import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {EMPTY, Observable} from 'rxjs';
import {TicketSaleService} from "@biletix/core/services/ticket-sale.service";
import {ShoppingCart} from "@biletix/core/models/shopping-cart/shopping-cart.model";
import {GlobalErrorHandler} from "@biletix/core/services/global-error-handler.service";
import {catchError, map} from "rxjs/operators";
import {RouterService} from "@biletix/core/services/router.service";

@Injectable()
export class ShoppingCartResolver implements Resolve<ShoppingCart> {

    constructor(private ticketSaleService: TicketSaleService,
                private globalErrorHandler: GlobalErrorHandler,
                private routerService: RouterService) {
    }

    /**
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ShoppingCart> | Observable<any> {
        return this.ticketSaleService.getShoppingCart()
            .pipe(
                map((cart: ShoppingCart) => {
                        this.ticketSaleService.setShoppingCart(cart);
                        return cart;
                    }
                ),
                catchError((err) => {
                        this.globalErrorHandler.handleError(err);
                        //TODO: Seam devre dışı kalınca aktif edilecek.
                        //this.routerService.navigateToHomePage();
                        this.routerService.navigateToSeamHomePage();
                        return EMPTY;
                    }
                )
            );
    }
}
