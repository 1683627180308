import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiResponse} from "@biletix/core/models/api-response";
import {ApiService} from "@biletix/core/services/api.service";
import {LoggedInCustomer} from "@biletix/core/models/authentication/logged-in-customer.model";
import {StorageKey} from "@biletix/core/services/storage.service";
import {LocalStorageService} from "@biletix/core/services/local-storage.service";
import {Router} from "@angular/router";
import {RegionService} from "@biletix/core/services/region.service";
import {I18nService} from "@biletix/core/services/i18n.service";
import {map} from "rxjs/operators";
import {LoggedInAnonymousCustomer} from "@biletix/core/models/customer/anonymous-customer-info.model";
import {environment} from "@biletix/env";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private baseAuthPath: string = 'api/v1/authentication';

    redirectUrl: string = '/home';

    constructor(private httpClient: HttpClient,
                private localStorage: LocalStorageService,
                private router: Router,
                private region: RegionService,
                private i18n: I18nService,
                private apiService: ApiService) {
    }

    login(signInformValue: { email: string, password: string }): Observable<ApiResponse<LoggedInCustomer>> {
        this.clearLogin();
        const headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.httpClient.post<ApiResponse<LoggedInCustomer>>(
            `${this.apiService.getCrmApiHost()}/${this.baseAuthPath}/customerLogin`,
            {...signInformValue});
    }

    doLogout(): Observable<void> {
        this.redirectUrl = '/home';
        return this.httpClient.get<ApiResponse<void>>(
            `${this.apiService.getCrmApiHost()}/${this.baseAuthPath}/customerLogout`)
            .pipe(map(response => response.data));
    }

    navigateToLogin() {
        this.clearLogin();

        const stateObj: any | { returnUrl: string } = {
            returnUrl: this.redirectUrl
        };

        //
        if (this.redirectUrl && this.redirectUrl.indexOf('/delivery')) {
            stateObj["showLoginAsAnonymousBtn"] = true;
        }

        this.router.navigate([
                '/auth',
                this.region.regionName,
                this.i18n.currentLang,
                'login'
            ],
            {
                state: stateObj,
                queryParams: stateObj
            }
        );
    }

    setLoggedInCustomer(loggedInCustomer: LoggedInCustomer | any) {
        this.removeLoggedInAnonymousCustomer();
        this.localStorage.set(StorageKey.LOGGED_IN_CUSTOMER, loggedInCustomer);
    }

    getLoggedInCustomer() {
        return this.localStorage.get<LoggedInCustomer>(StorageKey.LOGGED_IN_CUSTOMER);
    }

    removeLoggedInCustomer() {
        this.localStorage.remove(StorageKey.LOGGED_IN_CUSTOMER);
    }

    getLoggedInCustomerId() {
        return this.getLoggedInCustomer()?.customerId;
    }

    isLoggedInCustomer(): boolean {
        const loggedInCustomer = this.getLoggedInCustomer();
        return loggedInCustomer && loggedInCustomer.customerId;
    }

    isLoggedInAnonymousCustomer(): boolean {
        if (environment.ALLOW_ANONYMOUS && this.getLoggedInAnonymousCustomer()) {
            return true;
        }
        return false;
    }

    public setLoggedInAnonymousCustomer(loggedInAnonymousCustomer: LoggedInAnonymousCustomer) {
        this.localStorage.set(StorageKey.LOGGED_IN_ANONYMOUS_CUSTOMER, loggedInAnonymousCustomer);
    }

    public getLoggedInAnonymousCustomer(): LoggedInAnonymousCustomer {
        return this.localStorage.get<LoggedInAnonymousCustomer>(StorageKey.LOGGED_IN_ANONYMOUS_CUSTOMER);
    }

    public removeLoggedInAnonymousCustomer() {
        this.localStorage.remove(StorageKey.LOGGED_IN_ANONYMOUS_CUSTOMER);
    }


    public isAuthenticated(): boolean {
        return this.isLoggedInCustomer() || this.isLoggedInAnonymousCustomer();
    }


    clearLogin() {
        this.removeLoggedInCustomer();
        this.removeLoggedInAnonymousCustomer();
    }

    updateLoggedInCustomer(name: string, email: string) {
        let loggedInCustomer = this.getLoggedInCustomer();
        loggedInCustomer.name = name;
        loggedInCustomer.email = email;
        this.setLoggedInCustomer(loggedInCustomer);
    }

    public get watchLoggedInCustomer(): Observable<LoggedInCustomer> {
        return this.localStorage.watch<LoggedInCustomer>(StorageKey.LOGGED_IN_CUSTOMER);
    }

    /**
     *
     */
    checkLogin(): Observable<boolean> {
        return this.httpClient.get<ApiResponse<boolean>>(
            `${this.apiService.getCrmApiHost()}/${this.baseAuthPath}/checkLogin`)
            .pipe(map(response => response.data));
    }
}
