import {Injectable} from "@angular/core";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {EventIdAndPerfCode} from "@biletix/core/models/state/event-perf-code.model";
import {EventProfileType} from "@biletix/core/common/enums/event-profile-type.enum";
import {ShoppingItem} from "@biletix/core/models/shopping-cart/shopping-item.model";
import {TicketSaleService} from "@biletix/core/services/ticket-sale.service";
import {Upsell} from "@biletix/core/models/event/upsell.model";
import {BehaviorSubject, Observable, ReplaySubject, Subject} from "rxjs";
import {PriceInfo} from "@biletix/core/models/event/price-info.model";
import {ProfileInfo} from "@biletix/core/models/event/profile-info.model";
import {ShoppingCartState} from "@biletix/core/models/state/shopping-cart-state.model";
import {ShoppingStateItem} from "@biletix/core/models/state/shopping-state-item.model";
import {EventDetail} from "@biletix/core/models/event/event-detail.model";
import {FieldConfig} from "@biletix/core/models/base/field-config.model";
import {ImageUrlManager} from "@biletix/core/common/helper/image-url-manager";
import {TicketButtonState} from "@biletix/core/common/enums/ticket-button-state.enum";
import {UpsellStateItem} from "@biletix/core/models/state/upsell-state-item.model";
import {CustomTicketFieldConfig} from "@biletix/core/models/event/custom-ticket-field-config.model";
import {CustomValidators} from "@biletix/core/common/validators/custom-validators";
import {BtxPerformance} from "@biletix/core/models/event/performance.model";
import {BtxPerformanceListItem} from "@biletix/core/models/event/performance-list-item.model";
import { Block } from "@biletix/core/models/event/block.model";

export interface ConcessionSelectChange {
    itemId: number;
    quantity: number;
    emitEvent: boolean;
    onlySelf?: boolean
}

@Injectable()
export class PerformancePageStateService {


    ticketFormGroup!: FormGroup;
    upsellFormGroup!: FormGroup;

    private _currentEventDetail: EventDetail | any;
    private _performanceListItem: BtxPerformanceListItem | any;
    private _shoppingCartState: ShoppingCartState | any;
    private _currentEventCodeAndPerfCode!: EventIdAndPerfCode;

    private _currentPerformance: BtxPerformance | any;

    private _shoppingCartStateChange: BehaviorSubject<ShoppingCartState>;
    shoppingCartStateChange: Observable<ShoppingCartState>;


    private _priceCategoryChange: ReplaySubject<PriceInfo|undefined> = new ReplaySubject<PriceInfo|undefined>(1);
    priceCategoryChange: Observable<PriceInfo|undefined>;

    private _blockChange: ReplaySubject<Block|undefined> = new ReplaySubject<Block|undefined>(1);
    blockChange: Observable<Block|undefined>;

    private _concessionQuantityChange: Subject<ConcessionSelectChange> = new Subject<ConcessionSelectChange>();
    concessionQuantityChange: Observable<ConcessionSelectChange>;

    private _addToBasketButtonClicked = new Subject<TicketButtonState>();
    basketButtonClickChange: Observable<TicketButtonState>;

    constructor(private fb: FormBuilder,
                private ticketSaleService: TicketSaleService
    ) {
        this._shoppingCartState = new ShoppingCartState();
        this._shoppingCartStateChange = new BehaviorSubject<ShoppingCartState>(this._shoppingCartState);
        this.shoppingCartStateChange = this._shoppingCartStateChange.asObservable();


        //price categor change
        this.priceCategoryChange = this._priceCategoryChange.asObservable();

        this.blockChange = this._blockChange.asObservable();

        //quantity change
        this.concessionQuantityChange = this._concessionQuantityChange.asObservable();

        //add to basket button
        this.basketButtonClickChange = this._addToBasketButtonClicked.asObservable();
    }

    emitShoppingCartStateChangedEvent(shoppingCartState:ShoppingCartState){
        this._shoppingCartStateChange.next(shoppingCartState);
    }

    setCurrentEventDetail(eventDetail: EventDetail) {
        this._currentEventDetail = eventDetail;
    }

    getEventDetail(): EventDetail {
        return this._currentEventDetail;
    }

    public getEventUpsellItems(): Upsell[] {
        if (this._currentEventDetail) {
            return this._currentEventDetail.btxUpsells ?? [];
        }
        return [];
    }

    public getEventCustomTicketFieldConfigs(): FieldConfig[] {
        return this.mapToFieldConfigs(this._currentEventDetail?.customTicketFields);
    }

    private mapToFieldConfigs(customTicketFields: CustomTicketFieldConfig[]): FieldConfig[] {
        if (customTicketFields && customTicketFields.length > 0) {
            return customTicketFields.map(field => {
                const fieldConfig: FieldConfig = <any>field;
                fieldConfig.name = 'value';
                fieldConfig.validations = CustomValidators.createValidations(field);
                return fieldConfig;
            })
        }
        return [];
    }

    getEventSeatPlanImage(): string {
        return ImageUrlManager.createSeatPLanImage(this._currentEventDetail?.seatPlanImage ?? '');
    }


    setPerformanceListItemDetail(performanceListItem: BtxPerformanceListItem) {
        this._performanceListItem = performanceListItem;
    }

    getPerformanceListItemDetail(): BtxPerformanceListItem {
        return this._performanceListItem;
    }


    setCurrentPerformance(performance: BtxPerformance) {
        this._currentPerformance = performance;
    }

    public getCurrentPerformance(): BtxPerformance {
        return this._currentPerformance
    }


    /**
     *
     */
    public createParentFormGroup(): FormGroup {
        this.ticketFormGroup = this.fb.group({
                profile: ['', Validators.required],
                performance: [],
                block: ['', Validators.required],
                priceInfo: [null, Validators.required],
                validationFields: this.fb.array([]),
                concessions: this.fb.array([], {validators: [Validators.required]}),
            }
        );
        return this.ticketFormGroup;
    }

    public createUpsellFormGroup(): FormGroup {
        this.upsellFormGroup = this.fb.group({
                upsellInputs: this.fb.array([])
            }
        );

        return this.upsellFormGroup;
    }

    public resetTicketFormGroup(...properties: string[]) {
        properties.forEach(prop => this.ticketFormGroup.get(prop)?.reset());
    }

    public resetTicketFormGroupValidationFieldsFormControl(){
        this.ticketFormGroup.removeControl("validationFields");
        this.ticketFormGroup.addControl("validationFields",this.fb.array([]));
    }

    get parentFormValue(): any {
        if (!this.ticketFormGroup) {
            return null;
        }
        return this.ticketFormGroup.value;
    }


    public getCurrentProfile(): ProfileInfo | any {
        if (!this.parentFormValue)
            return null;

        return this.parentFormValue.profile;
    }

    public getCurrentPriceInfo(): PriceInfo | any {
        if (!this.parentFormValue)
            return null;

        return this.parentFormValue.priceInfo;
    }

    public getBasePrice(): number {
        let priceInfo = this.getCurrentPriceInfo();
        if (priceInfo) {
            return priceInfo.value;
        }
        return 0;
    }


    public setEventCodeAndPerfCode(model: EventIdAndPerfCode): void {
        this._currentEventCodeAndPerfCode = model;
    }

    public get currentEventPerfCode(): EventIdAndPerfCode {
        return this._currentEventCodeAndPerfCode;
    }

    getActiveEventCode(): string {
        return this._currentEventCodeAndPerfCode.eventCode;
    }

    getActiveEventPerfCode(): string {
        return this._currentEventCodeAndPerfCode.perfCode;
    }


    /**
     *TODO: Hangi profiller birlikte satılacak. Şuan localden kontrol ediliyor. Servise bağlanması lazım
     * @param profileType
     */
    public validateShoppingCartProfile(profileType: EventProfileType) {

        let shoppingCart = this.ticketSaleService.getLocalShoppingCart();

        if (shoppingCart == null
            || shoppingCart.shoppingItems.length == 0
            || EventProfileType[EventProfileType.PUBLIC] == profileType)
            return true;

        const otherShoppingItems = shoppingCart
            .shoppingItems//
            .filter((item: ShoppingItem) => EventProfileType[EventProfileType.PUBLIC] != item.profileType);

        //Genel Şatış dışında sadece bir profilden satışa izin verilecek.
        return otherShoppingItems.length == 0 || otherShoppingItems.some((item: ShoppingItem) => item.profileType == profileType);
    }

    /**
     * TODO: Server tarafında bu değer kontrol edilmeli. Burası daha sonra kontrol edilmelidir.
     * Shopping cart üzerinde bu performans ta item varsa geri kalan maxTicket alanı set edilmeli.
     *
     *
     * @param profileId
     * @param maxTicket
     */
    getMaxTicketCountForActivePerformance() {
        let performance: BtxPerformance = this.getCurrentPerformance();

        if (performance) {

            let basketCount = this.ticketSaleService.getSelectedTicketCountByActivePerformance(this.getActiveEventCode(), this.getActiveEventPerfCode());

            const maxTicket = performance.maxTicket;

            let value = maxTicket - basketCount;

            return value > 0 ? value : 0;
        }
        return 0;
    }

    /**
     *
     * @param itemId
     * @param quantity
     */

    public saveShoppingStateItemChanges(itemId: number, quantity: number) {
        let shoppingStateItem: ShoppingStateItem = this.shoppingCartState.getShoppingStateItemByItemId(itemId);


        //add item
        const formValue = this.ticketFormGroup.value;
        const profile = formValue.profile;
        const concessionItem = formValue.concessions[itemId];

        //update item
        if (shoppingStateItem) {
            shoppingStateItem.quantity = quantity
            shoppingStateItem.totalPrice = quantity * concessionItem.totalPrice;
        } else {

            const block = formValue.block,
                priceInfo = formValue.priceInfo;
            shoppingStateItem = {
                itemId: itemId,
                blockName: block.code + '-' + block.description,
                categoryName: priceInfo.description,
                quantity: quantity,
                servicePrice: concessionItem.servicePrice,
                totalPrice: quantity * concessionItem.totalPrice,
                price: concessionItem.pricePerSeat,
              concessionName: concessionItem.concessionName,
              zeroPriceEnabled: this.getCurrentPerformance().zeroPriceEnabled
            };
          let eventDetail = this.getEventDetail();
          let shoppingCart = this.ticketSaleService.getLocalShoppingCart();

          this.shoppingCartState.setDigitalTicketCost(false,350);

            this.shoppingCartState.addShoppingItem(shoppingStateItem);
        }
    }

    /**
     *
     * @param itemId
     */
    public removeShoppingStateItem(itemId: number) {
        this.shoppingCartState.removeShoppingItem(itemId);
    }

    /**
     *
     * @param upsellStateItem
     */
    public addOrUpdateUpsellStateItem(upsellStateItem: UpsellStateItem) {
        let stateItem = this.shoppingCartState.getUpsellStateItemByItemId(upsellStateItem.itemId);
        if (stateItem) {
            stateItem.quantity = upsellStateItem.quantity;
            stateItem.totalPrice = upsellStateItem.totalPrice;
        } else {
            this.shoppingCartState.addUpsellStateItem(upsellStateItem);
        }
    }

    /**
     *
     * @param itemId
     */
    public removeUpsellItem(itemId: number) {
        this.shoppingCartState.removeUpsellStateItem(itemId);
    }

    /**
     *
     */
    get shoppingCartState(): ShoppingCartState {
        return this._shoppingCartState;
    }

    /**
     *
     */
    public clearShoppingStateItems(): void {
        this.shoppingCartState.shoppingStateItems = [];
    }

    /**
     *
     */
    public clearStateData(): void {
        //
        if (this.ticketFormGroup) {
            this.ticketFormGroup.get('priceInfo')?.reset();
        }
        this._shoppingCartState.shoppingStateItems = [];
        this._shoppingCartState.upsellStateItems = [];
        this.clearAllUpsellQuantity();
    }

    private clearAllUpsellQuantity(): void {
        if (this.upsellFormGroup) {

            let upsellInputs: FormArray = this.upsellFormGroup
                .get('upsellInputs') as FormArray;

            upsellInputs?.controls.forEach(group => {
                    group.patchValue({
                        quantity: 0
                    });
                }
            );
        }
    }

    //Subscriptions
    public onChangePriceCategory(priceInfo: PriceInfo|undefined): void {
        this.clearShoppingStateItems();
        this._priceCategoryChange.next(priceInfo);
    }

    //Subscriptions
    public onChangeBlock(block: Block|undefined): void {
        //this.clearShoppingStateItems();
        this._blockChange.next(block);
    }

    /**
     *
     * @param state
     */
    public onChangeConcessionQuantityValue(state: ConcessionSelectChange): void {
        if (state) {
            //
            if (state.quantity == 0) {
                this.removeShoppingStateItem(state.itemId);
            } else {
                this.saveShoppingStateItemChanges(state.itemId, state.quantity);
            }

            //emit change value
            if (state.emitEvent) {
                this._concessionQuantityChange.next(state);
            }

            this.emitShoppingCartStateChangedEvent(this.shoppingCartState);
        }
    }

    /**
     *
     * @param buttonEvent
     */
    public onClickBasketButton(buttonEvent: TicketButtonState = TicketButtonState.ADD_TO_BASKET) {
        this._addToBasketButtonClicked.next(buttonEvent);
    }

    /**
     * Block dress type =3  ayakta bir block olduğunu göstermektedir. Koltuk seçimi yapılamaz.
     * ismAllowed=false koltuk seçimine izin yok
     */
    public isSeatSelection(): boolean {
        const currentBlock = this.ticketFormGroup.value.block;
        return currentBlock && currentBlock.dressType !== 3 && this._currentEventDetail?.ismAllowed;
    }


    /**
     *
     */
    public getSelectedTicketCount(): number {
        return this.shoppingCartState?.ticketCount ?? 0;
    }


    public isTicketSelected(): boolean {
        return this.getSelectedTicketCount() > 0;
    }

    public getSelectedUpsellCount(): number {
        return this.shoppingCartState?.upsellCount ?? 0;
    }

    public isUpsellSelected() {
        return this.getSelectedUpsellCount() > 0;
    }


}
