import {Injectable} from '@angular/core';
import {LocalStorageService} from "@biletix/core/services/local-storage.service";
import {StorageKey} from "@biletix/core/services/storage.service";
import {GlobalParameters} from "@biletix/core/common/constants/global-parameters";
import {ApiService} from "@biletix/core/services/api.service";
import {HttpClient} from "@angular/common/http";
import {ApiResponse} from "@biletix/core/models/api-response";
import {map} from "rxjs/operators";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import { ShoppingCartState } from '../models/state/shopping-cart-state.model';

@Injectable()
export class CartTimeoutService {


    private _ticketSaleBasePath = "/api/v1/ticketSales";

    private _cartTimeout = new Subject<boolean>();
    cartTimeout: Observable<boolean>;

    private cartTimeoutDateChangedSubject = new Subject<boolean>();
    cartTimeoutDateChangedObservable: Observable<boolean>;

    private _shoppingCartState: ShoppingCartState | any;
    private _shoppingCartStateChange: BehaviorSubject<ShoppingCartState>;
    shoppingCartStateChange: Observable<ShoppingCartState>;


    constructor(private localStorageService: LocalStorageService,
                private apiService: ApiService,
                private http: HttpClient) {
        this.cartTimeout = this._cartTimeout.asObservable();
        this.cartTimeoutDateChangedObservable =this.cartTimeoutDateChangedSubject.asObservable();
        //this._shoppingCartOrderExistChange = new BehaviorSubject<boolean>(false);
        //this.shoppingCartOrderExistChange = this._shoppingCartOrderExistChange.asObservable();

        this._shoppingCartStateChange = new BehaviorSubject<ShoppingCartState>(this._shoppingCartState);
        this.shoppingCartStateChange = this._shoppingCartStateChange.asObservable();
    }

    public setShoppinCartState(shoppingCartState:ShoppingCartState){
        this._shoppingCartStateChange.next(shoppingCartState);
    }

    public init(): void {
        this.localStorageService.set(StorageKey.CART_REMAINING_TIME_IN_SECONDS, GlobalParameters.CART_REMAINING_TIME_DEFAULT);
    }

    private storeRemainingTime(timeInSecond: number) {
        // timeInSecond = 10;
        this.localStorageService.set(StorageKey.CART_REMAINING_TIME_IN_SECONDS, timeInSecond);
        const timeoutDate = new Date();
        timeoutDate.setSeconds(timeoutDate.getSeconds() + timeInSecond);
        this.localStorageService.set(StorageKey.CART_TIMEOUT_DATE, timeoutDate);
        this.cartTimeoutDateChangedSubject.next(true);
    }

    public setRemainingTimeInSecond(timeInSecond: number) {
        this.storeRemainingTime(timeInSecond);
    }

    public getRemainingTimeInSecond(): number {
        //return this.localStorageService.get(StorageKey.CART_REMAINING_TIME_IN_SECONDS) ?? GlobalParameters.CART_REMAINING_TIME_DEFAULT;
        //return this.localStorageService.get(StorageKey.CART_REMAINING_TIME_IN_SECONDS);
        const now=new Date();
        return (this.getCartTimeoutDate().getTime()-now.getTime())/1000;
    }

    public getCartTimeoutDate(): Date {
        const timeoutDate = this.localStorageService.get(StorageKey.CART_TIMEOUT_DATE);
        return new Date(Date.parse(timeoutDate));
    }

    public resetTimeout(): Observable<boolean> {
        return this.http//
            .get<ApiResponse<boolean>>(`${this.apiService.getCrmApiHost()}${this._ticketSaleBasePath}/extendShoppingCartTime`)//
            .pipe(map(response => response.data));
    }

    public isTimeout(): boolean {
        let timeoutDate = this.getCartTimeoutDate();
        if (timeoutDate) {
            return timeoutDate.getTime() < Date.now();
        }
        return false;

    }

    public setTimeout(timeout: boolean = true): void {
        this._cartTimeout.next(timeout);
        this.clearTimeout();
    }

    public clearTimeout() {
        this.localStorageService.remove(StorageKey.CART_REMAINING_TIME_IN_SECONDS);
        this.localStorageService.remove(StorageKey.CART_TIMEOUT_DATE);
    }

    restart() {
        this.setRemainingTimeInSecond(GlobalParameters.CART_REMAINING_TIME_DEFAULT);
    }
}
