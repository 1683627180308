import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {RouteParameter} from "@biletix/core/common/constants/route-parameter.const";
import {I18nService} from "@biletix/core/services/i18n.service";
import {LocalStorageService} from "@biletix/core/services/local-storage.service";
import {Location} from "@angular/common";

/**
 * Url üzerinden dil seçeneği değiştirildiğinde veya url refresh olduğunda kontrol edilir.
 */
@Injectable({
  providedIn: 'root'
})
export class LanguageGuard implements CanActivate {

  constructor(private i18n: I18nService,
              private localStorage: LocalStorageService,
              private location:Location,
              private router: Router) {
  }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    //console.log("Language guard started");
    const lang = route.params[RouteParameter.PARAMS.LANGUAGE];
    let isAvailableLanguage=this.i18n.isAvailableLanguage(lang);

    // console.log(lang+ "language is available : "+isAvailableLanguage);
    if(!isAvailableLanguage){
      let url = state.url;
      //console.log(url);
      url = url.replace(`/${lang}`, `/en`);
      //console.log(url);
      this.router.navigateByUrl(url);//

    }
    // if (locale) {
    //   if (this.i18n.currentLang != locale.lang) {
    //     this.i18n.useLanguage(locale);
    //   }
    // } else {
    //   //TODO: Geçersiz bir dil seçeneği ise eski dil seçeneğine dön, veya direk homepageya dön
    //   this.location.back();
    // }
   // console.log("Language guard ended");
    return true;
  }

}
