/**
 * TODO: Server tarafında enum değerleri string olarak gönderilmemelidr.
 */
export enum EventProfileType {
  PUBLIC = "PUBLIC",
  MEMBER="MEMBER",
  CREDITCARD="CREDITCARD",
  TCKIMLIK="TCKIMLIK",
  AVEA="AVEA",
  TOKEN="TOKEN",
  PROMOSYON="PROMOSYON",
  PEGASUS="PEGASUS",
  TURKCELL="TURKCELL",
  VODAFONERED="VODAFONERED",
  AVEACELL="AVEACELL",
  DISCOUNT="DISCOUNT",
  VODAFONEFREEZONE="VODAFONEFREEZONE",
  VISA="VISA",
  MASTERCARD="MASTERCARD",
  TCKMEMBER="TCKMEMBER",
  AVANTAJKULUP="AVANTAJKULUP",
  CREDITCARDLMT="CREDITCARDLMT",
  MEMBERONLINE="MEMBERONLINE",
  VODAFONE="VODAFONE",
  CREDITCARDPROFILELMT="CREDITCARDPROFILELMT",
  TURKCELLMEMBER="TURKCELLMEMBER",
  FUNCLUB="FUNCLUB",
  EVENTCAMPAIGN="EVENTCAMPAIGN",
  FENERWS="FENERWS",
  FREEHOMEPRINT="FREEHOMEPRINT",
  BKMEXPRESS="BKMEXPRESS",
  ASKIDA="ASKIDA",
  KULTURSANAT="KULTURSANAT",
  ANADOLUEFESWS="ANADOLUEFESWS",

}
