import {Component, OnInit} from '@angular/core';
import {Locale, Region} from "@biletix/core/common";
import {RegionService} from "@biletix/core/services/region.service";
import {I18nService} from "@biletix/core/services/i18n.service";
import {Router} from "@angular/router";
import {MenuItem} from "@biletix/core/view-models/menu-item.model";
import {RouterService} from "@biletix/core/services/router.service";


@Component({
  selector: 'btx-top-band',
  templateUrl: './top-band.component.html',
  styleUrls: ['./top-band.component.scss']
})
export class TopBandComponent implements OnInit {

  locales: Locale[];
  currentLocale: Locale;

  regions: Region[];
  currentRegion: Region;

  topLinks: MenuItem[] = [];

  constructor(private  region: RegionService,
              private i18n: I18nService,
              private router: Router,
              public routerService:RouterService) {
    this.locales = this.i18n.getAvailableLocales();
    this.currentLocale = this.i18n.currentLocale;
    this.regions = this.region.getRegions();
    this.currentRegion = this.region.currentRegion;
  }

  ngOnInit(): void {
    this.buildTopLinks();
  }

  private buildTopLinks(): void {
    this.topLinks = [
      {label: 'main.labels.help', url: this.routerService.getSeamPageUrl('yardim'),order:0},//Help
     /*  {label: 'main.labels.blog', url: 'https://blog.biletix.com/',order:0},//Blog
      {label: 'main.labels.mag', url: 'https://www.flipsnack.com/885A97FF8D6/',order:0},//Mag
      {label: 'main.labels.giftCard', url: this.routerService.getSeamPageUrlWoRegion('hediyekart'), image: 'assets/images/icon/giftcard.svg',order:0,classList:['giftCart']},//GiftCart
     */];
  }


  public changeLanguage(locale: Locale) {
    this.currentLocale = locale;
    this.i18n.changeLanguage(locale);
  }

  public changeRegion(region: Region) {
    this.currentRegion = region;
    this.region.changeRegion(region);
  }

}
