export const environment = {
  production: true,
  profile:"prod",
/*
  crm_api: {
    host: 'https://www.biletix.com',
    contextPath: '/wbtxapi',
    url: 'https://www.biletix.com/wbtxapi'
  },
*/
  crm_api: {
    host: 'https://www.ticketmaster.co.il',
    contextPath: '/wbtxapi',
    url: 'https://www.ticketmaster.co.il/wbtxapi'
  },
  /*
  payment_api: {
    host: 'https://www.biletix.com',
    contextPath: '/paymentapi',
    url: 'https://www.biletix.com/paymentapi'
  },
  */
  payment_api: {
    host: 'https://www.ticketmaster.co.il',
    contextPath: '/paymentapi',
    url: 'https://www.ticketmaster.co.il/paymentapi'
  },
  ism_api: {
    host: 'https://www.ticketmaster.co.il',
    contextPath: '/ismapi',
    url: `https://www.ticketmaster.co.il/ismapi`
  },
  wts_api: {
    host: 'https://www.ticketmaster.co.il',
    contextPath: '/wtsapi',
    url: 'https://www.ticketmaster.co.il/wtsapi'
  },

  iswWebHost:"https://www.ticketmaster.co.il",

  biletixWebHost:"https://www.ticketmaster.co.il",

  venuePageUrl:"https://www.ticketmaster.co.il/venue",

  accountPageUrlEnv:"/myaccount/",

  venueImageBasePath:'https://ticketmaster.co.il/static/images/live/venue/venuelogo/',

  paymentPageUrl:"https://www.ticketmaster.co.il/paymentgateway/payment?transId=",

  eventImageBasePath: 'https://www.ticketmaster.co.il/static/images/live/event/eventimages/',

  eventWideImageBasePath: 'https://www.ticketmaster.co.il/static/images/live/event/eventimages/wide/',

  sponsorImageBasePath: 'https://www.ticketmaster.co.ilstatic/images/live/event/sponsorimages/',

  topImageBasePath:'https://www.ticketmaster.co.il/static/images/live/event/topeventimages/400x400/',

  spotLightImageBasePath:'https://ticketmaster.co.il/static/images/live/spotlight/',

  spotLightMobileImageBasePath:'https://ticketmaster.co.il/static/images/live/spotlightMobile/',

  seatPlanImageBasePath: 'https://www.ticketmaster.co.il/static/images/live/venue/seatingplan/',

  eventPhotosBasePath: 'https://www.ticketmaster.co.il/static/images/live/event/eventphotos/',

  groupImageBasePath: 'https://www.ticketmaster.co.il/static/images/live/event/groupimages/',

  profileValidationImageBasePath: 'https://www.ticketmaster.co.il/static/images/profileValidationImages/',

  upsellImageBasePath: 'https://www.ticketmaster.co.il/static/images/upsell/',

  staticImageBasePath: 'https://www.ticketmaster.co.il/static/images/',

  CHANNEL: 'INTERNET',
  CPU: 32100,
  SEND_COOKIES: false,
  ALLOW_ANONYMOUS: false,
  BYPASS_ADDRESS: true,
  currencySymbolCode:"ILS",
  currencyDigit:"1.2-2",
  currencyLocale:"iw",
  GOOGLE_RECAPTCHA_SITE_KEY: '6LdQicEUAAAAAFvxzXiBvgI9ggtltLFVXumdStwB',
  JENKINS_BUILD_NUMBER : '79'
};
