import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'btx-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.scss']
})
export class ProductPriceComponent implements OnInit {

  @Input("value") priceValue: number | string | any = 0;

  @Input() quantity?: number = -1;

  @Input() showFreeLabel: boolean = false;

  @Input() classList: any[] | string = [];

  /**
   *
   */
  isShowFreeLabel: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
    this.isShowFreeLabel = this.isLabelText();
  }

  private isLabelText(): boolean {
    if (this.showFreeLabel && this.priceValue <= 0) {
      return true
    }
    return false;
  }


}
