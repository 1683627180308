import {Injectable} from '@angular/core';
import {ActiveToast, ToastrService} from "ngx-toastr";
import {ApiError} from "@biletix/core/models/api-error";
import {ApiWarning} from "@biletix/core/models/api-warning";
import {IndividualConfig} from "ngx-toastr/toastr/toastr-config";
import {I18nService} from "@biletix/core/services/i18n.service";

@Injectable()
export class NotificationService {

  constructor(private toast: ToastrService,private i18n: I18nService,) {
    
  }

  success(message: any, title?: string,override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.toast.success(message, title, override ? override : {positionClass: this.i18n.currentLocale.lang == 'en' ? 'toast-top-right' : 'toast-top-left'});
  }

  info(message: any, title?: string): ActiveToast<any> {
    return this.toast.info(message, title, {positionClass: this.i18n.currentLocale.lang == 'en' ? 'toast-top-right' : 'toast-top-left'});
  }

  error(message: ApiError[]|string|any, title?: string ,override?: Partial<IndividualConfig>): ActiveToast<any> {
    if (Array.isArray(message))
      return this.toast.error(this.getErrorMessage(message), title, override ? override : {positionClass: this.i18n.currentLocale.lang == 'en' ? 'toast-top-right' : 'toast-top-left'});

    return this.toast.error(<string>message, title, {positionClass: this.i18n.currentLocale.lang == 'en' ? 'toast-top-right' : 'toast-top-left'});
  }

  warning(message:ApiError[]| string | any, title?:string,override?: Partial<IndividualConfig>): ActiveToast<any> {
    if (Array.isArray(message))
      return this.toast.warning(this.getErrorMessage(message), title, {positionClass: this.i18n.currentLocale.lang == 'en' ? 'toast-top-right' : 'toast-top-left'});

    return this.toast.warning(<string>message, title, {positionClass: this.i18n.currentLocale.lang == 'en' ? 'toast-top-right' : 'toast-top-left'});
  }



  private getErrorMessage(errors?: ApiError[] | ApiWarning[]) {
    return errors?.map(error => error.message)
      .reduce((acc, cur) => acc + '<br>' + cur);
  }
}
