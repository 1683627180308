import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { EMPTY, Observable } from "rxjs";
import { RouteParameter } from "../common/constants/route-parameter.const";
import { I18nService } from "../services/i18n.service";

@Injectable({ providedIn: 'root' })
export class TranslationResolver implements Resolve<any> {
  constructor(private translateService: TranslateService,private i18n: I18nService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    let lang = route.params[RouteParameter.PARAMS.LANGUAGE];
    // console.log("translation resolver called : "+lang);
    if(!lang){
      lang = this.i18n.currentLang;
    }
    const locale=this.i18n.getLocaleByLang(lang);
    // console.log("translation resolver lang changing to: "+locale.lang);

    return this.i18n.useLanguage(locale);

  }

}
