<mat-sidenav-container  class="sidenav-container lang-{{currentLocale.lang}}">

    <mat-sidenav #sidenav role="navigation" [dir]="'dir'| translate" class="mobile-side-menu">
        <div class="side-nav-header">
            <!-- <span class="material-icons-outlined back-button"> chevron_left </span> -->
            <!-- <span class="head-text">{{'shared.labels.categories'|translate}}</span> -->
            <span class="material-icons-outlined close-button" (click)="sidenav.close()">
                close
            </span>
        </div>
        <btx-event-category-mobile-menu></btx-event-category-mobile-menu>
    </mat-sidenav>

    <!--  -->
    <mat-sidenav-content>
        <section class="sidenav-content-container">
            <btx-header (sidenavToggle)="sidenav.toggle()"></btx-header>
            <!-- <div id="adResponsive-Hidden" class="hiddenOnDesktop">
                <div id='div-gpt-ad-1505377893829-0' style='height:50px; width:320px; margin: 20px auto;'>
                    <script type="text/plain" async="" class="optanon-category-C0004">
                        googletag.cmd.push(function() { 
                            googletag.display('div-gpt-ad-1505377893829-0'); 
                        });
                    </script>
                </div>
            </div>
            
            <div id="adTop-Hidden" class="hiddenOnMobile">
                <div id='div-gpt-ad-1594640818191-0' style='width: 1000px; height: 90px; margin: 20px auto;'>
                    <script type="text/plain" async="" class="optanon-category-C0004">
                    googletag.cmd.push(function() { 
                        googletag.display('div-gpt-ad-1594640818191-0'); 
                    });
                    </script>
                </div>
            </div> -->
            <main role="main" class="sidenav-content container">
                <router-outlet></router-outlet>
            </main>
        </section>

        <btx-footer></btx-footer>
    </mat-sidenav-content>
</mat-sidenav-container>



