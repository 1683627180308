export enum LogLevel {
  DEBUG = 0,
  INFO = 1,
  WARN = 2,
  ERROR = 3
}

export interface Logger {
  log(...args: string[]|any): void

  error(...args: string[]|any): void

  warn(...args: string[]|any): void
}
