import {CustomHttpInterceptor} from "@biletix/core/interceptors/custom-http.interceptor";
import {ClassProvider} from "@angular/core";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {ErrorInterceptor} from "@biletix/core/interceptors/error.interceptor";

const customHttpInterceptorProvider: ClassProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: CustomHttpInterceptor,
  multi: true
};
const errorInterceptorProvider: ClassProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true
};
export const HTTP_INTERCEPTORS_PROVIDERS = [
  customHttpInterceptorProvider,
  errorInterceptorProvider
];
