import { Component, OnInit } from '@angular/core';
import {RouterService} from "@biletix/core/services/router.service";

@Component({
  selector: 'btx-site-logo',
  templateUrl: './site-logo.component.html',
  styleUrls: ['./site-logo.component.scss']
})
export class SiteLogoComponent {

  constructor(public routerService:RouterService) { }

}
