import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
    selector: 'btx-event-rule-modal',
    templateUrl: './event-rule-modal.component.html',
    styleUrls: ['./event-rule-modal.component.scss']
})
export class EventRuleModalComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public eventRules: string) {

    }
}
