import {environment} from "@biletix/env";


export const NoImageBasePath = {
    SEAT_PLAN: 'noseat.gif',
    EVENT_IMAGE: 'noimage.gif',
    DEFAULT: 'assets/images/noimage.gif'
}


/**
 *
 */
export class ImageUrlManager {


    private static urlPatten: RegExp = /^(https|ftp|http|ftps|www|www5):/;

    static createEventImage(path: string): string {
        return this.create(path, environment.eventImageBasePath);
    }

    static createTopEventImage(path: string): string {
        return this.create(path, environment.topImageBasePath);
    }

    static createVenueImage(path: string): string {
        return this.create(path, environment.venueImageBasePath);
    }
    static createSpotLightImage(path: string): string {
        return this.create(path, environment.spotLightImageBasePath);
    }

    static createSpotLightMobileImage(path: string): string {
        return this.create(path, environment.spotLightMobileImageBasePath);
    }

    static createEventWideImage(path: string): string {
        return this.create(path, environment.eventWideImageBasePath);
    }

    static createGroupImage(path: string): string {
        return this.create(path, environment.groupImageBasePath);
    }

    static createUpsellImage(path: string): string {
        return this.create(path, environment.upsellImageBasePath);
    }

    static createSponsorImage(path: string): string {
        return this.create(path, environment.sponsorImageBasePath);
    }

    static createSeatPLanImage(path: string): string {
        return this.create(path, environment.seatPlanImageBasePath, NoImageBasePath.SEAT_PLAN);
    }


    static create(value: string, basePath: string, noImagePath: string = NoImageBasePath.EVENT_IMAGE) {
        if (!value || value == '')
            value = noImagePath;

        if (this.urlPatten.test(value)) {
            return value;
        }

        return basePath + value;
    }

    static createValidationImage(image: string, basePath: string) {

        if (!image || image == '')
            return NoImageBasePath.DEFAULT;


        if (this.urlPatten.test(image)) {
            return image;
        }

        return basePath + image;

    }
}
