import {FactoryProvider, InjectionToken} from "@angular/core";


/**
 *
 */
export const LOCAL_STORAGE = new InjectionToken<Storage>('LOCAL_STORAGE');
export const LOCAL_STORAGE_PROVIDER: FactoryProvider = {
  provide: LOCAL_STORAGE,
  useFactory: () => localStorage
};

/**
 *
 */
export const WINDOW = new InjectionToken<Window>('WINDOW');
export const WINDOW_PROVIDER: FactoryProvider = {
  provide: WINDOW,
  useFactory: () => window
};

/**
 *
 */
export class Locale {
  constructor(public lang: string,//
              public code?: string, //
              public flag?: string,//
              public displayName?: string) {
  }
}

export const DEFAULT_LOCALES: InjectionToken<Locale[]> = new InjectionToken<Locale[]>('DEFAULT_LOCALES');
export const DEFAULT_LOCALE_VALUE: InjectionToken<string> = new InjectionToken<string>('DEFAULT_LOCALE_ID');


/**
 *
 */
export interface Region {
  name: string;
  displayName: string;
  code?: string;
};

export const DEFAULT_REGION_VALUE = new InjectionToken<Region>('DEFAULT_REGION');

export const USE_DEFAULT_REGIONS = new InjectionToken<Region[]>('USE_DEFAULT_REGIONS');


/**
 * Possible color palette values
 */
export type ColorTheme = 'primary' | 'secondary' | 'success' | 'error' | 'warning';
