import {ChangeDetectionStrategy, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {CountdownComponent, CountdownEvent, CountdownGlobalConfig} from "ngx-countdown";
import {CartTimeoutService} from "@biletix/core/services/cart-timeout.service";
import {TicketSaleService} from "@biletix/core/services/ticket-sale.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AbstractionComponent} from "@biletix/core/common/abstraction-component";
import {NotificationService} from "@biletix/core/services/notification.service";
import {RouterService} from "@biletix/core/services/router.service";
import {TranslateService} from "@ngx-translate/core";
import { ShoppingCartState } from '@biletix/core/models/state/shopping-cart-state.model';
import { StorageKey } from '@biletix/core/services/storage.service';
import { I18nService } from '@biletix/core/services/i18n.service';
import { Locale } from '@biletix/core/common';


@Component({
    selector: 'btx-header-countdown-timer',
    templateUrl: './header-countdown-timer.component.html',
    styleUrls: ['./header-countdown-timer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderCountdownTimerComponent extends AbstractionComponent implements OnInit {

    @ViewChild('countDown', {static: false}) private countdown: CountdownComponent | any;
    event: CountdownEvent | any;
    private started: boolean = false;
    lastOneMinute: boolean = false;

    locales: Locale[];
  currentLocale: Locale;

    constructor(public config: CountdownGlobalConfig,
                private ticketSaleService: TicketSaleService,
                private routerService: RouterService,
                private matDialog: MatDialog,
                public i18n: I18nService,
                private notificationService: NotificationService,
                private cartTimeoutService: CartTimeoutService,
                private translate: TranslateService,) {
                super();
                this.locales = this.i18n.getAvailableLocales();
                this.currentLocale = this.i18n.currentLocale;
                this.initConfig();

    }

    @HostListener('window:storage', ['$event'])
    onStorageChange(event:StorageEvent) {
        if(event.key==StorageKey.CART_TIMEOUT_DATE){
          // console.log("cart timeout date changed");
             //TODO: Seam devre dışı kalınca aktif edilecek.
          // console.log("cart timeout date changed from other tab");
            this.initConfig();
            this.countdown.restart();
            //this.routerService.navigateToHomePage();
            this.routerService.navigateToSeamHomePage();
        }
    }

    initConfig() {
        this.started = this.ticketSaleService.isExistShoppingCart();
        this.config.leftTime = +this.cartTimeoutService.getRemainingTimeInSecond();
      // console.log("Config left time : "+this.config.leftTime);
        this.config.demand = !this.started;
        // this.config.demand = true;
    }

    ngOnInit(): void {

        this.cartTimeoutService.cartTimeoutDateChangedObservable.subscribe(()=>{
            this.initConfig();
            this.countdown.restart();
        });
    }

    get isTimeout(): boolean {
        return this.cartTimeoutService.isTimeout();
    }


    handleEvent(ev: CountdownEvent) {
        let leftTime = ev.left / 1000;
        // this.cartTimeoutService.setRemainingTimeInSecond(leftTime);
        //
        // if (ev.action === 'notify') {
        //     this.cartTimeoutService.setRemainingTimeInSecond(leftTime);
        //     if (leftTime == 10 && this.started) {
        //         this.showTimeoutDialog();
        //     }
        // }

        //
        if (ev.action === 'done') {
            this.countDownCompleted();
        }

        if (leftTime == 60){
            this.lastOneMinute = true;
        }

    }

    countDownCompleted() {
        this.closeDialog();
        this.onClearShoppingCart();
    }

    closeTooltip(){
        this.lastOneMinute = false;
    }

    /**
     * Sepetiniz sıfırlanacak
     */
    // showTimeoutDialog() {
    //     this.matDialog.open(CartTimeoutModalComponent,
    //         {
    //             disableClose: true,
    //         }
    //     );
    // }

    onClearShoppingCart() {
        this.ticketSaleService
            .clearShoppingCart()
            .pipe(this.takeUntilDestroy())
            .subscribe(() => {
                    this.notificationService.success(this.translate.instant("ticket.labels.selectedTicketsRemoved"));
                    this.cartTimeoutService.setTimeout(true);
                    //TODO: Seam devre dışı kalınca aktif edilecek.
                    //this.routerService.navigateToHomePage();
                    this.routerService.navigateToSeamHomePage();
                }
            )
    }


    onRestart() {
        this.countdown.restart();
    }

    closeDialog() {
        this.matDialog.closeAll();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.closeDialog();
    }

}
