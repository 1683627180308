import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "shorten"
})
export class ShortenPipe implements PipeTransform {

  transform(content: string | undefined, limit: number = 50, suffix: string = '...') {
    if (content && content.length > limit) {
      return content.substr(0, limit) + suffix;
    }
    return content;
  }


}
