
import {EventService} from '@biletix/core/services/event.service';
import {Component, Input, OnInit} from '@angular/core';
import {ShoppingItem} from "@biletix/core/models/shopping-cart/shopping-item.model";
import {ShoppingUpsellItem} from "@biletix/core/models/shopping-cart/shopping-upsell-item.model";
import {ShoppingCart} from "@biletix/core/models/shopping-cart/shopping-cart.model";
import {MatDialog} from "@angular/material/dialog";
import {LocalStorageService} from "@biletix/core/services/local-storage.service";
import {TicketSaleService} from "@biletix/core/services/ticket-sale.service";
import {ApplicationStateService} from '@biletix/core/services/application-state.service';
import {AbstractionComponent} from "@biletix/core/common/abstraction-component";
import {EventRuleModalComponent} from "@biletix/shared/components/popups/event-rule-modal/event-rule-modal.component";
import {DeliveryService} from "@biletix/core/services/delivery.service";
import {RouterService} from "@biletix/core/services/router.service";
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import { ImageUrlManager } from '@biletix/core/common/helper/image-url-manager'; 


@Component({
    selector: 'btx-cart-dropdown-item',
    templateUrl: './cart-dropdown-item.component.html',
    styleUrls: ['./cart-dropdown-item.component.scss']
})
export class CartDropdownItemComponent extends AbstractionComponent implements OnInit {

    @Input("items") items: ShoppingItem[] = [];



    

    firstItem: ShoppingItem | any;
    hideSeatNumber: boolean = false;
    panelOpenState = false;

    eventRules?: string;

    constructor(private dialog: MatDialog,
                private localStorage: LocalStorageService,
                private deliveryService: DeliveryService,
                private routerService: RouterService,
                private ApplicationStateService: ApplicationStateService,
                private ticketSaleService: TicketSaleService,
                private fb: FormBuilder,
                private eventService: EventService) {
        super();

    }

    ngOnInit(): void {
        //
        this.firstItem = this.items[0];


        //
        this.getEventRules(this.firstItem?.eventCode);
    }


    getEventRules(eventCode: string) {
        if (eventCode) {
            this.eventService
                .getEventRulesByEventCode(this.firstItem.eventCode)
                .pipe(this.takeUntilDestroy())
                .subscribe(rules => this.eventRules = rules);
        }
    }
 
    isEventRulesVisible(): boolean {
        if (this.eventRules && this.eventRules.length > 0) {
            return true;
        }
        return false;
    }

    getTotalPrice() {
        let totalPrice = 0;
        if (this.items && this.items.length > 0) {
            totalPrice += this.items.reduce((acc, cur) => acc + cur.totalPrice, 0);
        }
        return totalPrice;
    }

  hasZeroPrice(): boolean {
    return this.items.some(item => item.zeroPriceEnabled);
  }

    

    getEventImage(): string {
        return ImageUrlManager.createEventImage(this.firstItem.eventImage);
    }

    get ticketCount() {
        return this.items?.reduce((acc, cur) => acc + cur.seats.length, 0) ?? 0;
    }
    getTotalServicePrice() {
        let totalServicePrice = 0;
        if (this.items && this.items.length > 0) {
            totalServicePrice += this.items.reduce((acc, cur) => acc + cur.servicePrice, 0);
        }
        return totalServicePrice;
    }

    removeItem() {
        this.ticketSaleService
            .removeItemsByEventCodeAndPerfCode(this.firstItem.eventCode, this.firstItem.performanceCode)
            .pipe(this.takeUntilDestroy())
            .subscribe((cart: ShoppingCart) => {
                    if (cart) {
                        this.deliveryService.reloadDeliveryOptions(cart.shoppingItems?.length > 0);
                        this.ticketSaleService.setShoppingCart(cart);
                    }
                }
            );
    }



    getIsMobile() {
        return this.ApplicationStateService.getIsMobileResolution();
    }


    showEventRules() {
        this.dialog.open(EventRuleModalComponent,
            {
                hasBackdrop: true,
                width: '600px',
                role: 'dialog',
                data: this.eventRules 
            }
        );
    }

    get performancePageLink(): string {
        return this.routerService.getPerformancePageUrl(this.firstItem?.eventCode, this.firstItem?.performanceCode);
    }



}
