import {Component, OnInit} from '@angular/core';
import {RegionService} from "@biletix/core/services/region.service";
import {I18nService} from "@biletix/core/services/i18n.service";
import {Locale, Region} from "@biletix/core/common";
import {environment} from "@biletix/env";
import {RouterService} from "@biletix/core/services/router.service";
import { Router } from '@angular/router';

@Component({
  selector: 'btx-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  locales: Locale[];
  currentLocale: Locale;

  regions: Region[];
  currentRegion: Region;

  version:string="";
  footerStyleValue: boolean = false;

  constructor(
    private  regionService: RegionService,
    private i18nService: I18nService,
    public routerService:RouterService,
    private router: Router,) {

    this.locales = this.i18nService.getAvailableLocales();
    this.currentLocale = this.i18nService.currentLocale;
    this.regions = this.regionService.getRegions();
    this.currentRegion = this.regionService.currentRegion;

    router.events.subscribe((val) => {
      // see also
      this.changeFooterStyle();
    });

  }

  ngOnInit(): void {
    this.version = environment.JENKINS_BUILD_NUMBER;
  }

  changeFooterStyle(){
    if (this.router.url.indexOf('/performance') > -1 || this.router.url.indexOf('/cart') > -1) {
      this.footerStyleValue = true;
    }else{
      this.footerStyleValue = false;
    }
  }

  changeLanguage(locale: Locale) {
    this.currentLocale = locale;
    this.i18nService.changeLanguage(locale);
  }

  changeRegion(region:Region){
    this.currentRegion=region;
    this.regionService.changeRegion(region);
  }
}
