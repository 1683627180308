import {Injectable, isDevMode} from '@angular/core';
import {Logger, LogLevel} from "@biletix/core/logging/logger";


@Injectable()
export class ConsoleLogger implements Logger {

  logLevel = LogLevel;

  constructor() {
  }

  log(message?: any) {
    if (isDevMode()) {
      console.log(message);
    }
  }

  error(message?: any) {
    if (isDevMode()) {
      console.error(message);
    }
  }

  warn(message?: any) {
    if (isDevMode()) {
      console.warn(message);
    }
  }



}
