<div [dir]="'dir'| translate" class="cart-page-item"
     fxLayout="column"
     fxLayoutGap="20px">

    <div class="item-header"
         fxLayout="row"
         fxLayoutGap="16px"
         fxFlex="100"
         fxLayoutGap.lt-md="0px">

        <!--        -->
        <btx-event-image [count]="ticketCount"
                         [imageSrc]="firstItem.eventImage|secureImage"
                         [routerLink]="[performancePageLink]"
                         >
        </btx-event-image>


        <!--        -->
        <div class="product-detail" fxLayout="column" fxFlex>
            <div class="product-detail-row" fxLayout="row wrap" fxLayoutAlign="space-between start">
                <btx-sub-title class="event-name-title"
                               [title]="firstItem.eventName |shorten:40"
                               [matTooltip]="firstItem.eventName.length>40 ? firstItem.eventName:''"
                               [routerLink]="[performancePageLink]"></btx-sub-title>
                
            </div>


            <div class="product-detail-row product-action-buttons"
                 fxLayout="row"
                 fxLayoutAlign="space-between center">

                <!-- <div class="event-rules action-buttons"
                     *ngIf="!getIsMobile()">
                    <button class="event-rules"
                            mat-flat-button
                            (click)="showEventRules()"
                            *ngIf="isEventRulesVisible()">
                        <mat-icon aria-hidden="false">rule</mat-icon>
                        {{'shared.labels.eventRules'|translate}}
                    </button>
                </div> -->

                <div class="delete-button-holder">
                    <div class="total-price" fxLayoutAlign="end">
                        <btx-product-price [value]="getTotalPrice()"></btx-product-price>
                    </div>
                    <!-- <div class="service-fee" *ngIf="!hasZeroPrice()">
                        <span class="service-fee-title">{{'shared.labels.serviceFee'|translate}}:</span>

                        <btx-product-price class="service-fee-price"
                                           [value]="getTotalServicePrice()"
                                           >
                        </btx-product-price>

                    </div> -->
                    <button mat-button
                            *ngIf="!getIsMobile()"
                            class="btn-delete"
                            color="warn"
                            (click)="removeItem()">
                        <mat-icon class="material-icons-outlined" aria-hidden="false">delete</mat-icon>
                        {{'shared.labels.remove'|translate}}
                    </button>
                </div>
            </div>
            <!-- <div class="product-detail-row" fxLayout="row" fxLayoutAlign="flex-start center" id="protection">
                <div class="ticket-protection-container">

                    <div class="ticket-protection">
                        <mat-checkbox (change)="onClickShareInfo()">{{'shared.labels.ticketProtection'|translate}}
                        </mat-checkbox>
                        <button mat-icon-button>
                            <mat-icon class="material-icons-outlined">info</mat-icon>
                        </button>
                    </div>
                    <div class="protection-price">
                        TODO:Bilet koruması fiyatları için .ts düzenlenecek
                        ₺18,21
                    </div>
                </div>  -->
            <!-- <div class="action-buttons" *ngIf="!getIsMobile()">
                <button mat-flat-button
                        class="show-ticket-number"
                        (click)="hideSeatNumber = !hideSeatNumber">
                    <mat-icon class="material-icons-outlined">event_seat</mat-icon>
                    {{
                    (hideSeatNumber
                            ? "cart.labels.hideTicketNumbers"
                            : "cart.labels.showTicketNumbers"
                    ) | translate
                    }}
                </button>
            </div> -->
        </div>
    </div> 

    <!-- TODO:    -->
    <div class="item-body"
         fxLayout="column"
         fxLayoutGap="10px"
         *ngIf="!getIsMobile()">

        <!--        -->


    </div>


    <!--    TODO: Html bu şekilde duplicate olmamalı. Media query yazılmalıdır.-->
    <div class="remove" *ngIf="getIsMobile()" fxLayoutAlign="space-around center">
        <button mat-button
                class="btn-delete"
                color="warn"
                (click)="removeItem()">
            <mat-icon class="material-icons-outlined"
                      aria-hidden="false">delete</mat-icon>
            {{'shared.labels.remove'|translate}}
        </button>
    </div>
    <!--  --> 
