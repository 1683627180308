import {Injectable, Inject} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import { DOCUMENT } from '@angular/common';

export interface TypingSEO {
  title?: string;
  titleSuffix?: string;
  description?: string;
  image?: string;
  keywords?: string;
  url?: string;
}

export interface TypingProperty {
  property: string;
  itemprop?: string;
  content: string;
}

export interface TypingName {
  name: string;
  itemprop?: string;
  content: string;
}


@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private titleService: Title,
              private metaTagService: Meta,
              @Inject(DOCUMENT) private doc: any,
              private translate: TranslateService) {
  }

  get metaInstance(): Meta {
    return this.metaTagService;
  }

  get titleInstance(): Title {
    return this.titleService;
  }

  /**
   * @description Set General SEO Tags
   * @param config: TypingSEO
   * @return void
   */
  setTags(config: TypingSEO): void {
    if (config.title !== undefined) {
      this.title(config.title, config.titleSuffix);
    }
    if (config.description !== undefined) {
      this.description(config.description);
    }
    if (config.image !== undefined) {
      this.image(config.image);
    }
    if (config.keywords !== undefined) {
      this.keywords(config.keywords);
    }
    if (config.url !== undefined) {
      this.url(config.url);
    }
  }

  /**
   * @description Set Name Tag
   * @param name: TypingName
   * @param content
   * @return void
   */
  setNameTag(name: TypingName): void {
    const property: any = {
      name: name.name,
      content: name.content,
    };
    if (name.itemprop !== undefined && name.itemprop !== '') {
      property['itemprop'] = name.itemprop;
    }
    if (this.metaTagService.getTag(`name="${property.name}"`)) {
      this.metaTagService.updateTag(property);
    } else {
      this.metaTagService.addTag(property);
    }
  }

  /**
   * @description Set Name Tags
   * @param names: [TypingName]
   * @return void
   */
  setNameTags(names: [TypingName]): void {
    names.forEach(prop => {
      this.setNameTag(prop);
    });
  }

  /**
   * @description Set Property Tag
   * @param prop: TypingProperty
   * @param content
   * @return void
   */
  setPropertyTag(prop: TypingProperty): void {
    const property:any = {
      property: prop.property,
      content: prop.content,
    };
    if (prop.itemprop !== undefined && prop.itemprop !== '') {
      property['itemprop'] = prop.itemprop;
    }
    if (this.metaTagService.getTag(`property="${property.property}"`)) {
      this.metaTagService.updateTag(property);
    } else {
      this.metaTagService.addTag(property);
    }
  }

  /**
   * @description Set Property Tags
   * @param props: [TypingProperty]
   * @return void
   */
  setPropertyTags(props: [TypingProperty]): void {
    props.forEach(prop => {
      this.setPropertyTag(prop);
    });
  }

  /**
   * @description Set URL Tag
   * @param content: string
   * @return void
   */
  url(content: string): void {
    this.metaTagService.updateTag({property: 'og:url', itemprop: 'url', content: `${content}`});
  }

  ogUrl(){
    this.metaTagService.updateTag({property: 'og:url', content: this.doc.URL});
  }

  /**
   * @description Set Title Tag
   * @param title: string
   * @param titleSuffix: string
   * @return void
   */
  title(title: string, titleSuffix?: string): void {
    const setTitle = (titleSuffix !== undefined && titleSuffix !== '') ? `${title} ${titleSuffix}` : title;
    this.titleService.setTitle(setTitle);
    this.setPropertyTag({property: 'og:title', itemprop: 'title', content: setTitle});
    this.setPropertyTag({property: 'twitter:title', itemprop: 'title', content: setTitle});
  }

  /**
   * @description Set Description Tag
   * @param content: string
   * @return void
   */
  description(content: string): void {
    this.setNameTag({name: 'description', itemprop: 'description', content: content});
    this.setPropertyTag({property: 'og:description', itemprop: 'description', content: content});
    this.setPropertyTag({property: 'twitter:description', itemprop: 'description', content: content});
  }

  /**
   * @description Set Image Tag
   * @param content: string
   * @return void
   */
  image(content: string): void {
    this.setPropertyTag({property: 'twitter:image', itemprop: 'image', content: content});
    this.setPropertyTag({property: 'og:image', itemprop: 'image', content: content});
    this.setPropertyTag({property: 'og:image:secure_url', itemprop: 'image', content: content});
  }

  /**
   * @description Set Keywords Tag
   * @param content: string
   * @return void
   */
  keywords(content: string): void {
    this.setNameTag({name: 'keywords', itemprop: 'keywords', content: content});
  }
 
  createLinkForCanonicalURL() {

    let link: HTMLLinkElement | null = this.doc.querySelector('link[rel="canonical"]');

    if (link) {
        link.setAttribute('href', this.doc.URL);
    } else {
      let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', this.doc.URL);
    }
    
    
 }


 createNoIndex() {
  let link: HTMLLinkElement = this.doc.createElement('meta');
  link.setAttribute('name', 'robots');
  this.doc.head.appendChild(link);
  link.setAttribute('content', 'noindex');
}




}
