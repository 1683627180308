import {Directive, ElementRef, HostBinding, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[checkToggle]'
})
export class FooterToggleDirective {


  /**
   * Test amaçlı eklendi.
   */
  @Input('multi') multi: boolean = false;

  /**
   *
   */
  @HostBinding('class.open') isOpen = false;


  constructor(private elRef: ElementRef) {
  }

  @HostListener('document:click', ['$event']) toggleOpen(event: Event) {
    this.isOpen = this.elRef.nativeElement.contains(event.target) ? !this.isOpen : false;
  }


  // @HostListener('click', ['$event']) onClick(event: Event) {
  //
  // }


}
