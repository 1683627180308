import {Inject, Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {BehaviorSubject} from "rxjs";
import {DEFAULT_LOCALE_VALUE, DEFAULT_LOCALES, Locale} from "@biletix/core/common";
import {ActivatedRoute, Router} from "@angular/router";
import {LocalStorageService} from "@biletix/core/services/local-storage.service";
import {StorageKey} from "@biletix/core/services/storage.service";
import {ConsoleLogger} from "@biletix/core/logging/console.logger";
import {DateAdapter} from "@angular/material/core";
import {DOCUMENT} from "@angular/common";

@Injectable({
    providedIn: "root"
})
export class I18nService {

    changeLangEvent: BehaviorSubject<string>;

    private _currentLocale: Locale | any;

    constructor(private translate: TranslateService,
                private logger: ConsoleLogger,
                private localStorage: LocalStorageService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                @Inject(DEFAULT_LOCALE_VALUE) private  lang: string | any,
                @Inject(DEFAULT_LOCALES) private defaultLocales: Locale[],
                @Inject(DOCUMENT) private document: Document,
                private _adapter: DateAdapter<any>
    ) {

        if (this.lang == null && this.lang == '' || !this.isAvailableLanguage(this.lang)) {
            throw new Error(`Please check that your locale settings locale:` + this.lang);
        }

        let availableLanguages: string[] = defaultLocales.map(item => item.lang);
        this.translate.addLangs(availableLanguages);

        const locale = this.getLocale();

        this.changeLangEvent = new BehaviorSubject<string>(locale?.lang);

        this._currentLocale=this.localStorage.get(StorageKey.LOCALE);

        
       

        //this._adapter.setLocale(locale?.lang);

        //this.setHtmlLanguage(locale?.lang);

        //this._currentLocale = locale;

        //this.useLanguage(locale);
    }


    changeLanguage(locale: Locale) {
        //path param da lang var ise güncelle
        const url = this.router.routerState.snapshot.url.replace(`/${this.currentLang}`, `/${locale.lang}`);
        
        // Tek çağırışta değişmediği için 2 kere çağrıldı
        this.router.navigateByUrl(url)//
            .then(() => this.router.navigateByUrl(url)//
                .then(() =>  window.location.reload()));
    }

    useLanguage(locale: Locale) {
        this._currentLocale = locale;
        this.localStorage.set(StorageKey.LOCALE, this._currentLocale);
        this.setHtmlLanguage(this.language);
        this.changeLangEvent.next(this.language);
        this._adapter.setLocale(this.language);
        return this.translate.use(this.language);
    }

    setHtmlLanguage(lang: string) {
        this.document.documentElement.lang = lang ?? this.lang;
    }

    get currentLang(): string {
        let locale = this.localStorage.get(StorageKey.LOCALE);
        if (locale && this.isAvailableLanguage(locale.lang))
            return locale.lang;

        return this.language;
    }

    get language(): string {
        return this._currentLocale?.lang ?? this.lang;
    }

    public isAvailableLanguage(lang: string) {
        return this.defaultLocales.some(x => x.lang === lang);
    }

    getAvailableLocales(): Locale[] {
        return this.defaultLocales;
    }

    get currentLocale() {
        return this._currentLocale;
    }


    getLocaleByLang(lang: string): Locale | any {
        return this.defaultLocales?.find(item => item.lang == lang);
    }

    getLocale() {
        let locale: Locale = this.localStorage.get(StorageKey.LOCALE);
        if (locale && this.isAvailableLanguage(locale.lang)) {
            return locale;
        }
        return this.getLocaleByLang(this.lang);
    }

}
