import {Component, Inject, OnInit} from '@angular/core';
import {WINDOW} from "@biletix/core/common";
import {RouterService} from "@biletix/core/services/router.service";
import {environment} from "@biletix/env";

@Component({
    selector: 'btx-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

    constructor(@Inject(WINDOW) private window: Window,
                private routerService: RouterService) {


        //TODO: Şimdilik seam tarafına yönledirildi.
        //this.onNavigate();
    }

    ngOnInit(): void {

    }

    onNavigate() {
        this.window.location.href = environment.biletixWebHost + '/404-html/404.html';
    }

}
