import { Injectable } from '@angular/core';
import { ApiService } from '@biletix/core/services/api.service';
import { TicketInput } from '@biletix/core/models/sale/ticket-input.model';
import { Observable, of } from 'rxjs';
import { ShoppingCart } from '@biletix/core/models/shopping-cart/shopping-cart.model';
import { ApiResponse } from '@biletix/core/models/api-response';
import { map, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from '@biletix/core/services/local-storage.service';
import { StorageKey } from '@biletix/core/services/storage.service';
import { UpsellInput } from '@biletix/core/models/sale/upsell-input.model';
import { ShoppingItem } from '@biletix/core/models/shopping-cart/shopping-item.model';
import { Location } from '@angular/common';
import { AuthenticationService } from '@biletix/core/services/authentication.service';
import { CartTimeoutService } from '@biletix/core/services/cart-timeout.service';
import { Customer } from '@biletix/core/models/customer/customer.model';
import { ShareCustomerInfo } from '../models/shopping-cart/share-customer-info';

@Injectable()
export class TicketSaleService {
  openBasket: boolean = false;
  private _ticketSaleBasePath = '/api/v1/ticketSales';

  constructor(
    private apiUrlService: ApiService,
    private http: HttpClient,
    private location: Location,
    private authenticationService: AuthenticationService,
    private localStorage: LocalStorageService
  ) {}

  /**
   *
   * @param shoppingCart
   */
  setShoppingCart(shoppingCart: ShoppingCart | any) {
    this.localStorage.set(StorageKey.SHOPPING_CART, shoppingCart);
  }

  /**
   *
   */
  get watchShoppingCart(): Observable<ShoppingCart> {
    return this.localStorage.watch<ShoppingCart>(StorageKey.SHOPPING_CART);
  }

  /**
   *
   */
  getLocalShoppingCart(): ShoppingCart {
    return this.localStorage.get<ShoppingCart>(StorageKey.SHOPPING_CART);
  }

  getShoppingCart(): Observable<ShoppingCart> {
    return this.http //
      .get<ApiResponse<ShoppingCart>>(
        `${this.apiUrlService.getCrmApiHost()}${
          this._ticketSaleBasePath
        }/getShoppingCart`
      ) //
      .pipe(map((response) => response.data));
  }

  /**
   *
   */
  public isTimeout() {
    // let refreshTime: number = Date.parse(this.localStorage.get(StorageKey.SHOPPING_CART_REFRESH_TIME));
    // return (!refreshTime || refreshTime < Date.now());
    return false;
  }

  /**
   *
   * @param input
   */
  addTicket(input: TicketInput): Observable<ShoppingCart> {
    return this.http //
      .post<ApiResponse<ShoppingCart>>(
        `${this.apiUrlService.getCrmApiHost()}${
          this._ticketSaleBasePath
        }/addTicket`,
        input
      ) //
      .pipe(map((response) => response.data));
  }

  /**
   * TODO: Delete işleminde get isteği kullanılmamalı. Api değiştirilmeli
   * @param itemNo
   */
  removeTicket(itemNo: number | string): Observable<ShoppingCart> {
    return this.http //
      .get<ApiResponse<ShoppingCart>>(
        `${this.apiUrlService.getCrmApiHost()}${
          this._ticketSaleBasePath
        }/removeTicket/${itemNo}`
      ) //
      .pipe(map((response) => response.data));
  }

  /**
   *
   */
  clearShoppingCart(): Observable<void> {
    return this.http //
      .get<ApiResponse<void>>(
        `${this.apiUrlService.getCrmApiHost()}${
          this._ticketSaleBasePath
        }/clearShoppingCart`
      ) //
      .pipe(
        map((response) => {
          this.clearLocalShoppingCart();
          return response.data;
        })
      );
  }

  setShareCustomerInfo(input: ShareCustomerInfo[]): Observable<ShoppingCart> {
    return this.http //
      .post<ApiResponse<ShoppingCart>>(
        `${this.apiUrlService.getCrmApiHost()}${
          this._ticketSaleBasePath
        }/setShareCustomerInfo`,
        input
      ) //
      .pipe(map((response) => response.data));
  }

  /**
   *
   */
  clearLocalShoppingCart() {
    //local de shopping-cart cartı temizle
    this.setShoppingCart(null);
  }

  isExistShoppingCart(): boolean {
    let cart = this.getLocalShoppingCart();
    if (cart == null || cart.shoppingItems?.length == 0) {
      return false;
    }
    return true;
  }

  /**
   * TODO: Upsell ekleme metodu kaldırılacak veya tek servis ile bu işlem yapılacak.
   * @param upsellInputs
   */
  addAllUpsell(upsellInputs: UpsellInput[]): Observable<ShoppingCart> {
    return this.http //
      .post<ApiResponse<ShoppingCart>>(
        `${this.apiUrlService.getCrmApiHost()}${
          this._ticketSaleBasePath
        }/addAllUpsell`,
        upsellInputs
      ) //
      .pipe(map((response) => response.data));
  }

  /**
   * TODO: Delete işleminde get isteği kullanılmamalı. Api değiştirilmeli
   * @param itemNo
   */
  removeUpsell(itemNo: number | string): Observable<ShoppingCart> {
    return this.http //
      .get<ApiResponse<ShoppingCart>>(
        `${this.apiUrlService.getCrmApiHost()}${
          this._ticketSaleBasePath
        }/removeUpsell/${itemNo}`
      ) //
      .pipe(map((response) => response.data));
  }

  getSelectedTicketCountByActivePerformance(
    eventCode: string,
    perfCode: string
  ) {
    const shoppingCart: ShoppingCart = this.getLocalShoppingCart();
    if (shoppingCart && shoppingCart.shoppingItems) {
      return (
        shoppingCart.shoppingItems
          .filter(
            (item) =>
              item.eventCode == eventCode && item.performanceCode == perfCode
          )
          .reduce((c, item: ShoppingItem) => c + item.quantity, 0) ?? 0
      );
    }
    return 0;
  }

  isExistTicketsByEventCodeAndPerfCode(eventCode: string, perfCode: string) {
    const shoppingCart: ShoppingCart = this.getLocalShoppingCart();
    if (shoppingCart && shoppingCart.shoppingItems) {
      return (
        shoppingCart.shoppingItems.some(
          (item) =>
            item.eventCode == eventCode && item.performanceCode == perfCode
        ) ?? false
      );
    }
    return false;
  }

  /**
   *
   * @param eventCode
   */
  removeItemsByEventCodeAndPerfCode(eventCode: string, perfCode: string) {
    return this.http //
      .delete<ApiResponse<ShoppingCart>>(
        `${this.apiUrlService.getCrmApiHost()}${
          this._ticketSaleBasePath
        }/removeItemsByEventCodeAndPerfCode/${eventCode}/${perfCode}`
      ) //
      .pipe(map((response) => response.data));
  }

  /**
   *
   */
  confirmSale(): Observable<ApiResponse<ShoppingCart>> {
    return this.http //
      .get<ApiResponse<ShoppingCart>>(
        `${this.apiUrlService.getCrmApiHost()}${
          this._ticketSaleBasePath
        }/confirmSale`
      );
  }

  useLoggedInCustomer(): Observable<Customer> {
    return this.http //
      .get<ApiResponse<Customer>>(
        `${this.apiUrlService.getCrmApiHost()}${
          this._ticketSaleBasePath
        }/useLoggedInCustomer`
      )
      .pipe(map((response) => response.data));
  }
}
