import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {ApiResponse} from "@biletix/core/models/api-response";
import {ApiResponseStatus} from "@biletix/core/models/api-response-status";
import {Router} from "@angular/router";
import {ErrorStatusCode} from "@biletix/core/common/constants/error-status-code.const";
import {LocalStorageService} from "@biletix/core/services/local-storage.service";
import {AuthenticationService} from "@biletix/core/services/authentication.service";
import {NotificationService} from "@biletix/core/services/notification.service";
import {TicketSaleService} from "@biletix/core/services/ticket-sale.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private localStorage: LocalStorageService,
              private notificationService: NotificationService,
              private ticketSaleService: TicketSaleService,
              private authenticationService: AuthenticationService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          //console.log("http event "+req.url+" "+JSON.stringify(event));
          if (event instanceof HttpResponse && event.ok) {
            console.log("http event "+req.url+" responseCode : "+event.status);
            this.checkServiceResult(event);
          }else{
            //console.log("http event "+req.url+" responseCode : ",event);
          }
        }
      ),
      catchError(this.handleError)
    )
  }


  checkServiceResult(event: HttpResponse<any>) {
    if (event && event.body != null) {

      const apiResponse = <ApiResponse<any>>event.body;
      if (apiResponse && apiResponse.status == ApiResponseStatus.ERROR) {

        let showErrorMessage=true;

        apiResponse.errors?.forEach(apiError => {
          switch (apiError.code) {

            case ErrorStatusCode.CUSTOMER_REQUIRED:
            case ErrorStatusCode.USER_REQUIRED:
              this.authenticationService.navigateToLogin();
              showErrorMessage=false;
              break;

            case ErrorStatusCode.INVALID_CHANNEL_HEADER:

              break;
            case ErrorStatusCode.SHOPPING_CART_EMPTY:
              this.ticketSaleService.clearLocalShoppingCart();
              break;
            //default
            default:
              break;
          }
        });

        /**
         * TODO: Geniş bir zamanda kod refactor edilecek. Bütün hatalar global error handler a atılıcak.
         */
        if (showErrorMessage){
          console.log("Api returned error",apiResponse.errors);
          this.notificationService.error(apiResponse.errors);
        }

      }
    }
  }

  handleError(error: HttpErrorResponse | any) {
    console.log("Handle error called");
    if (error.status == 401) {
      this.authenticationService.navigateToLogin();
    }
    return throwError(error) //goes to the Global-Error-Handler
  }

}
