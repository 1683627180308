import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractionComponent} from "@biletix/core/common/abstraction-component";
import {RouterService} from "@biletix/core/services/router.service";
import {ApplicationStateService} from '@biletix/core/services/application-state.service';3
import {ShoppingCartState} from "@biletix/core/models/state/shopping-cart-state.model";
import {ShoppingCart} from "@biletix/core/models/shopping-cart/shopping-cart.model";
import {PerformancePageStateService} from "@biletix/features/performance/services/performance-page-state.service";
import {TicketSaleService} from "@biletix/core/services/ticket-sale.service";
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import { Router } from '@angular/router';
import { CartTimeoutService } from '@biletix/core/services/cart-timeout.service';
import { I18nService } from '@biletix/core/services/i18n.service';
import { Locale } from '@biletix/core/common';
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'btx-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends AbstractionComponent implements OnInit {

  @Output() public sidenavToggle = new EventEmitter();
  ticketCount$: Observable<number> = of(0);
  shoppingCartState?: ShoppingCartState;
  //cartStateValue: boolean = false;

  locales: Locale[];
  currentLocale: Locale;

  constructor(
    private routerService: RouterService,
    private performancePageStateService: PerformancePageStateService,
    private i18n: I18nService,
    private cartTimeoutService: CartTimeoutService,
    private ticketSaleService: TicketSaleService,
    private translate: TranslateService,
    private router: Router,
    private ApplicationStateService: ApplicationStateService) {
    super();

    this.locales = this.i18n.getAvailableLocales();
    this.currentLocale = this.i18n.currentLocale;
    // router.events.subscribe((val) => {
    //   // see also
    //   this.changeCartState();
    // });
  }

    

  ngOnInit(): void {
    // this.performancePageStateService
    //         .shoppingCartStateChange
    //         .pipe(this.takeUntilDestroy())
    //         .subscribe(cart => {
    //           console.log("Header cart state changed");
    //           this.shoppingCartState = cart
    //         });

    this.cartTimeoutService
    .shoppingCartStateChange
    .pipe(this.takeUntilDestroy())
    .subscribe(shoppingCartState =>{ 
        this.shoppingCartState =shoppingCartState;
    });
    
    this.ticketCount$ = this.ticketSaleService
            .watchShoppingCart
            .pipe(map((cart: ShoppingCart) => cart?.totalTicket ?? 0));

  this.currentLocale = this.i18n.currentLocale;
  }

  // changeCartState(){
  //   if (this.router.url.indexOf('/cart') > -1) {
  //     this.cartStateValue = true;
  //   }else{
  //     this.cartStateValue = false;
  //   }
  // }

  showTimerInHeader(){
    //const isMobile = this.getIsMobile();
    const isExistShoppingCart =this.isExistShoppingCart();
    const isCartPage =this.isCartPage();
    const isOrderSummaryExists =this.isOrderSummaryExists();

    //return !isMobile && isExistShoppingCart && !isCartPage&&!isOrderSummaryExists;
    return isExistShoppingCart && !isCartPage&&!isOrderSummaryExists;

  }
  
  isOrderSummaryExists(){
    return this.shoppingCartState && this.shoppingCartState.isNotEmpty();
  }

  isCartPage() {
    return this.router.url.indexOf('/cart') > -1;
  }

  getIsMobile() {
      return this.ApplicationStateService.getIsMobileResolution();
  }

  isExistShoppingCart(): boolean {
      return this.ticketSaleService.isExistShoppingCart();
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  ngAfterViewInit(): void{
    
  }

}
