import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ApiService} from "@biletix/core/services/api.service";
import {ApiResponse} from "@biletix/core/models/api-response";
import {SaveCustomerInput} from "@biletix/core/models/customer/save-customer-input.model";
import {map} from "rxjs/operators";
import {Customer} from "@biletix/core/models/customer/customer.model";
import {CustomerAddress} from "@biletix/core/models/customer/customer-address.model";
import {LocalStorageService} from "@biletix/core/services/local-storage.service";

@Injectable()
export class CustomerService {


  private basePath: string = 'api/v1/customer';

  constructor(private httpClient: HttpClient,
              private apiService: ApiService,
              private localStorageService:LocalStorageService) {
  }
  /**
   *
   * @param saveCustomerInput
   */
  getLoggedInCustomer(): Observable<ApiResponse<Customer>> {
    return this.httpClient.get<ApiResponse<Customer>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/getLoggedInCustomer`,
    );
  }

}
