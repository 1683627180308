import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment-timezone';

/**
 * @author ozaytunctan
 * @version 2.0
 *
 *  Her seferinde çağrılsın bir değişiklik algılandığında.
 *  Mesela dil değiştiğinde parametreler güncellenmez ama içerdeki dil değiştiğinden false olarak ayarlandıki her seferinde kontrol etsin.
 */
@Pipe({
    name: 'localizedDate',
    pure: false
  }
)
export class LocalizedDatePipe implements PipeTransform {
  static defaultTimezone:string="Asia/Jerusalem";

  constructor(
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) {
  }

  /**
   *
   * @param value
   * @param format
   */
  transform(value: Date | string | number, format: string = 'mediumDate',useClientTimezone:boolean=false): any {
    if (value){
      let timezoneOffset = moment(value).tz(LocalizedDatePipe.defaultTimezone).format('Z');
      return this.datePipe.transform(
        value,
        format,
        timezoneOffset,
        this.translateService.currentLang
      );
    }
  }
}
