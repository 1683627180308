import {Component, OnInit} from '@angular/core';
import {environment} from "@biletix/env";
import {Locale, Region} from "@biletix/core/common";
import {RouterService} from "@biletix/core/services/router.service";
import {RegionService} from "@biletix/core/services/region.service";
import {I18nService} from "@biletix/core/services/i18n.service";
import {AbstractionComponent} from "@biletix/core/common/abstraction-component";

export interface EventCategoryItem {
    categoryName: string;
    linkLabel?: string;
    value: string | any;
    icon?: string;
    url?: string;
    children?: EventCategoryItem[]
}


@Component({
    selector: 'btx-event-category-mobile-menu',
    templateUrl: './event-category-mobile-menu.component.html',
    styleUrls: ['./event-category-mobile-menu.component.scss']
})
export class EventCategoryMobileMenuComponent extends AbstractionComponent implements OnInit {

    locales: Locale[];
    currentLocale: Locale;

    regions: Region[];
    currentRegion: Region;

    categories: EventCategoryItem[] = [];
    host: string = environment.biletixWebHost;

    panelOpenState = false;

    regionAndLanguagePath: string | any;

    categoryMenuExpanded: boolean = false;

    constructor(private routerService: RouterService,
                private  region: RegionService,
                private regionService: RegionService,
                private i18n: I18nService
                ) {
            super();

            this.locales = this.i18n.getAvailableLocales();
            this.currentLocale = this.i18n.currentLocale;
            this.regions = this.region.getRegions();
            this.currentRegion = this.region.currentRegion;
    }

    ngOnInit() {
        //
        this.initializeVariables();
        //
        this.onRegionChangeSubscription();

        //
        this.onLanguageChangeSubscription();
    }

    initializeVariables() {
        this.regionAndLanguagePath = this.routerService.regionLanguagePath;
        this.categories = this.createEventCategories();
    }

    onRegionChangeSubscription() {
        this.regionService.regionChange
            .pipe(this.takeUntilDestroy())
            .subscribe(() => this.initializeVariables())
    }

    onLanguageChangeSubscription() {
        this.i18n.changeLangEvent
            .pipe(this.takeUntilDestroy())
            .subscribe(() => this.initializeVariables())
    }

    public changeLanguage(locale: Locale) {
        this.currentLocale = locale;
        this.i18n.changeLanguage(locale);
    }

    public changeRegion(region: Region) {
        this.currentRegion = region;
        this.region.changeRegion(region);
    }

    /**
     * TODO: Etkinlik Kategori ve sub categorileri servisten alınmalıdır.
     *
     * Mobile için de kullanılabilir. Static olmamalıdır.
     */
    createEventCategories(): EventCategoryItem[] {
        //TODO: homepage ekran tasarımı yapıldığında burası düzenlenecek..
        // https://www.biletix.com/category/MUSIC/ALL/iw
        // https://www.biletix.com/search/ALL/iw#subcategory:pop$MUSIC&qt=standard

        const categories: EventCategoryItem[] = [

            //Music
            {
                categoryName: 'main.category.music',
                linkLabel: 'main.category.seeAllMusicEvents',
                value: 'MUSIC',
                url: `${this.host}/category/MUSIC/${this.regionAndLanguagePath}`,
                children: [
                    {
                        categoryName: 'main.subcategory.music.pop',
                        value: 'pop',
                        icon: 'assets/images/category/pop.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:pop$MUSIC&qt=standard`
                    },
                    {
                        categoryName: 'main.subcategory.music.rock',
                        value: 'rock',
                        icon: 'assets/images/category/rock.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:rock$MUSIC&qt=standard`
                    },
                    {
                        categoryName: 'main.subcategory.music.jazz',
                        value: 'jazz',
                        icon: 'assets/images/category/jazz.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:jazz$MUSIC&qt=standard`
                    },
                    {
                        categoryName: 'main.subcategory.music.classical',
                        value: 'klasik',
                        icon: 'assets/images/category/classical.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:klasik$MUSIC&qt=standard`
                    },
                    {
                        categoryName: 'main.subcategory.music.alternative',
                        value: 'alternative',
                        icon: 'assets/images/category/alternative.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:alternatif$MUSIC&qt=standard`
                    }

                ]

            },
            //Stage
            {
                categoryName: 'main.category.stage',
                linkLabel: 'main.category.seeAllArtEvents',
                value: 'ART',
                url: `${this.host}/category/ART/${this.regionAndLanguagePath}`,
                children: [
                    {
                        categoryName: 'main.subcategory.stage.theater',
                        value: 'tiyatro',
                        icon: 'assets/images/category/theatre.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:tiyatro$ART&qt=standard`
                    },
                    {
                        categoryName: 'main.subcategory.stage.show',
                        value: 'gosteri',
                        icon: 'assets/images/category/show.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:gosteri$ART&qt=standard`
                    },
                    {
                        categoryName: 'main.subcategory.stage.dance',
                        value: 'bale_dans',
                        icon: 'assets/images/category/dance.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:bale_dans$ART&qt=standard`
                    },
                    {
                        categoryName: 'main.subcategory.stage.standUp',
                        value: 'stand_up',
                        icon: 'assets/images/category/standup.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:stand_up$ART&qt=standard`
                    }

                ]
            },
            //Sport
            {
                categoryName: 'main.category.sport',
                linkLabel: 'main.category.seeAllSportEvents',
                value: 'SPORT',
                url: `${this.host}/category/SPORT/${this.regionAndLanguagePath}`,
                children: [
                    {
                        categoryName: 'main.subcategory.sport.football',
                        value: 'futbol',
                        icon: 'assets/images/category/football.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:futbol$SPORT&qt=standard`
                    },
                    {
                        categoryName: 'main.subcategory.sport.basketball',
                        value: 'basketbol',
                        icon: 'assets/images/category/basketball.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:basketbol$SPORT&qt=standard`
                    },
                    {
                        categoryName: 'main.subcategory.sport.motorsports',
                        value: 'motorspor',
                        icon: 'assets/images/category/motorsports.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:motorspor$SPORT&qt=standard`
                    },
                    {
                        categoryName: 'main.subcategory.sport.volleyball',
                        value: 'voleybol',
                        icon: 'assets/images/category/volleyball.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:voleybol$SPORT&qt=standard`
                    }

                ]

            },
            //Family
            {
                categoryName: 'main.category.family',
                linkLabel: 'main.category.seeAllFamilyEvents',
                value: 'FAMILY',
                url: `${this.host}/category/FAMILY/${this.regionAndLanguagePath}`,
                children: [
                    {
                        categoryName: 'main.subcategory.family.show',
                        value: 'gosteri',
                        icon: 'assets/images/category/familyshow.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:gosteri$FAMILY&qt=standard`
                    },
                    {
                        categoryName: 'main.subcategory.family.circus',
                        value: 'sirk',
                        icon: 'assets/images/category/circus.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:sirk$FAMILY&qt=standard`
                    },
                    {
                        categoryName: 'main.subcategory.family.theater',
                        value: 'tiyatro',
                        icon: 'assets/images/category/familytheatre.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:tiyatro$FAMILY&qt=standard`
                    },
                    {
                        categoryName: 'main.subcategory.family.musical',
                        value: 'musical',
                        icon: 'assets/images/category/musical.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:musical$FAMILY&qt=standard`
                    },
                    {
                        categoryName: 'main.subcategory.family.zoo',
                        value: 'zoo',
                        icon: 'assets/images/category/zoo.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:zoo$FAMILY&qt=standard`
                    },
                    {
                        categoryName: 'main.subcategory.family.themePark',
                        value: 'themepark',
                        icon: 'assets/images/category/themepark.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:themepark$FAMILY&qt=standard`
                    },
                    {
                        categoryName: 'main.subcategory.family.attractionCenter',
                        value: 'attractioncenter',
                        icon: 'assets/images/category/attractioncenter.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:attractioncenter$FAMILY&qt=standard`
                    },
                    {
                        categoryName: 'main.subcategory.family.minimaster',
                        value: 'minimaster',
                        icon: 'assets/images/category/minimaster.png',
                        url: `${this.host}/search/${this.regionAndLanguagePath}#subcategory:minimaster$FAMILY&qt=standard`
                    }


                ]

            },

            //Education & More
            {
                categoryName: 'main.category.education',
                linkLabel: 'main.category.education',
                value: 'EDUCATION',
                url: `${this.host}/category/OTHER/${this.regionAndLanguagePath}`,
                children: [
                    {
                        categoryName: 'main.subcategory.education.museum',
                        value: 'museum',
                        icon: 'assets/images/category/museum.png',
                        url: `${this.host}/museum/${this.regionAndLanguagePath}`
                    },
                    {
                        categoryName: 'main.subcategory.education.seyretix',
                        value: 'seyretix',
                        icon: 'assets/images/category/seyretix.png',
                        url: `${this.host}/seyretix/${this.regionAndLanguagePath}`
                    },
                    {
                        categoryName: 'main.subcategory.education.msa',
                        value: 'msa',
                        icon: 'assets/images/category/msa.png',
                        url: `https://guides.biletix.com/msa/${this.regionAndLanguagePath}`
                    },
                    {
                        categoryName: 'main.subcategory.education.kulup',
                        value: 'kulup',
                        icon: 'assets/images/category/kulup.png',
                        url: `https://guides.biletix.com/kulup/${this.regionAndLanguagePath}`
                    },
                    {
                        categoryName: 'main.subcategory.education.onstage',
                        value: 'tiyatrolar',
                        icon: 'assets/images/category/onstage.png',
                        url: `https://guides.biletix.com/tiyatrolar/${this.regionAndLanguagePath}`
                    },
                    {
                        categoryName: 'main.subcategory.education.minimaster',
                        value: 'minimaster',
                        icon: 'assets/images/category/minimaster.png',
                        url: `${this.host}/minimaster/${this.regionAndLanguagePath}`
                    }
                ]

            },
        ];
        return categories;

    }

}
