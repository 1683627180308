import {ErrorHandler, NgModule, Optional, SkipSelf} from '@angular/core';
import {
    CurrencyPipe,
    DatePipe,
    Location,
    LocationStrategy,
    PathLocationStrategy,
    registerLocaleData
} from "@angular/common";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {ToastrModule} from "ngx-toastr";
import {IConfig, NgxMaskModule} from "ngx-mask";
import {
    DEFAULT_LOCALE_VALUE,
    DEFAULT_LOCALES,
    DEFAULT_REGION_VALUE,
    LOCAL_STORAGE_PROVIDER,
    USE_DEFAULT_REGIONS,
    WINDOW_PROVIDER
} from "@biletix/core/common";
import {HTTP_INTERCEPTORS_PROVIDERS} from "@biletix/core/interceptors/interceptors.provider";

import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {EventService} from "@biletix/core/services/event.service";
import {ConsoleLogger} from "@biletix/core/logging/console.logger";
import {NotificationService} from "@biletix/core/services/notification.service";
import {LocalStorageService} from "@biletix/core/services/local-storage.service";
import {I18nService} from "@biletix/core/services/i18n.service";
import {GlobalErrorHandler} from "@biletix/core/services/global-error-handler.service";
import {LanguageGuard} from "@biletix/core/guards/language.guard";
import {LOADING_CONFIG_DEFAULT, LoadingScreenService} from "@biletix/core/components/loading/loading-screen.service";
import {OverlayService} from "@biletix/core/services/overlay.service";
import {AuthenticationGuard} from "@biletix/core/guards/authentication.guard";
import {CustomerAccountService} from "@biletix/core/services/customer-account.service";
import {ApplicationStateService} from "@biletix/core/services/application-state.service";
import {AuthenticationService} from "@biletix/core/services/authentication.service";
import {TicketSaleService} from "@biletix/core/services/ticket-sale.service";
import {CreditCardTypeService} from "@biletix/core/services/credit-card-type.service";
import {RegionGuard} from "@biletix/core/guards/region.guard";
import {CustomPaginatorIntl} from "@biletix/core/configurations/custom-paginator-intl";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {RegionService} from "@biletix/core/services/region.service";
import {DeliveryService} from "@biletix/core/services/delivery.service";
import {AnonymousGuard} from "@biletix/core/guards/anonymous.guard";
import {CustomerService} from "@biletix/core/services/customer.service";
import {ShoppingCartResolver} from "@biletix/core/resolvers/shopping-cart.resolver";
import {ShoppingCartGuard} from "@biletix/core/guards/shopping-cart.guard";
import {LoggedInCustomerResolver} from "@biletix/core/resolvers/logged-in-customer.resolver";
import {NavigationService} from "@biletix/core/services/navigation.service";
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MAT_MOMENT_DATE_FORMATS,
    MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {RouterService} from "@biletix/core/services/router.service";

import he from '@angular/common/locales/he';
import en from '@angular/common/locales/en';
import {CountdownGlobalConfig, CountdownModule} from "ngx-countdown";
import {MaterialModule} from "@biletix/core/material/material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MainLayoutComponent} from "@biletix/core/layouts/main-layout/main-layout.component";
import {HeaderComponent} from "@biletix/core/layouts/main-layout/header/header.component";
import {FooterComponent} from "@biletix/core/layouts/main-layout/footer/footer.component";
import {LoadingComponent} from "@biletix/core/components/loading/loading.component";
import {AccountDropdownComponent} from "@biletix/core/layouts/main-layout/header/account-dropdown/account-dropdown.component";
import {CartDropdownComponent} from "@biletix/core/layouts/main-layout/header/cart-dropdown/cart-dropdown.component";
import {EventSearchBoxComponent} from "@biletix/core/layouts/main-layout/header/event-search-box/event-search-box.component";
import {EventCategoryMenuComponent} from "@biletix/core/layouts/main-layout/header/event-category-menu/event-category-menu.component";
import {EventCategoryMobileMenuComponent} from "@biletix/core/layouts/main-layout/header/event-category-mobile-menu/event-category-mobile-menu.component";
import {TopBandComponent} from "@biletix/core/layouts/main-layout/header/top-band/top-band.component";
import {EventSearchNotFoundComponent} from "@biletix/core/layouts/main-layout/header/event-search-box/event-search-not-found/event-search-not-found.component";
import {SecondaryLayoutComponent} from "@biletix/core/layouts/secondary-layout/secondary-layout.component";
import {EmptyHeaderComponent} from "@biletix/core/layouts/secondary-layout/empty-header/empty-header.component";
import {EmptyFooterComponent} from "@biletix/core/layouts/secondary-layout/empty-footer/empty-footer.component";
import {AuthLayoutComponent} from "@biletix/core/layouts/auth-layout/auth-layout.component";
import {BrowserModule, Title} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RouterModule} from "@angular/router";
import {FlexLayoutModule} from "@angular/flex-layout";
import {SiteLogoComponent} from './layouts/site-logo/site-logo.component';
import {SideNavComponent} from './layouts/side-nav/side-nav.component';
import {FooterToggleDirective} from './layouts/footer-toggle.directive';
import {GlobalParameters} from "@biletix/core/common/constants/global-parameters";
import {CartTimeoutService} from "@biletix/core/services/cart-timeout.service";
import {CartResetTimeoutResolver} from "@biletix/core/resolvers/cart-reset-timeout.resolver";
import {GlobalConfig} from "ngx-toastr/toastr/toastr-config";
import {TranslateModuleConfig} from "@ngx-translate/core/public_api";
import {SetLoadingConfigGuard} from "@biletix/core/components/loading/set-loading-config.guard";
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import {HeaderCountdownTimerComponent} from './components/header-countdown-timer/header-countdown-timer.component';
import {PerformancePageStateService} from "@biletix/features/performance/services/performance-page-state.service";
import { QRCodeModule } from 'angularx-qrcode';
import { StatusCodeResponseService } from './services/status-code-response.service';
import { environment } from '@biletix/env';
import { CartDropdownItemComponent } from './layouts/main-layout/header/cart-dropdown-item/cart-dropdown-item.component';
import { SharedModule } from '@biletix/shared/shared.module';

registerLocaleData(en);
registerLocaleData(he,'iw');

//
const maskConfig: Partial<IConfig> = {
    validation: true,
};

const translateConfig: TranslateModuleConfig = {
    loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
    },
    isolate: false,
    extend: false
};


//
const toastrConfig: Partial<GlobalConfig> = {
    closeButton: true,
    enableHtml: true,
    // extendedTimeOut: 2000,
    // progressAnimation: "increasing",
    timeOut: 10000,
    newestOnTop: true,
    onActivateTick: true,
    maxOpened: 3,
    autoDismiss: true,
    preventDuplicates: true,
};

/**
 *
 * @param http
 */
export function httpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?v='+environment.JENKINS_BUILD_NUMBER);
}

/**
 *
 */
export function countdownConfigFactory(): CountdownGlobalConfig | any {
    return {format: `mm:ss`, leftTime: GlobalParameters.CART_REMAINING_TIME_DEFAULT, notify: 0};
}

@NgModule({

    declarations: [
        //Main layout
        MainLayoutComponent,
        HeaderComponent,
        FooterComponent,
        LoadingComponent,
        AccountDropdownComponent,
        CartDropdownComponent,
        CartDropdownItemComponent,
        EventSearchBoxComponent,
        EventCategoryMenuComponent,
        EventCategoryMobileMenuComponent,
        TopBandComponent,
        EventSearchNotFoundComponent,
        SideNavComponent,

        //Secondary layout
        SecondaryLayoutComponent,
        EmptyHeaderComponent,
        EmptyFooterComponent,

        //Auth layout
        AuthLayoutComponent,
        SiteLogoComponent,
        FooterToggleDirective,
        PageNotFoundComponent,

        HeaderCountdownTimerComponent,
    ],
    imports: [

        //vendor
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,
        RouterModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        FlexLayoutModule,
        MaterialModule,
        CountdownModule,
        SharedModule,
        QRCodeModule,
        //ngx-toastr
        ToastrModule.forRoot(toastrConfig),
        //ngx-mask
        NgxMaskModule.forRoot(maskConfig),
        //ngx-translate
        TranslateModule.forRoot(translateConfig)
    ],

    exports: [

        //Main layout
        MainLayoutComponent,
        //Secondary layout
        SecondaryLayoutComponent,
        //Auth layout
        AuthLayoutComponent,
        SiteLogoComponent,
        PageNotFoundComponent,
        HeaderCountdownTimerComponent

    ],

    providers: [

        Title,

        //local
        HTTP_INTERCEPTORS_PROVIDERS,

        LOCAL_STORAGE_PROVIDER,

        WINDOW_PROVIDER,
        
        StatusCodeResponseService,

        PerformancePageStateService,
        
        //location go back forward
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        },

        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        {
            provide: MatPaginatorIntl,
            deps: [TranslateService],
            useClass: CustomPaginatorIntl
        },


        //set locale id
        I18nService,
        {
            provide: DEFAULT_LOCALE_VALUE,
            useValue: 'iw'
        },
        {
            provide: DEFAULT_LOCALES,
            useValue: [
                {lang: 'iw', displayName: 'עִברִית', flag: 'he.svg'},
                {lang: 'en', displayName: 'English', flag: 'en.svg'}
            ]
        },

        //set region
        RegionService,
        {provide: DEFAULT_REGION_VALUE, useValue: {name: 'ALL', displayName: "main.region.turkey"}},
        {
            provide: USE_DEFAULT_REGIONS, useValue: [
                {name: 'ALL', displayName: "main.region.turkey"}
            ]
        },

        //mat date picker settings
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'iw'
        },
        DatePipe,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {
            provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS
        },

        //count down config
        {provide: CountdownGlobalConfig, useFactory: countdownConfigFactory},

        //
        LoadingScreenService,
        SetLoadingConfigGuard,
        {
            provide: LOADING_CONFIG_DEFAULT,
            useValue: {message: 'shared.labels.loadingDefaultMessage', icon: 'default.svg'}
        },

        //local services & common service
        EventService,
        AuthenticationService,
        LocalStorageService,
        NotificationService,
        ConsoleLogger,
        GlobalErrorHandler,
        LanguageGuard,
        RegionGuard,
        ShoppingCartGuard,
        AuthenticationGuard,
        AnonymousGuard,
        OverlayService,

        CustomerAccountService,
        ApplicationStateService,
        TicketSaleService,
        CreditCardTypeService,
        DeliveryService,
        CustomerService,
        ShoppingCartResolver,
        LoggedInCustomerResolver,
        NavigationService,
        RouterService,
        CartTimeoutService,
        CartResetTimeoutResolver
    ]


})

export class CoreModule {


    constructor(@Optional() @SkipSelf() coreModule: CoreModule,private title:Title) {
        if (coreModule) {
            throw new Error(`CoreModule has already been loaded. Import Core modules in the AppModule only.`);
        }
    }
}
