import { Component, OnInit } from '@angular/core';
import {RouterService} from "@biletix/core/services/router.service";

@Component({
  selector: 'btx-empty-footer',
  templateUrl: './empty-footer.component.html',
  styleUrls: ['./empty-footer.component.scss']
})
export class EmptyFooterComponent implements OnInit {

  constructor(public routerService:RouterService) { }

  ngOnInit() {
  }

}
