import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE} from "@biletix/core/common";
import {StorageService} from "@biletix/core/services/storage.service";

/**
 * for example
 *
 * // get current value
 this.localStorage.get(StorageKey.SHOPPING_CART);

 // set new value
 this.localStorage.set(StorageKey.SHOPPING_CART, {
  cartId: 54852,
  customer_old: {email: "otunctan@gmail.com", userName: "otunctan"}
});

 // watch value changes
 this.localStorage.watch(StorageKey.SHOPPING_CART)//
 .pipe(this.takeUntilDestroy())//
 .subscribe(cart => this.logger.log('shopping-cart cart changed', cart));


 */

@Injectable()
export class LocalStorageService extends StorageService {


  /**
   * Constructor with service injection
   * @param _storage
   */
  constructor(@Inject(LOCAL_STORAGE) private storage: Storage) {
    super(storage);
  }

}
