import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from "@biletix/core/core.module";
// import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { I18nService } from './core/services/i18n.service';
import { LOCATION_INITIALIZED } from '@angular/common';

export function appInitializerFactory(i18n: I18nService, injector: Injector) {
    return () => new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
  
        i18n.useLanguage(i18n.currentLocale).subscribe(() => {
          console.info(`Successfully initialized '${i18n.currentLang}' language.'`);
        }, err => {
          console.error(`Problem with '${i18n.currentLang}' language initialization.'`);
        }, () => {
          resolve(null);
        });
      });
    });
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        //core
        CoreModule,
        AppRoutingModule,
        // GoogleTagManagerModule.forRoot({
        //     id: 'GTM-5LZB7MP',
        //     // gtm_auth: YOUR_GTM_AUTH,
        //     // gtm_preview: YOUR_GTM_ENV
        // })
    ],
    providers: [
        // { provide: 'googleTagManagerCSPNonce',
        //  useValue: 'CSP-NONCE'
        // },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [I18nService, Injector],
            multi: true
        }
    ],
    bootstrap: [AppComponent]

})
export class AppModule {

    //TODO:Silinecek . Router config olarak verildi. Özelleştirilmek isteniyorsa kullanılabilir.

    // constructor(router: Router, viewportScroller: ViewportScroller) {
    //     router.events.pipe(
    //         filter((e: Event): e is Scroll => e instanceof Scroll)
    //     ).subscribe(e => {
    //             if (e.position) {
    //                 // backward navigation
    //                 viewportScroller.scrollToPosition([0, 0]);
    //             } else if (e.anchor) {
    //                 // anchor navigation
    //                 viewportScroller.scrollToAnchor(e.anchor);
    //             } else {
    //                 // forward navigation
    //                 viewportScroller.scrollToPosition([0, 0]);
    //             }
    //         }
    //     );
    //
    // }
}
