<div class="top-band" fxHide fxShow.gt-sm="true">
  <div class="container">

    <!--    -->
    <ul class="top-band-links">
      <!-- <li *ngFor="let linkItem of topLinks">
        <a [href]="linkItem.url"
           [classList]="linkItem.classList">

          <img *ngIf="linkItem.image"
               [src]="linkItem.image"
               alt="">
          {{linkItem.label|translate}}
        </a>
      </li> -->
      <!-- <li *ngIf="currentLocale?.lang=='en'">
        <a href="https://help.ticketmaster.co.il/hc/en-us">HELP</a>
      </li>
      <li *ngIf="currentLocale?.lang=='iw'">
        <a href="https://help.ticketmaster.co.il/hc/he">עזרה</a>
      </li> -->
    </ul>


    <!--          Language-->
    <div class="button-holder">
      <button class="language-menu" [attr.aria-label]="'main.labels.language' | translate"
              mat-button
              [matMenuTriggerFor]="languageMenu">
        {{'main.labels.language'|translate}}:
        <img [src]="'assets/images/flag/'+currentLocale.flag" [alt]="currentLocale.lang|uppercase" class="flag">
        {{currentLocale.lang === 'iw' ? 'HE' : currentLocale.lang |uppercase}}
        <img src="assets/images/icon/lang-arrow.svg" class="arrow" alt="Lang Arrow">
      </button>
      <mat-menu #languageMenu="matMenu" class="language-menu">
        <button mat-menu-item disabled>
            <span class="title"
                  translate="main.labels.language">
            </span>
        </button>
        <button mat-menu-item
                *ngFor="let locale of locales"
                [ngClass]="{'selected':currentLocale?.lang==locale.lang}"
                [disabled]="currentLocale?.lang==locale.lang"
                (click)="changeLanguage(locale)">
          <span class="inner-item">{{locale.displayName}}</span>
        </button>
      </mat-menu>
    </div>
    <div class="accessibility">
      <a [attr.aria-label]="'main.labels.accessibility' | translate" *ngIf="currentLocale?.lang=='en'" href="https://help.ticketmaster.co.il/hc/en-us/articles/20881962474897">{{'main.labels.accessibility'|translate}}</a>
      <a [attr.aria-label]="'main.labels.accessibility' | translate" *ngIf="currentLocale?.lang=='iw'" href="https://help.ticketmaster.co.il/hc/he/articles/20881962474897">{{'main.labels.accessibility'|translate}}</a>
    </div>

    <!--          REGION-->
<!--     <div class="button-holder">
      <button class="region-menu" mat-button [matMenuTriggerFor]="regionMenu">{{'main.labels.region'|translate}}
        : {{currentRegion.displayName|translate}} <img
          src="assets/images/icon/lang-arrow.svg"
          class="arrow"
          [alt]="currentRegion.displayName|translate"></button>
      <mat-menu #regionMenu="matMenu" class="region-menu">
        <button mat-menu-item disabled style="width: 200px;"><span class="title"
                                                                   translate="main.labels.selectYourRegion"></span>
        </button>
        <button mat-menu-item *ngFor="let region of regions"
                (click)="changeRegion(region)"
                [disabled]="region.name==currentRegion?.name"><span
          class="inner-item">{{region.displayName|translate}}</span>
        </button>
      </mat-menu>
    </div> -->


  </div>
</div>
