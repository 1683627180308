<div [dir]="'dir'| translate" class="dropdown-menu cart-dropdown lang-{{currentLocale.lang}}">

  <a [routerLink]="[cartPageUrl]"
     class="cart-link"
     fxLayout="row"
     rel="nofollow"
     fxLayoutGap="5px"
     fxLayoutAlign="center center">

    <img src="assets/images/icon/basket.svg"
         alt="cart">

    <span class="cart-title" [ngClass]="{'move-up': !cartStateValue && isExistShoppingCart() && !(shoppingCartState && shoppingCartState.isNotEmpty())}" fxHide fxShow.gt-sm="true"> {{'shared.labels.myBasket'|translate}}</span>

    <span class="cart-count">{{ticketCount$|async}}</span>

  </a>

  <div class="cart-popdrop-holder" #cartPopdropHolder>
    <div class="cart-popdrop">
      <h4>
        <ng-container *ngIf="ticketCount$|async; else empty">{{'shared.labels.myBasket'|translate}}({{ticketCount$|async}})</ng-container>
        <ng-template #empty>{{'shared.labels.myCartIsEmpty'|translate}}</ng-template>
        </h4>
      <btx-cart-dropdown-item *ngFor="let eventItemMap of eventShoppingItemsMap |keyvalue;let ind=index" [items]="eventItemMap.value">
                </btx-cart-dropdown-item>
      <div class="button-holder">
        <a rel="nofollow" [routerLink]="[cartPageUrl]">{{'shared.labels.goToCart'|translate}}</a>
        <!-- <a href="">Siparişi Tamamla</a> -->
      </div>
    </div>
  </div>
</div>

<!-- <span>1:35</span> -->
 