import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from "rxjs";
import {LoggedInCustomer} from "@biletix/core/models/authentication/logged-in-customer.model";
import {HttpClient} from "@angular/common/http";
import {ApiService} from "@biletix/core/services/api.service";
import {ApiResponse} from "@biletix/core/models/api-response";
import {map} from "rxjs/operators";
import {PasswordChangeRequest} from "@biletix/core/models/customer/password-change-request.model";
import {ActivationChangeRequest} from "@biletix/core/models/customer/activation-change-request.model";
import {ValidateChangeRequestIdResponse} from "@biletix/core/models/customer/validate-change-request-id-response.model";
import {CreateCustomer} from "@biletix/core/models/customer/create-customer.model";
import {Customer} from "@biletix/core/models/customer/customer.model";
import {PasswordResetRequest} from "@biletix/core/models/customer/reset-password-request.model";
import {PhoneChangeRequest} from "@biletix/core/models/customer/phone-change-request.model";
import {EmailChangeRequest} from "@biletix/core/models/customer/email-change-request.model";
import {ForgotPasswordRequest} from "@biletix/core/models/customer/forgot-password-request.model";
import {ActivationRequest} from "@biletix/core/models/customer/activation-request.model";
import {CustomerAddressInput} from "@biletix/core/models/customer/customer-address-input.model";
import {CustomerAddress} from "@biletix/core/models/customer/customer-address.model";
import {EditCustomer} from "@biletix/core/models/customer/edit-customer.model";
import {LocalStorageService} from "@biletix/core/services/local-storage.service";
import {StorageKey} from "@biletix/core/services/storage.service";
import {NotificationPreferences} from "@biletix/core/models/customer/notification-preferences.model";
import {PagedResult} from "@biletix/core/models/paged-result.model";
import {TicketFilterData} from "@biletix/core/models/account/ticket-filter-data.model";
import {PageRequest} from "@biletix/core/models/page-request.model";
import {MyTicketItem} from "@biletix/core/models/account/my-ticket-item.model";
import {TicketDetails} from "@biletix/core/models/ticket/ticket-details.model";
import {TicketDetailsFilterData} from "@biletix/core/models/ticket/ticket-details-filter-data.model";
import { MyCardDTO } from '../models/account/my-card-item.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerAccountService {

  private basePath: string = 'api/v1/customerAccount';


  //Son güncel müşteri bilgisi
  private _customerChange = new ReplaySubject<Customer>(1);
  customerChange: Observable<Customer>;

  constructor(private httpClient: HttpClient,
              private apiService: ApiService,
              private localStorage: LocalStorageService) {

    this.customerChange = this._customerChange.asObservable();
  }

  setCustomer(customer: Customer) {
    this._customerChange.next(customer);
  }

  /**
   *
   * @param saveCustomerInput
   */
  create(createCustomer: CreateCustomer): Observable<ApiResponse<CreateCustomer>> {
    return this.httpClient.post<ApiResponse<CreateCustomer>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/create`,//
      {...createCustomer}//
    );
  }

  /**
   *TODO: Update customer_old servisi düzenlenmeli. CUstomer update için id ve güncellenecek alanlar gönderilmeli.
   * @param input
   */
  updateCustomer(input: EditCustomer | any): Observable<ApiResponse<Customer>> {
    return this.httpClient.post<ApiResponse<Customer>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/update`,//
      {...input});//
  }

  /**
   *
   * @param email
   */
  forgotPassword(forgotPasswordRequest: ForgotPasswordRequest): Observable<ApiResponse<boolean>> {
    return this.httpClient.post<ApiResponse<boolean>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/forgotPassword`,//
      {...forgotPasswordRequest}//
    );

  }

  /**
   *
   * @param changeRequestId
   */
  validatePasswordChange(changeRequestId: string): Observable<ApiResponse<ValidateChangeRequestIdResponse>> {
    return this.httpClient.post<ApiResponse<ValidateChangeRequestIdResponse>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/validatePasswordChange`,//
      changeRequestId//
    );
  }

  /**
   *
   * @param passwordChangeRequest
   */
  resetPassword(resetPasswordRequest: PasswordResetRequest): Observable<ApiResponse<LoggedInCustomer>> {
    return this.httpClient.put<ApiResponse<LoggedInCustomer>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/resetPassword`,//
      {...resetPasswordRequest}//
    );
  }

  /**
   *
   * @param passwordChangeRequest
   */
  passwordChange(passwordChangeRequest: PasswordChangeRequest): Observable<ApiResponse<Customer>> {
    return this.httpClient.put<ApiResponse<Customer>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/passwordChange`,//
      {...passwordChangeRequest}//
    );
  }

  /**
   *
   * @param emailChangeRequest
   */
  emailChange(emailChangeRequest: EmailChangeRequest): Observable<ApiResponse<Customer>> {
    return this.httpClient.put<ApiResponse<Customer>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/emailChange`,//
      {...emailChangeRequest}//
    );
  }

  /**
   *
   * @param phoneChangeRequest
   */
  phoneChange(phoneChangeRequest: PhoneChangeRequest): Observable<ApiResponse<Customer>> {
    return this.httpClient.put<ApiResponse<Customer>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/phoneChange`,//
      {...phoneChangeRequest}//
    );
  }


  /**
   *
   * @param email
   */
  activation(activationRequest: ActivationRequest): Observable<ApiResponse<boolean>> {
    return this.httpClient.post<ApiResponse<boolean>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/activation`,//
      {...activationRequest}//
    );

  }

  /**
   *Hesab aktifleştirme
   *
   * @param activationChangeRequest
   */
  activate(activationChangeRequest: ActivationChangeRequest): Observable<ApiResponse<LoggedInCustomer>> {
    return this.httpClient.put<ApiResponse<LoggedInCustomer>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/activate`,//
      {...activationChangeRequest}//
    );
  }


  deactivateAccount(): Observable<boolean> {
    return this.httpClient.get<ApiResponse<boolean>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/deactivateAccount`)//
      .pipe(map(response => response.data));
  }

  /**
   *
   * @param input
   */
  saveOrUpdateCustomerAddress(input: CustomerAddressInput): Observable<CustomerAddress> {
    return this.httpClient.post<ApiResponse<CustomerAddress>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/saveAddress`,
      {...input}
    ).pipe(map(response => response.data));

  }

  /**
   *
   * @param addressId
   */
  removeCustomerAddressById(addressId: number,isDeliveryAddress:boolean): Observable<boolean> {
    return this.httpClient.get<ApiResponse<boolean>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/removeAddress/${addressId}/${isDeliveryAddress}`,
    ).pipe(map(response => response.data));
  }

  /**
   *İd paremteresi göndermiyoruz. Hazelcastte olan kullanıcının id si ile işlem yapılacak.
   * @param customerId
   */
  getAddressesByCustomerId(customerId: number): Observable<CustomerAddress[]> {
    return this.httpClient.get<ApiResponse<CustomerAddress[]>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/getCustomerAddressesById`,
    ).pipe(map(response => response.data));
  }

  checkEmailAddress(email: string): Observable<ApiResponse<boolean>> {
    return this.httpClient.get<ApiResponse<boolean>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/checkEmail/${email}`,
    );
  }


  setLocalCustomer(customer: Customer) {
    this.localStorage.set(StorageKey.CURRENT_CUSTOMER, customer);
  }

  getLocalCustomer() {
    return this.localStorage.get(StorageKey.CURRENT_CUSTOMER);
  }

  getNotificationPermissions(customerId: number): Observable<NotificationPreferences> {
    return this.httpClient.get<ApiResponse<NotificationPreferences>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/notificationPermissions/${customerId}`,
    ).pipe(map(response => response.data));
  }

  updateNotificationPreferences(notificationPreferences: NotificationPreferences): Observable<boolean> {
    return this.httpClient.post<ApiResponse<boolean>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/updateNotificationPreferences`,
      {...notificationPreferences}
    ).pipe(map(response => response.data));
  }

  /**
   *
   * @param filterType
   * @param pageNumber
   * @param size
   */
  getMyTickets(pageRequest: PageRequest<TicketFilterData>): Observable<PagedResult<MyTicketItem>> {
    return this.httpClient.post<ApiResponse<PagedResult<MyTicketItem>>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/getMyTickets`,
      {...pageRequest}
    ).pipe(map(response => response.data));
  }

  getMyCards(): Observable<MyCardDTO[]> {
    return this.httpClient
    .get<ApiResponse<MyCardDTO[]>>(`${this.apiService.getCrmApiHost()}/${this.basePath}/getMyCards`)
    .pipe(map(response => response.data))
  }


  /**
   *
   * @param shoppingCartId
   */
  getTicketDetailsByFilterData(filter:TicketDetailsFilterData):Observable<TicketDetails>{
    return this.httpClient.post<ApiResponse<TicketDetails>>(
      `${this.apiService.getCrmApiHost()}/${this.basePath}/getTicketDetails`,
      {
        ...filter
      }
    ).pipe(map(response => response.data));
  }

}
