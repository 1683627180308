<!---->


<!-- -->
<div [dir]="'dir'| translate" class="secondary-layout-container">
    <btx-empty-header></btx-empty-header>
    <!--    -->
    <div class="container secondary-layout-content">
        <router-outlet></router-outlet>
        <!--    -->
    </div>

    <div class="push"></div>
</div>
<btx-empty-footer id="footer"></btx-empty-footer>