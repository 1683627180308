import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {finalize} from "rxjs/operators";
import {Injectable, Injector} from "@angular/core";
import {environment} from "@biletix/env";
import {I18nService} from "@biletix/core/services/i18n.service";
import {LoadingScreenService} from "@biletix/core/components/loading/loading-screen.service";


@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(private readonly injector: Injector) {
  }


  private get loadingService() {
    return this.injector.get(LoadingScreenService);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.log("CustomHttpInterceptor called");
    const i18n = this.injector.get<I18nService>(I18nService);
    let modifiedRequest = req.clone({
        setHeaders: {
          CHANNEL: environment.CHANNEL,
          'Accept-Language': i18n.currentLang,
          CPU: environment.CPU + "",
          CLIENT_APP_ENVIRONMENT:environment.profile
        },
        withCredentials: environment.SEND_COOKIES
      }
    );

    //Dil dosyaları yüklenirken loading component gösterme
    let url = modifiedRequest.url;
    //console.log("Requesting" + url);
    let isAssetsFile = url && url.includes('assets/i18n/');
    if (!isAssetsFile)
      this.loadingService.open();


    return next.handle(modifiedRequest)
      .pipe(
        //İşlem başarısız ise bir kere tekrar dene
        // retry(1),
        finalize(() => {
          //console.log("Response received" + url);
          if (!isAssetsFile)
            this.loadingService.close();
        })
      );
  }
}
