<div class="account-menu timer-sibling  lang-{{currentLocale.lang}}"[ngClass]="{'timer-sibling': showTimerInHeader()}"
     [matMenuTriggerFor]="accountMenu"
     [attr.aria-label]="'account.title' | translate"
     fxLayout="row">
    <!--  -->
    <div [dir]="'dir'| translate" class="account-menu-wrapper"
         fxLayout="row"
         fxLayoutGap="8px">

        <div class="account-icon">
            <img src="assets/images/icon/profile.svg"
                 alt="Profile">
        </div>

        <div class="account-info-text-wrapper" fxHide fxShow.gt-sm="true">
            <!--      -->
            <div class="title">{{'account.title'|translate}}
                <mat-icon class="chevron-icon"
                          color="primary"
                          fxHide.sm>
                    expand_more
                </mat-icon>
            </div>
            <!--     -->
            <div class="user-name-text">
                {{this.isAuthenticated ? loggedInUserName : ('auth.labels.signInOrSignUp'|translate)}}
            </div>
        </div>

    </div>
</div>


<!--  Account Menu    -->
<mat-menu #accountMenu="matMenu" class="account-menu-item lang-{{currentLocale.lang}}" 
          xPosition="before">


    <!--  TODO: Seam devre dışı kalınca burası düzenlenecek.-->
    <ng-container *ngFor="let menuItem of menuItems">
        <a [dir]="'dir'| translate" mat-menu-item
           *ngIf="menuItem.external else internalLink"
           rel="nofollow" 
           [href]="menuItem.url">
            <mat-icon class="material-icons-outlined"
                      *ngIf="menuItem.icon">{{menuItem.icon}}</mat-icon>
            <span [translate]="menuItem.label"></span>
        </a>

        <!--  -->
        <ng-template #internalLink>
            <a [dir]="'dir'| translate"
            rel="nofollow"  mat-menu-item
               [routerLink]="[menuItem.url]">
                <mat-icon class="material-icons-outlined"
                          *ngIf="menuItem.icon">{{menuItem.icon}}</mat-icon>
                <span [translate]="menuItem.label"></span>
            </a>
        </ng-template>
    </ng-container>


    <!--    Logout Button          -->
    <ng-container *ngIf="isAuthenticated">

        <!--      Logout        -->
        <button [dir]="'dir'| translate" mat-menu-item
                class="btn-logout"
                color="warn"
                (click)="doLogout()">
            <mat-icon>logout</mat-icon>
            {{'shared.labels.logout'|translate}}
        </button>

    </ng-container>

</mat-menu>
