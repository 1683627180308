import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'btx-sub-title',
  templateUrl: './sub-title.component.html',
  styleUrls: ['./sub-title.component.scss']
})
export class SubTitleComponent {

  @Input("title") title?: string;

  @Input("routerLink") routerLink: any[] | string = [];

}
