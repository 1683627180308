import {Component, OnInit} from '@angular/core';
import {Location} from "@angular/common";
import {I18nService} from "@biletix/core/services/i18n.service";
import {LocaleSelectorComponent} from "@biletix/core/common/locale-selector.component";
import {TranslateService} from "@ngx-translate/core";
import {RegionService} from "@biletix/core/services/region.service";
import {ApplicationStateService} from "@biletix/core/services/application-state.service";
import {ConsoleLogger} from "@biletix/core/logging/console.logger";
import {AuthenticationService} from "@biletix/core/services/authentication.service";
import { Locale } from '@biletix/core/common';

@Component({
    selector: 'btx-main-layout',
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent extends LocaleSelectorComponent implements OnInit {

    currentLocale: Locale = this.i18n.currentLocale;

    constructor(private location: Location,
                public i18n: I18nService,
                public region: RegionService,
                private applicationStateService: ApplicationStateService,
                private authenticationService: AuthenticationService,
                private translate: TranslateService,
                private logger: ConsoleLogger) {
        super(translate, i18n);
    }

    ngOnInit(): void {
        this.initialize();
    }

    initialize() {
      this.clearLocalStorage();
    }

    clearLocalStorage(){
      //clear cache
      this.authenticationService.removeLoggedInAnonymousCustomer();
    }


    onBack() {
        this.location.back();
    }


}
