import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router} from "@angular/router";
import {Injectable} from "@angular/core";
import {AuthenticationService} from "@biletix/core/services/authentication.service";

/**
 *Anonim kullanıcı erişimini engellemek için oluşturuldu.
 */
@Injectable()
export class AnonymousGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router,
              private authenticationService: AuthenticationService) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return this.checkAnonymousLogin();
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    return this.canActivate(route);
  }

  checkAnonymousLogin() {

    // If Anonymous, can  not access the page
    if (this.authenticationService.isLoggedInAnonymousCustomer()) {

      //Kayıtlı kullanıcı değilse kullanıcı gerekli olduğu için anonymous bilgilerini kaldır.
      // this.authenticationService.removeLoggedInAnonymousCustomer();

      //try login
      this.authenticationService.navigateToLogin();

      return false;
    }


    //if else anonymous
    return true;
  }

}
