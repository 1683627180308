import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {TicketSaleService} from "@biletix/core/services/ticket-sale.service";
import {CartTimeoutService} from "@biletix/core/services/cart-timeout.service";
import {map} from "rxjs/operators";
import {GlobalParameters} from "@biletix/core/common/constants/global-parameters";

@Injectable()
export class CartResetTimeoutResolver implements Resolve<boolean> {

    constructor(private ticketSaleService: TicketSaleService,
                private cartTimeoutService: CartTimeoutService
    ) {
    }


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

        //console.log("CartResetTimeoutResolver called")
        //Sepette ürün yok ise timeout süresi yenilenmeyecek.
        if (!this.ticketSaleService.isExistShoppingCart() || this.cartTimeoutService.isTimeout()) {
            //console.log("CartResetTimeoutResolver isExistShoppingCart : "+this.ticketSaleService.isExistShoppingCart());
            //console.log("CartResetTimeoutResolver isCartTimeout : "+this.cartTimeoutService.isTimeout());
            this.cartTimeoutService.clearTimeout();
            this.ticketSaleService.clearLocalShoppingCart();
            //console.log("CartResetTimeoutResolver clear timeout data");
            return of(false);
        }


        //Sayfalar arasında timeout süresi resetleniyor.
        return this.cartTimeoutService
            .resetTimeout()
            .pipe(
                map((reset: boolean) => {
                        //console.log("CartResetTimeoutResolver reset Timeout called");
                        if (reset) {
                            this.cartTimeoutService.setRemainingTimeInSecond(GlobalParameters.CART_REMAINING_TIME_DEFAULT);
                        }
                        return reset;
                    }
                )
            )
    }


}
