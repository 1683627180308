import { Component, OnInit } from '@angular/core';
import {Locale, Region} from "@biletix/core/common";
import {RegionService} from "@biletix/core/services/region.service";
import {I18nService} from "@biletix/core/services/i18n.service";

@Component({
  selector: 'btx-empty-header',
  templateUrl: './empty-header.component.html',
  styleUrls: ['./empty-header.component.scss']
})
export class EmptyHeaderComponent implements OnInit {

  locales: Locale[];
  currentLocale: Locale;

  regions: Region[];
  currentRegion: Region;

  constructor(private  regionService: RegionService,
              private i18nService: I18nService,
  ) {
    this.locales = this.i18nService.getAvailableLocales();
    this.currentLocale = this.i18nService.currentLocale;
    this.regions = this.regionService.getRegions();
    this.currentRegion = this.regionService.currentRegion;
  }

  ngOnInit(): void {
  }

}
