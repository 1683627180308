import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Data,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {I18nService} from "@biletix/core/services/i18n.service";
import {RegionService} from "@biletix/core/services/region.service";
import {AuthenticationService} from "@biletix/core/services/authentication.service";
import {RouterService} from "@biletix/core/services/router.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate, CanActivateChild {

  constructor(private authenticationService: AuthenticationService,
              private router: Router,
              private regionService: RegionService,
              private routerService:RouterService,
              private i18n: I18nService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLogin(state.url, route.data);
  }


  checkLogin(url: string, data: Data): boolean {
    this.authenticationService.redirectUrl=url;
    if (this.authenticationService.isAuthenticated()) {
      return true;
    }

    const stateObj: any | { returnUrl: string } = {
      returnUrl: url
    };

    if (data && data.showLoginAsAnonymousBtn) {
      stateObj['showLoginAsAnonymousBtn'] = true;
    }

    this.router.navigate(
      [this.routerService.loginUrl],
      {
        state: stateObj,
        queryParams: stateObj
      }
    );
    return false;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }


}
