import {Component, Directive, OnDestroy} from "@angular/core";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'btx-abstraction',
  template: ''
})
export abstract class AbstractionComponent implements OnDestroy {

  private _destroy$: Subject<void> | undefined;

   public  takeUntilDestroy = <T>() => {
    if (!this._destroy$) this._destroy$ = new Subject<void>(); // LAZY Subject
    return takeUntil<T>(this._destroy$);
  };

  ngOnDestroy(): void {
    if (this._destroy$) {
      this._destroy$.next();
      this._destroy$.complete();
    }
  }

}
