import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApiResponse} from '../models/api-response';
import { ClientErrorLogDto } from '../models/client-error-log.dto';
import { ApiService } from './api.service';
import {LocalStorageService} from './local-storage.service';

@Injectable({providedIn: 'root'})
export class LogService {

  constructor(private apiService: ApiService,
    private http: HttpClient,
    private localStorageService: LocalStorageService) {
  }


  /**
   *
   * @param request
   */
  public printClientErrorLog(errorType:string,errorMessage:string,stackTrace:string): Observable<boolean> {
    const request:ClientErrorLogDto = {
                    errorType : errorType,
                    errorMessage:errorMessage,
                    stackTrace  :stackTrace
                    };
    return this.http
      .post<ApiResponse<boolean>>(this.apiService.getCrmApiHost() + '/api/v1/log/printClientErrorLog', request)
      .pipe(map(response => response.data));
  }
}
