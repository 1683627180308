import {ErrorHandler, Inject, Injectable, Injector, PLATFORM_ID} from "@angular/core";
import {ConsoleLogger} from "@biletix/core/logging/console.logger";
import {HttpErrorResponse} from "@angular/common/http";
import {NotificationService} from "@biletix/core/services/notification.service";
import { isPlatformServer } from "@angular/common";
import { LogService } from "./log.service";


@Injectable({
  providedIn: "root"
})
export class GlobalErrorHandler extends ErrorHandler {

  constructor(private injector: Injector,@Inject(PLATFORM_ID) private platformId: string) {
    super();
  }

  private get consoleLogger(): ConsoleLogger {
    return this.injector.get(ConsoleLogger);
  }

  private get notificationService(): NotificationService {
    return this.injector.get(NotificationService);
  }

  private get logService(): LogService {
    return this.injector.get(LogService);
  }

  handleError(error: any): void {
    //development ortamında hatayı logla
    // @ToDo canlıya geçmeyecek bir yapı yapılacak loglar için
    //this.consoleLogger.error(error);

    console.log("Error happened",error);
    console.error(error);

    if (!navigator.onLine) {
      this.notificationService.error('No Internet Connection');
      return;
    }

    //this.checkLazyLoadingError(error);

    if(this.checkLazyLoadingError(error)){
      return;
    }

    if (this.isServerError(error)) {
      this.handleServerError(error);
    } else {
      this.handleClientError(error);
    }

  }

  checkLazyLoadingError(error: any) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      //window.location.reload();
      return true;
    }
    return false;
  }


  isServerError(error: Error | HttpErrorResponse | any | undefined): boolean {
    return error instanceof HttpErrorResponse;
  }

  /**
   *Implement edilecek
   */
  handleServerError(responseError: HttpErrorResponse) {
    let errorMessage: string | any;

    // server-side error
    if (responseError)
      errorMessage = `Error Code: ${responseError.status}\nMessage: ${responseError.message}`;


    if (!errorMessage)
      errorMessage = "Application can not execute because API hasn\'t been started";
    console.log("Unhandled server error happened ",errorMessage);
    if(!isPlatformServer(this.platformId)){
      this.notificationService.error(errorMessage,"Server Error");
      this.logService.printClientErrorLog("Server",errorMessage, JSON.stringify(responseError)).subscribe();
    }
}

  /**
   *Implement edilecek
   */
  handleClientError(error: Error | HttpErrorResponse | any | undefined) {
    const errMessage = error.message ? error.message : error.toString();
    const errStack = error.stack ? error.stack : "Not available";
    //console.log("Client error"+errMessage);
    //console.log(error);
    console.log("Unhandled client error happened :",error);

    if(!isPlatformServer(this.platformId)){
      this.notificationService.error(errMessage,"Client Error");
      this.logService.printClientErrorLog("Client",errMessage,errStack).subscribe();
    }

  }


}
