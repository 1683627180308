<!--<a [routerLink]="[routerService.homePageUrl]">-->
<!--  <img [src]="'assets/images/logo.svg'"-->
<!--       alt="Biletix"-->
<!--       class="logo">-->
<!--</a>-->

<!--TODO: Seam projesi aradan çıkarıldıktan sonra yukarıdaki link aktif edilecek.-->
<!-- <a [href]="routerService.getSeamHomePageUrl()">
  <img [src]="'assets/images/logo.svg'"
       alt="Biletix"
       class="logo">
</a> -->
<!--<a [routerLink]="[routerService.homePageUrl]"> -->
<a [href]="routerService.homePageUrl">  
  <img [src]="'assets/images/iswlogo.svg'"
       alt="TM Israel"
       class="logo">
</a>
