import {AbstractionComponent} from "@biletix/core/common/abstraction-component";
import {TranslateService} from "@ngx-translate/core";
import {I18nService} from "@biletix/core/services/i18n.service";
import {Locale} from "@biletix/core/common";

/**
 *
 */
export abstract class LocaleSelectorComponent extends AbstractionComponent {

  protected constructor(translate: TranslateService,
                        i18n: I18nService) {
    super();
    this.onChangeLanguage(translate, i18n)
  }

  private onChangeLanguage(translate: TranslateService, i18n: I18nService) {
    i18n.changeLangEvent.pipe(this.takeUntilDestroy())
      .subscribe((lang:string) => {
        // console.log("On change language called");
        translate.currentLang = '';
        translate.use(lang)
      });
  }

}
