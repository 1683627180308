<h2 fxLayoutAlign="center center"
    mat-dialog-title
    translate="shared.labels.eventRules"></h2>

<mat-dialog-content>
    <div [innerHTML]="eventRules"></div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close
            cdkFocusInitial
            translate="shared.labels.close"></button>
</mat-dialog-actions>

