import {RegionService} from "@biletix/core/services/region.service";
import {I18nService} from "@biletix/core/services/i18n.service";
import {Inject, Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {ShoppingCart} from "@biletix/core/models/shopping-cart/shopping-cart.model";
import {environment} from "@biletix/env";
import { WINDOW } from "../common";

@Injectable()
export class RouterService {


    constructor(private  region: RegionService,
                private router: Router,
                private i18n: I18nService,
                @Inject(WINDOW) private window: Window) {
    }


    public get regionLanguagePath() {
        return `${this.region.regionName}/${this.i18n.currentLang}`;
    }

    public get homePageUrl(): string {
        return `/homepage/${this.regionLanguagePath}`;

    }

    getHostName(): string {
        return this.window.location.hostname;
    }

    getProtocol(): string {
        return this.window.location.protocol;
    }

    getDomain(): string {
        return this.getProtocol() + "//" + this.getHostName();
    }

    navigateToHomePage() {
        this.router.navigate([this.homePageUrl]);
    }

    /**
     * TODO:Seam devre dışı kaldıktan sonra homepage yukarıdaki link ile yönlendirme işlemi yapılacak.
     */
    getSeamHomePageUrl() {
		if(environment.production){
			return `${this.getDomain()}/homepage/${this.regionLanguagePath}`;
		}
        return `${environment.biletixWebHost}/homepage/${this.regionLanguagePath}`;
    }

    getPaymentPageUrl(paymentTransactionId:string){

        if(environment.production){
            return this.getDomain()+"/paymentgateway/payment?transId="+paymentTransactionId;
        }

        return environment.paymentPageUrl+paymentTransactionId;
    }

    getSeamPageUrl(urlName: string) {
        return `${environment.biletixWebHost}/${urlName}/${this.regionLanguagePath}`;
    }

    getIswPageUrl(urlName: string) {
        return `${environment.iswWebHost}/${urlName}/${this.regionLanguagePath}`;
    }

    getSeamPageUrlWoRegion(urlName: string) {
      return `${environment.biletixWebHost}/${urlName}/${this.i18n.currentLang}`;
    }

    navigateToSeamHomePage() {
        window.location.href = this.getSeamHomePageUrl();
    }

    navigateToPaymentPage(paymentTransactionId:string) {
        window.location.href = this.getPaymentPageUrl(paymentTransactionId);
    }

    public get authPageUrl() {
        return `/auth/${this.regionLanguagePath}`;
    }

    public get loginUrl() {
        return `${this.authPageUrl}/login`;
    }

    public get registerUrl() {
        return `${this.authPageUrl}/register`;
    }

    public get anonymousLoginUrl() {
        return `${this.authPageUrl}/login`;
    }

    public get accountPageUrl() {
        // return `/myaccount/`;
        return `${environment.accountPageUrlEnv}${this.i18n.currentLang}`;
    }


    // public get accountPageUrl() {
    //     return `account`;
    // }


    /**
     * TODO: Account module fix edildiğinde commentli kısım açılacak.
     */
    public get accountMyPersonalInformationUrl() {
        return `/myaccount/${this.i18n.currentLang}`;
    }


    /**
     * TODO: Account module fix edildiğinde commentli kısım açılacak.
     */
    public get accountMyAddressesUrl() {
        return `${this.accountPageUrl}/my-addresses`;
    }

    /**
     * TODO: Account module fix edildiğinde commentli kısım açılacak.
     */
    public get accountMyTicketsUrl() {
        return `${this.accountPageUrl}/my-tickets`;
    }

    public get accountFavoritesUrl() {
        return `${this.accountPageUrl}/my-favorites`;
    }

    public get cartPageUrl(): string {
        return `/cart/${this.regionLanguagePath}`;
    }

    getPerformancePageUrl(eventCode: string, performanceCode: string) {
        return `/performance/${eventCode}/${performanceCode}/${this.regionLanguagePath}`;
    }

    /**
     *
     * @param cart
     */
    navigateToBasketPage(cart?: ShoppingCart) {
        const state: any = {};
        if (cart) {
            state['shoppingCart'] = cart;
        }
        this.router.navigate(
            [this.cartPageUrl],
            {
                state: state
            }
        )
    }


    get eventBaseUrl() {
        return '/event';
    }

    createEventPageUrl(eventCode: string) {
        return `/${this.eventBaseUrl}/${eventCode}/${this.regionLanguagePath}`;
    }


    get venuePageBaseUrl() {
        return `${environment.venuePageUrl}`;
    }

    createVenuePageUrl(venueId: string) {
        return `${this.venuePageBaseUrl}/${venueId}/${this.regionLanguagePath}`;
    }

    get salesBasePageUrl() {
        return '/newsales';
    }

    getDeliveryPageUrl() {
        return `${this.salesBasePageUrl}/delivery`;
    }

    getConfirmationErrorPageUrl(){
        return `${this.salesBasePageUrl}/confirmation-error`;
    }

    navigateToSalesDeliveryPage() {
        this.router.navigate([this.getDeliveryPageUrl()]);
    }


}
