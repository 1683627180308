import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiResponse} from "@biletix/core/models/api-response";
import {HttpClient} from "@angular/common/http";
import {ApiService} from "@biletix/core/services/api.service";
import {CreditCardType} from "@biletix/core/models/sale/credit-card-type.model";

@Injectable()
export class CreditCardTypeService {

  private eventBasePath: string = "/api/v1/payment";

  constructor(private http: HttpClient,
              private apiService: ApiService) {

  }

  findByBinNumber(binNumber: string): Observable<ApiResponse<CreditCardType>> {
    return this.http//
      .get<ApiResponse<CreditCardType>>(`${this.apiService.getCrmApiHost()}${this.eventBasePath}/getCreditCardType`, {
        params: {
          cardNumber: binNumber
        }
      });
  }
}
