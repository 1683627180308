import {Injectable, OnDestroy} from "@angular/core";
import {MediaChange, MediaObserver} from "@angular/flex-layout";
import {Observable, Subscription} from "rxjs";

@Injectable()
export class ApplicationStateService implements OnDestroy {

  private isMobileResolution: boolean;

  private readonly watcher: Subscription;

  private _activeMediaQuery = '';


  constructor(private mediaObserver: MediaObserver) {
    this.isMobileResolution = false;
    this.watcher = mediaObserver
      .asObservable()
      .subscribe((change) => {
        this.isMobileResolution = false;
        change.forEach((item) => {
          this._activeMediaQuery = item
            ? `'${item.mqAlias}' = (${item.mediaQuery})`
            : '';
          if (item.mqAlias === 'xs' || item.mqAlias === 'sm') {
            this.isMobileResolution = !this.isMobileResolution;
          }
        });
      });
  }

  public getIsMobileResolution(): boolean {
    return this.isMobileResolution;
  }

  get activeMediaQuery(): string {
    return this._activeMediaQuery;
  }

  get mediaChange(): Observable<MediaChange[]> {
    return this.mediaObserver.asObservable();
  }


  ngOnDestroy() {
    if (this.watcher)
      this.watcher.unsubscribe();

  }
}
