
<div class="lang-{{currentLocale.lang}} loading-container" [dir]="'dir'| translate">
    <div class="loading-content">
        <div class="icon-holder">
            <img [src]="'assets/images/loading/'+config.icon" alt="Loading">
        </div>
        <h5 [translate]="config.message"></h5>
    </div>
</div>

