import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";
import {LocalStorageService} from "@biletix/core/services/local-storage.service";
import {StorageKey} from "@biletix/core/services/storage.service";
import {Router} from "@angular/router";
import {DEFAULT_REGION_VALUE, Region, USE_DEFAULT_REGIONS} from "@biletix/core/common";

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  private _regions: Array<Region> = [];

  private _currentRegion: Region | any;

  private _regionChange: Subject<Region> = new Subject();
  regionChange = this._regionChange.asObservable();


  constructor(private http: HttpClient,
              private localStorage: LocalStorageService,
              private router: Router,
              @Inject(DEFAULT_REGION_VALUE) private region: Region,
              @Inject(USE_DEFAULT_REGIONS) private useRegions: Region[]) {

    this.addRegions(useRegions);
    this._currentRegion = this.getRegion();
    this.localStorage.set(StorageKey.REGION, this._currentRegion);
  }


  public getRegion() {
    let localRegion = this.localStorage.get(StorageKey.REGION);
    if (this.isAvailableRegion(localRegion)) {
      return localRegion;
    }
    return this.defaultRegion;
  }

  getRegionByName(name: string): Region | any {
    return this.regions?.find(x => x.name === name);
  }

  isAvailableRegion(region: Region) {
    return this.regions.some(x => x.name === region?.name);
  }

  get regions(): Array<Region> {
    return this._regions;
  }

  set regions(value: Array<Region>) {
    this.addRegions(value);
  }

  get currentRegion(): Region {
    return this._currentRegion;
  }

  get regionName(): string {
    return this._currentRegion?.name;
  }

  set currentRegion(value: Region) {
    this._currentRegion = value;
  }

  public getRegions(): Region[] {
    return this._regions;
  }

  public setRegions(regions: Region[]) {
    this.addRegions(regions);
  }

  get defaultRegion(): Region {
    return this.region;
  }

  /**
   * Add available langs
   */
  public addRegions(regions: Array<Region>): void {
    regions?.forEach((region: Region) => {
        if (this.regions.every(item => item.code == region.code)) {
          this.regions.push(region);
        }
      }
    );
  }

  public use(region: Region) {
    if (region == null) {
      region = this.region;
    }
    this.currentRegion = region;
    this._regionChange.next(region);
    this.localStorage.set(StorageKey.REGION, region);
  }

  public changeRegion(region: Region) {
    const url = this.router.routerState.snapshot.url.replace(`/${this.regionName}`, `/${region?.name}`);
    this.use(region);
    // this.router.navigate(['/'])//
    //   .then(() => this.router.navigateByUrl(url));
    this.router.navigateByUrl(url).then(()=>{
      // console.log("Region changed");
    });
  }

}
