import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RouteParameter} from "@biletix/core/common/constants/route-parameter.const";
import {LanguageGuard} from "@biletix/core/guards/language.guard";
import {AuthenticationGuard} from "@biletix/core/guards/authentication.guard";
import {RegionGuard} from "@biletix/core/guards/region.guard";
import {AnonymousGuard} from "@biletix/core/guards/anonymous.guard";
import {SecondaryLayoutComponent} from "./core/layouts/secondary-layout/secondary-layout.component";
import {MainLayoutComponent} from "./core/layouts/main-layout/main-layout.component";
import {ShoppingCartGuard} from "@biletix/core/guards/shopping-cart.guard";
import {SetLoadingConfigGuard} from "@biletix/core/components/loading/set-loading-config.guard";
import {PageNotFoundComponent} from "@biletix/core/components/page-not-found/page-not-found.component";
import { TranslationResolver } from './core/resolvers/translation.resolver';


const routes: Routes = [
        //MAIN ROUTE
        {
            path: '',
            component: MainLayoutComponent,
            children: [
                {
                    path: 'homepage',
                    redirectTo: '/homepage/ALL/en',
                    //redirectTo: '/home',
                    pathMatch: 'full',
                },
                {
                    path: '',
                    redirectTo: '/homepage/ALL/en',
                    //redirectTo: '/list',
                    pathMatch: 'full',
                },
                {
                    path: `list/:${RouteParameter.PARAMS.REGION}/:${RouteParameter.PARAMS.LANGUAGE}`,
                    pathMatch: 'full',
                    loadChildren: () => import('./features/list/list.module').then(m => m.ListModule),
                    data: {
                        loadingConfig: {icon: 'default.svg'}
                    },
                    resolve:[TranslationResolver],
                    canActivate: [SetLoadingConfigGuard, LanguageGuard, RegionGuard]
                },
                {
                    path: `homepage/:${RouteParameter.PARAMS.REGION}/:${RouteParameter.PARAMS.LANGUAGE}`,
                    pathMatch: 'full',
                    loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule),
                    data: {
                        loadingConfig: {icon: 'default.svg'}
                    },
                    resolve:[TranslationResolver],
                    canActivate: [SetLoadingConfigGuard, LanguageGuard, RegionGuard]
                },
                {
                    path: `event-group/:${RouteParameter.PARAMS.EVENT_GROUP_CODE}/:${RouteParameter.PARAMS.REGION}/:${RouteParameter.PARAMS.LANGUAGE}`,
                    pathMatch: 'full',
                    data: {
                        loadingConfig: {icon: 'ticket.svg'}
                    },
                    resolve:[TranslationResolver],
                    canActivate: [SetLoadingConfigGuard, LanguageGuard, RegionGuard],
                    loadChildren: () => import('./features/event-group/event-group.module').then(m => m.EventGroupModule)
                },
                {
                    path: `event/:${RouteParameter.PARAMS.EVENT_CODE}/:${RouteParameter.PARAMS.REGION}/:${RouteParameter.PARAMS.LANGUAGE}`,
                    pathMatch: 'full',
                    data: {
                        loadingConfig: {icon: 'ticket.svg'}
                    },
                    resolve:[TranslationResolver],
                    canActivate: [SetLoadingConfigGuard, LanguageGuard, RegionGuard],
                    loadChildren: () => import('./features/event/event.module').then(m => m.EventModule)
                },
                {
                    path: `venue/:${RouteParameter.PARAMS.VENUE_CODE}/:${RouteParameter.PARAMS.REGION}/:${RouteParameter.PARAMS.LANGUAGE}`,
                    pathMatch: 'full',
                    data: {
                        loadingConfig: {icon: 'ticket.svg'}
                    },
                    resolve:[TranslationResolver],
                    canActivate: [SetLoadingConfigGuard, LanguageGuard, RegionGuard],
                    loadChildren: () => import('./features/venue/venue.module').then(m => m.VenueModule)
                },
                {
                    path: `performance/:${RouteParameter.PARAMS.EVENT_CODE}/:${RouteParameter.PARAMS.PERF_CODE}/:${RouteParameter.PARAMS.REGION}/:${RouteParameter.PARAMS.LANGUAGE}`,
                    pathMatch: 'full',
                    resolve:[TranslationResolver],
                    canActivate: [SetLoadingConfigGuard, LanguageGuard, RegionGuard],
                    data: {
                        loadingConfig: {icon: 'ticket.svg'}
                    },
                    loadChildren: () => import('./features/performance/performance.module').then(m => m.PerformanceModule)
                },

                {
                    path: `cart/:${RouteParameter.PARAMS.REGION}/:${RouteParameter.PARAMS.LANGUAGE}`,
                    pathMatch: 'full',
                    data: {
                        loadingConfig: {icon: 'cart.svg'}
                    },
                    resolve:[TranslationResolver],
                    canActivate: [SetLoadingConfigGuard, LanguageGuard, RegionGuard],
                    loadChildren: () => import('./features/cart/cart.module').then(m => m.CartModule)
                },

                {
                    path: `myaccount/:${RouteParameter.PARAMS.LANGUAGE}`,
                    data: {
                        loadingConfig: {icon: 'default.svg'}
                    },
                    resolve:[TranslationResolver],
                    canActivate: [SetLoadingConfigGuard, AuthenticationGuard, LanguageGuard, AnonymousGuard],
                    loadChildren: () => import('./features/account/account.module').then(m => m.AccountModule)
                }


            ]
        },
        {
            path: `t/:${RouteParameter.PARAMS.SCODE}/:${RouteParameter.PARAMS.REFCODE}`,
            redirectTo: `t/:${RouteParameter.PARAMS.SCODE}/:${RouteParameter.PARAMS.REFCODE}/en`,
            //redirectTo: '/home',
            pathMatch: 'full',
        },
        {
            
            path: `t/:${RouteParameter.PARAMS.SCODE}/:${RouteParameter.PARAMS.REFCODE}/:${RouteParameter.PARAMS.LANGUAGE}`,
            data: {

            },
            resolve:[TranslationResolver],
            //component:TicketLandingComponent,
            canActivate: [SetLoadingConfigGuard, LanguageGuard],
            loadChildren: () => import('./features/ticket-landing/ticket-landing.module').then(m => m.TicketLandingModule)
        },

        {
            path: `auth/:${RouteParameter.PARAMS.REGION}/:${RouteParameter.PARAMS.LANGUAGE}`,
            // component: AuthLayoutComponent,
            data: {
                loadingConfig: {icon: 'default.svg'}
            },
            resolve:[TranslationResolver],
            canActivate: [SetLoadingConfigGuard, LanguageGuard, RegionGuard],
            loadChildren: () => import('@biletix/features/auth/auth.module').then(m => m.AuthModule)
        },


        /**
         *
         */
        {
            path: 'newsales',
            resolve:[TranslationResolver],
            component: SecondaryLayoutComponent,
            data: {
                showLoginAsAnonymousBtn: true,
                loadingConfig: {icon: 'default.svg'}
            },
            canActivate: [SetLoadingConfigGuard, AuthenticationGuard, ShoppingCartGuard],
            canActivateChild: [AuthenticationGuard, ShoppingCartGuard],
            loadChildren: () => import('@biletix/features/sales/sales.module').then(m =>m.SalesModule),
        },

        /**
         * Redirect to home page when page not found
         *
         * İki yöntem de kullanılabilir.
         */

        // {
        //     path: '**', pathMatch: 'full',
        //     component: PageNotFoundComponent
        // },

        {
            path: '**',
            pathMatch: 'full',
            redirectTo: '/404.btx'
        }
        ,
        {
            path: '404.btx',
            component: PageNotFoundComponent
        }


    ]
;

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'top',
    initialNavigation: 'enabled'
})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
