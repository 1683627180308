
<!--<div class="countdown-wrapper"-->
<!--     [class.timeout]="isTimeout">-->

<!--  &lt;!&ndash;  &ndash;&gt;-->
<!--  <img class="icon"-->
<!--       [src]="isTimeout ?'assets/images/icon/bg-red-timer.svg':'assets/images/icon/bg-orange-timer.svg'">-->


<!--  <countdown #countDown [config]="config"-->
<!--             (event)="handleEvent($event)"></countdown>-->

<!--  &lt;!&ndash;  &ndash;&gt;-->
<!--  <img class="icon"-->
<!--       *ngIf="isTimeout"-->
<!--       [matTooltip]="'refresh'"-->
<!--       (click)="onRestart()"-->
<!--       src="assets/images/icon/refresh.svg">-->

<!--</div>-->



<div [dir]="'dir'| translate" class="header-countdown-wrapper lang-{{currentLocale.lang}}"
     [class.timeout]="isTimeout">
          <span class="material-icons-outlined mat-icon">
                                    timer
                                    </span>

       <!-- <div class="countdown-icon-holder"></div> -->
  <countdown #countDown
             [config]="config"
             (event)="handleEvent($event)"></countdown>

             <div class="countdown-tooltip" *ngIf="lastOneMinute">
                  <div class="countdown-tooltip-holder">
                         <mat-icon class="material-icons-outlined"
                                   fontIcon="icon">timer
                         </mat-icon>
                         <span translate="ticket.labels.ticketExpiration"></span>
                         <mat-icon class="material-icons-outlined" (click)="closeTooltip()"
                                   fontIcon="icon">close
                         </mat-icon>
                  </div>
             </div>
</div>
