import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "@biletix/core/services/authentication.service";
import {LoggedInCustomer} from "@biletix/core/models/authentication/logged-in-customer.model";
import {AbstractionComponent} from "@biletix/core/common/abstraction-component";
import {MenuItem} from "@biletix/core/view-models/menu-item.model";
import {RouterService} from "@biletix/core/services/router.service";
import {Router} from "@angular/router";
import {TicketSaleService} from "@biletix/core/services/ticket-sale.service";
import {LocalStorageService} from "@biletix/core/services/local-storage.service";
import { Locale } from '@biletix/core/common';
import { I18nService } from '@biletix/core/services/i18n.service';

@Component({
    selector: 'btx-account-dropdown',
    templateUrl: './account-dropdown.component.html',
    styleUrls: ['./account-dropdown.component.scss']
})
export class AccountDropdownComponent extends AbstractionComponent implements OnInit {

    loggedInCustomer?: LoggedInCustomer = this.authenticationService.getLoggedInCustomer();
    menuItems: MenuItem[] = [];
    shoppingCartState: any;
    ApplicationStateService: any;

    locales: Locale[];
    currentLocale: Locale;

    constructor(private authenticationService: AuthenticationService,
                private ticketSaleService: TicketSaleService,
                private router: Router,
                public i18n: I18nService,
                private localStorageService: LocalStorageService,
                private routerService: RouterService) {
      super();
                  this.locales = this.i18n.getAvailableLocales();
                 this.currentLocale = this.i18n.currentLocale;
    }

    ngOnInit(): void {

        //check login
        this.checkLogin();

        //watch login customer
        this.watchLoginUser();

        //
        this.buildAccountDropdownMenu();
    }

    showTimerInHeader(){
        //const isMobile = this.getIsMobile();
        const isExistShoppingCart =this.isExistShoppingCart();
        const isCartPage =this.isCartPage();
        const isOrderSummaryExists =this.isOrderSummaryExists();

        //return !isMobile && isExistShoppingCart && !isCartPage&&!isOrderSummaryExists;
        return isExistShoppingCart && !isCartPage&&!isOrderSummaryExists;

      }

      isOrderSummaryExists(){
        return this.shoppingCartState && this.shoppingCartState.isNotEmpty();
      }

      isCartPage() {
        return this.router.url.indexOf('/cart') > -1;
      }

      getIsMobile() {
          return this.ApplicationStateService.getIsMobileResolution();
      }

      isExistShoppingCart(): boolean {
          return this.ticketSaleService.isExistShoppingCart();
      }

    watchLoginUser() {
        this.authenticationService
            .watchLoggedInCustomer
            .pipe(this.takeUntilDestroy())
            .subscribe(loggedInCustomer => this.loggedInCustomer = loggedInCustomer);
    }

    checkLogin() {
        if (this.loggedInCustomer && this.loggedInCustomer.customerId) {
            this.authenticationService
                .checkLogin()
                .pipe(this.takeUntilDestroy())
                .subscribe(isLogin => {
                        if (!isLogin) {
                            this.localStorageService.clear();
                            this.ticketSaleService.clearLocalShoppingCart();
                            this.buildAccountDropdownMenu();
                        }
                    }
                );
        }
    }

    /**
     *
     */
    private buildAccountDropdownMenu() {
      this.menuItems = [];

      if (!this.isAuthenticated) {
            this.menuItems.push({
                order: 1,
                url: this.routerService.loginUrl,
                label: "auth.labels.signIn",
                icon: "login"
            });
            this.menuItems.push({
                order: 2,
                url: this.routerService.registerUrl,
                label: "auth.labels.createAccount",
                icon: "person_add_alt_1"
            });
        }

      if (this.isAuthenticated) {
        this.menuItems.push({
          order: 3,
          url: this.routerService.accountMyTicketsUrl,
          label: "shared.labels.myTickets",
          icon: "confirmation_number"
        });
        this.menuItems.push({
          order: 4,
          url: this.routerService.accountMyPersonalInformationUrl,
          label: "account.labels.myAccount",
          icon: "person_outline"
        });
        /**
          this.menuItems.push({
          order: 4,
          url: this.routerService.accountMyAddressesUrl,
          label: "shared.labels.myAddresses",
          icon: "home"
        });
          **/
        /**
        this.menuItems.push({
          order: 6,
          url: this.routerService.accountFavoritesUrl,
          label: "shared.labels.myFavorites",
          icon: "favorite_border"
        });
          **/
      }

        return this.menuItems.sort((item1, item2) => item1.order < item2.order ? -1 : 1)
    }

    public get isAuthenticated(): boolean {
        return this.authenticationService.getLoggedInCustomer() ?? false;
    }

    public doLogout(): void {
        this.authenticationService
            .doLogout()
            .pipe(this.takeUntilDestroy())
            .subscribe(() => {
                    this.authenticationService.clearLogin();
                    this.ticketSaleService.clearLocalShoppingCart();
                    this.buildAccountDropdownMenu();

                    //TODO: Seam devre dışı kalınca aktif edilecek.
                    //this.routerService.navigateToHomePage();
                    this.routerService.navigateToSeamHomePage();
                }
            );
    }

    public get loggedInUserName() {
        let userName = this.loggedInCustomer?.name;
        if (userName && userName.length > 10) {
            return userName.substring(0, 10).concat("...");
        }
        return userName;
    }

}
