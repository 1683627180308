<!--<header [dir]="'dir'| translate" class="lang-{{currentLocale.lang}}"> -->
<header>

    <!--  -->
    <btx-top-band></btx-top-band>


    <!--  TODO: @Emre bu kısmı da ayrı bir componente alabiliriz.-->
    <!--  LOGO-->
    <div class="bottom-band">
        <div class="container">
            <div class="bottom-band-holder">
                <div class="inside-holder">
                    <a (click)="onToggleSidenav()"
                       class="mobile-menu"
                       fxHide
                       fxShow.lt-md="true">
                        <img [src]="'assets/images/icon/menu.svg'"
                             alt="TM Israel">
                    </a>
                    <btx-site-logo></btx-site-logo>
                </div>

                <!--        -->
<!--                 <btx-event-category-menu></btx-event-category-menu>
 -->
                <!--        -->
<!--                 <btx-event-search-box></btx-event-search-box>
 -->
                <!--        -->
                <div class="bottom-band-inside-holder lang-{{currentLocale.lang}}" fxLayout="row" fxLayoutGap="25px">

                    <!-- Account Menu         -->
                    <btx-account-dropdown></btx-account-dropdown>

                    <btx-header-countdown-timer *ngIf="showTimerInHeader()"></btx-header-countdown-timer>
                    <!-- <btx-countdown-timer></btx-countdown-timer> -->

                    <!--          -->
                    <btx-cart-dropdown></btx-cart-dropdown>

                     <!-- Help Button -->
                     <!-- <button [aria-label]="'footer.help'|translate"></button> -->
                  <div [dir]="'dir'| translate"  class="help-button-container  lang-{{currentLocale.lang}}">
                    <a [href]="'https://help.ticketmaster.co.il/hc/en-us'" [attr.aria-label]="'main.labels.help' | translate" target="_blank">
                      <mat-icon class="help-icon"
                                fxHide.sm>help_outline
                      </mat-icon>
                      <li *ngIf="currentLocale?.lang=='en'" fxHide fxShow.gt-sm="true">
                        <a href="https://help.ticketmaster.co.il/hc/en-us">Help</a>
                      </li>
                      <li *ngIf="currentLocale?.lang=='iw'" fxHide fxShow.gt-sm="true">
                        <a href="https://help.ticketmaster.co.il/hc/he">עזרה</a>
                      </li>
                    </a>
                  </div>
                </div>

            </div>
        </div>
    </div>
</header>



