import {Inject, Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {
    LOADING_CONFIG_DEFAULT,
    LoadingConfigData,
    LoadingScreenService
} from "@biletix/core/components/loading/loading-screen.service";


@Injectable({
    providedIn: 'root'
})
export class SetLoadingConfigGuard implements CanActivate {

    constructor(@Inject(LOADING_CONFIG_DEFAULT) public config: LoadingConfigData) {
    }


    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        
        //console.log("Setloading guard started");    
        const data = route.data;
        if (data && data.loadingConfig) {
            const loadingConfig: LoadingConfigData = data.loadingConfig;
            this.config.icon = loadingConfig.icon;
        }
        //console.log("Setloading guard ended");
        return true;
    }

}
