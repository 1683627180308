import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {ImageUrlManager} from "@biletix/core/common/helper/image-url-manager";
import {environment} from "@biletix/env";

@Pipe({
  name: 'secureImage'
})
export class SecureImagePipe implements PipeTransform {


  constructor(private sanitizer: DomSanitizer) {
  }

  transform(url: string, basePath: string = environment.eventImageBasePath, noImagePath: string = 'noimage.gif'): SafeUrl |string{
      url=ImageUrlManager.create(url,basePath,noImagePath);
    return this.sanitizer.bypassSecurityTrustUrl(url);

  }

}
