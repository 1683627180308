import {ComponentType, Overlay, OverlayConfig, OverlayRef} from "@angular/cdk/overlay";
import {ComponentPortal, PortalInjector} from "@angular/cdk/portal";
import {Injectable, Injector, TemplateRef} from "@angular/core";
import {LoadingComponent} from "@biletix/core/components/loading/loading.component";

@Injectable()
export class OverlayService {

  constructor(private _overlay: Overlay) {
  }


  // Portala component eklemek için component create et.
//   const componentPortal = new ComponentPortal(
//     componentType,
//     null,
//     this.createInjector(LOADING_DEFAULT_OPTIONS, loadingData)
//   );
//
//   // Componenti portala bağla
//   this.overlayRef.attach(componentPortal);
//
// }
//
// createInjector(injectorToken, data): PortalInjector {
//   const injectorTokens = new WeakMap<any, any>([
//     [injectorToken, data],
//   ]);
//   return new PortalInjector(this.injector, injectorTokens);
// }
  /**
   *
   * @param config
   * @param component
   */
  create(config: OverlayConfig, component: ComponentType<any> ,injector?:Injector): OverlayRef {
    const _overlayRef = this._overlay.create(config);

    // Portala component eklemek için component create et.
    const componentPortal = new ComponentPortal(component,null,injector);

    // Componenti portala bağla
    _overlayRef.attach(componentPortal);

    return _overlayRef;
  }

  get overlay(): Overlay {
    return this._overlay;
  }

}
