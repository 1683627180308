import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ApiService} from "@biletix/core/services/api.service";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {DeliveryOption} from "@biletix/core/models/delivery/option/delivery.option.model";
import {ApiResponse} from "@biletix/core/models/api-response";
import {map} from "rxjs/operators";
import {ShoppingCart} from "@biletix/core/models/shopping-cart/shopping-cart.model";
import {DeliveryOptionRequest} from "@biletix/core/models/delivery/option/request/delivery-option-request.model";

@Injectable()
export class DeliveryService {


    private _deliveryBasePath = "/api/v1/delivery";


    private _reloadDeliveryOptions = new Subject<boolean>();
    reloadDeliveryOptionsChange: Observable<boolean>;

    constructor(private http: HttpClient,
                private apiService: ApiService) {
        this.reloadDeliveryOptionsChange = this._reloadDeliveryOptions.asObservable();
    }


    reloadDeliveryOptions(changed: boolean = true) {
        this._reloadDeliveryOptions.next(changed);
    }

    /**
     *
     */
    getDeliveryOptions(): Observable<DeliveryOption[]> {
        return this.http//
            .get<ApiResponse<DeliveryOption[]>>(`${this.apiService.getCrmApiHost()}${this._deliveryBasePath}/getDeliveryOptions`)
            .pipe(map(response => response.data));
    }


    /**
     *
     * @param deliveryRequest
     */
    setDeliveryOption(deliveryRequest: DeliveryOptionRequest): Observable<ShoppingCart> {
        return this.http//
            .post<ApiResponse<ShoppingCart>>(
                `${this.apiService.getCrmApiHost()}${this._deliveryBasePath}/setDeliveryOption`,
                {...deliveryRequest}
            )
            .pipe(map(response => response.data));
    }


}
