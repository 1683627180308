<footer class="empty-footer">
  <div class="empty-footer-corporate" fxLayout="column">
    <div class="footer-seperator" fxFlex="100%"></div>
    <div class="corporate" >
      <h2 checkToggle class="corporate-pages-title" >{{'footer.policiesNotices'|translate}}</h2>
        <ul>
          <!-- <li>
            <a [href]="routerService.getIswPageUrl('ttk')">{{'footer.TTK'|translate}}</a>
          </li> -->
          <li>
            <a [href]="routerService.getIswPageUrl('ourpolicies')">{{'footer.aboutProtectionOfPersonalData'|translate}}</a>
          </li>
          <li>
            <a [href]="routerService.getIswPageUrl('ourpolicies')">{{'footer.cookiePolicy'|translate}}</a>
          </li>
          <li>
            <button id="ot-sdk-btn" class="ot-sdk-show-settings">{{'footer.cookieConsent'|translate}}</button>
          </li>
          <li>
            <a [href]="routerService.getIswPageUrl('ourpolicies')">{{'footer.contractAndPolicies'|translate}}</a>
          </li>
        </ul>

      <div>
        <span class="trademark">{{'footer.trademark'|translate}}</span>
      </div>

    </div>
  </div>
</footer>
