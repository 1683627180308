import {Injectable, InjectionToken} from '@angular/core';
import {Subject} from "rxjs";
import {OverlayService} from "@biletix/core/services/overlay.service";
import {OverlayConfig, OverlayRef} from "@angular/cdk/overlay";
import {LoadingComponent} from "@biletix/core/components/loading/loading.component";


export interface LoadingConfigData {
    message: string,
    icon: string,
    className?: string;
}


export const LOADING_CONFIG_DEFAULT: InjectionToken<LoadingConfigData>
    = new InjectionToken<LoadingConfigData>('LOADING_CONFIG_DEFAULT');


@Injectable({
    providedIn: 'root'
})
export class LoadingScreenService {

    activeRequests: number = 0;

    private _loading: boolean = false;
    loadingStatus: Subject<boolean> = new Subject<boolean>();

    private _overlayRef: OverlayRef | undefined;


    constructor(private overlayService: OverlayService) {
    }

    open() {
        ++this.activeRequests;
        this._loading = true;
        if (this.activeRequests === 1) {
            this.createLoadingComponent();
        }
    }

    private createLoadingComponent(): OverlayRef {

        let config: OverlayConfig = new OverlayConfig();

        //Componentin konumu ayarla
        const positionStrategy = this.overlayService.overlay.position()
            .global()
            .centerHorizontally()
            .centerVertically();

        config.positionStrategy = positionStrategy;
        config.hasBackdrop = true;

        //component portal create..
        this._overlayRef = this.overlayService.create(
            config,
            LoadingComponent
        );

        return this._overlayRef;

    }


    close() {
        --this.activeRequests;
        if (this.activeRequests <= 0) {
            if (this._overlayRef && this._overlayRef.hasAttached()) {
                this._overlayRef.detach();
                this._overlayRef.dispose();
            }
            this.activeRequests = 0;
            this._loading = false;
        }
    }

    get loading(): boolean {
        return this._loading;
    }

    set loading(value) {
        this._loading = value;
        this.loadingStatus.next(value);
    }

    public get activeRequestsCount() {
        return this.activeRequests;
    }


}
