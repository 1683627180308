import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {Customer} from "@biletix/core/models/customer/customer.model";
import {AuthenticationService} from "@biletix/core/services/authentication.service";
import {CustomerService} from "@biletix/core/services/customer.service";
import {EMPTY, Observable, of} from "rxjs";
import {catchError, mergeMap} from "rxjs/operators";
import {GlobalErrorHandler} from "@biletix/core/services/global-error-handler.service";
import {ApiResponseStatus} from "@biletix/core/models/api-response-status";
import {CustomerAccountService} from "@biletix/core/services/customer-account.service";
import {I18nService} from "@biletix/core/services/i18n.service";
import {NotificationService} from "@biletix/core/services/notification.service";
import {RegionService} from "@biletix/core/services/region.service";
import {RouterService} from "@biletix/core/services/router.service";

@Injectable({
    providedIn: 'root'
})
export class LoggedInCustomerResolver implements Resolve<Customer> {

    constructor(private authenticationService: AuthenticationService,
                private customerAccountService: CustomerAccountService,
                private customerService: CustomerService,
                private region: RegionService,
                private i18n: I18nService,
                private notificationService: NotificationService,
                private routerService: RouterService,
                private router: Router,
                private globalErrorHandler: GlobalErrorHandler) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Customer> | Customer | any {

        //Anonim kullanıcı ise customer null
        if (this.authenticationService.isLoggedInAnonymousCustomer()) {
            return null;
        }

        //Giriş yapan kullanıcı bilgisini çek
        return this.customerService
            .getLoggedInCustomer()
            .pipe(
                catchError((err) => {
                    this.globalErrorHandler.handleError(err);
                    // this.goBack();
                    return EMPTY;
                }),
                mergeMap(response => {

                        //işlem başarılı ise confirmation sayfasına yönlendir
                        if (response && response.status == ApiResponseStatus.SUCCESS) {
                            this.customerAccountService.setLocalCustomer(response.data);
                            return of(response.data);
                        }

                        //show error message
                        this.notificationService.error(response.errors);

                        //TODO: Seam devre dışı kalınca aktif edilecek.
                        //this.routerService.navigateToHomePage();
                         this.routerService.navigateToSeamHomePage();
                        return EMPTY;
                    }
                )
            )
    }
}
