import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'btx-event-image',
  templateUrl: './event-image.component.html',
  styleUrls: ['./event-image.component.scss']
})
export class EventImageComponent implements OnInit {

  @Input() imageSrc: string | any;

  @Input() count: number=0;

  @Input("routerLink") routerLink: any[] | string = [];

  constructor() {
  }

  ngOnInit(): void {
  }

}
