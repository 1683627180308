import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {TicketSaleService} from "@biletix/core/services/ticket-sale.service";
import {RegionService} from "@biletix/core/services/region.service";
import {I18nService} from "@biletix/core/services/i18n.service";
import {CartTimeoutService} from "@biletix/core/services/cart-timeout.service";

@Injectable({
    providedIn: 'root'
})
export class ShoppingCartGuard implements CanActivate, CanActivateChild {

    constructor(private ticketSaleService: TicketSaleService,
                private region: RegionService,
                private i18n: I18nService,
                private router: Router,
                private timeoutService: CartTimeoutService) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (this.ticketSaleService.isExistShoppingCart()) {
            let deliveryChoice = this.ticketSaleService.getLocalShoppingCart().deliveryChoice;
            if (deliveryChoice && deliveryChoice.deliveryType) {
                return true;
            }
        }

        // Else force redirect to basket page
        this.router.navigate(['/cart', this.region.regionName, this.i18n.currentLang]);

        return false;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(childRoute, state);
    }

}
