import {MatPaginatorIntl} from "@angular/material/paginator";
import {TranslateService} from "@ngx-translate/core";
import {Injectable, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";

/**
 * @author ozaytunctan
 * @version 2.0
 *
 * MatPaginatorIntl.class
 */
@Injectable({
  providedIn: "root"
})
export class CustomPaginatorIntl extends MatPaginatorIntl implements OnDestroy {

  firstPageLabelKey = 'shared.material.paginator.firstPageLabel';
  itemsPerPageLabelKey = 'shared.material.paginator.itemsPerPageLabel';
  lastPageLabelKey = 'shared.material.paginator.lastPageLabel';
  nextPageLabelKey = 'shared.material.paginator.nextPageLabel';
  previousPageLabelKey = 'shared.material.paginator.previousPageLabel';

  langSubscription: Subscription = Subscription.EMPTY;

  constructor(private translateService: TranslateService) {
    super();
    this.translateLabels();
    this.langSubscription = this.translateService
      .onLangChange
      .subscribe((e: Event) => {
        this.translateLabels();
      });
  }

  translateLabels() {
    this.firstPageLabel = this.translate(this.firstPageLabelKey);
    this.itemsPerPageLabel = this.translate(this.itemsPerPageLabelKey);
    this.lastPageLabel = this.translate(this.lastPageLabelKey);
    this.nextPageLabel = this.translate(this.nextPageLabelKey);
    this.previousPageLabel = this.translate(this.previousPageLabelKey);
  }

  translate(key: string) {
    return this.translateService.instant(key);
  }

  ngOnDestroy(): void {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }

  }

}
