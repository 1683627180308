import {Component, Inject, OnInit} from '@angular/core';
import { Locale } from '@biletix/core/common';
import {LOADING_CONFIG_DEFAULT, LoadingConfigData} from "@biletix/core/components/loading/loading-screen.service";
import { I18nService } from '@biletix/core/services/i18n.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'btx-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

    locales: Locale[];
    currentLocale: Locale;
    constructor(@Inject(LOADING_CONFIG_DEFAULT) public config: LoadingConfigData,
    public i18n: I18nService,
    private  translateService: TranslateService,) {
        this.locales = this.i18n.getAvailableLocales();
            this.currentLocale = this.i18n.currentLocale;
    }

    
    ngOnInit(): void {
    }

}
