import {Injectable} from "@angular/core";
import {Event, NavigationEnd, Router, RouterEvent} from "@angular/router";
import {BehaviorSubject, Observable} from "rxjs";
import {filter} from "rxjs/operators";


@Injectable()
export class NavigationService {


  public appDrawer: any;


  private _currentUrl = new BehaviorSubject<string | any>(null);
  currentUrl: Observable<string>;

  constructor(private router: Router) {
    this.currentUrl = this._currentUrl.asObservable();

    //
    this.router.events
      .pipe(filter((e: Event | RouterEvent | any) => e instanceof NavigationEnd))
      .subscribe((event: RouterEvent | any) => this._currentUrl.next(event.urlAfterRedirects));
  }

  public closeNav() {
    this.appDrawer.close();
  }

  public openNav() {
    this.appDrawer.open();
  }

}
