export const ErrorStatusCode = {

  USER_REQUIRED: "USER_REQUIRED",

  CUSTOMER_REQUIRED: "CUSTOMER_REQUIRED",

  INVALID_CHANNEL_HEADER: "INVALID_CHANNEL_HEADER",

  INVALID_CPU_HEADER: "INVALID_CPU_HEADER",

  SHOPPING_CART_EMPTY: "SHOPPING_CART_EMPTY"

}
