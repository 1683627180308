<div class="category-menu">
    <!-- <mat-accordion>
        <mat-expansion-panel *ngFor="let category of categories" (opened)="panelOpenState = true"
                                (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
            <mat-panel-title>
                {{category.categoryName|translate}}
            </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let subCategory of category.children">
                <a
                    [href]="subCategory.url"
                    class="category-card">
                    <span>{{subCategory.categoryName|translate}}</span>
                    <span class="material-icons-outlined right-arrow"> chevron_right </span>
                </a>
            </mat-list-item>
            <a class="see-all-events"
                    *ngIf="category.linkLabel"
                    [href]="category.url">{{category.linkLabel|translate}}</a>
            </mat-list>
        </mat-expansion-panel>

    </mat-accordion>

    <div class="mobile-seperator"></div> -->

    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header class="lang-dropdown">
            <mat-panel-title translate="main.labels.language"></mat-panel-title>
            <img [src]="'assets/images/flag/'+currentLocale.flag" [alt]="currentLocale.lang|uppercase" class="flag">
            </mat-expansion-panel-header>

            <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let locale of locales "
                [ngClass]="{'selected':currentLocale?.lang==locale.lang}"
                [disabled]="currentLocale?.lang==locale.lang"
                (click)="changeLanguage(locale)"><span class="inner-item">{{locale.displayName}}</span>
            </mat-list-item>
            </mat-list>
        </mat-expansion-panel>
    </mat-accordion>

    <!-- <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header class="lang-dropdown">
            <mat-panel-title>{{'main.labels.region'|translate}}</mat-panel-title>

            </mat-expansion-panel-header>

            <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let region of regions"
            (click)="changeRegion(region)"
            [disabled]="region.name==currentRegion?.name"><span class="inner-item">{{region.displayName|translate}}</span>
            </mat-list-item>
            </mat-list>
        </mat-expansion-panel>
    </mat-accordion> -->
</div>

<!-- <ul class="category-menu"
    fxHide
    fxShow.gt-md="true">

    <li *ngFor="let category of categories">

        <a>{{category.categoryName|translate}}</a>

        <span class="material-icons-outlined mobile-arrow">
            chevron_right
            </span>

        <div class="submenu">
            <div class="submenu-item-holder">
                <a *ngFor="let subCategory of category.children"
                   [href]="subCategory.url"
                   class="category-card">
                    <img [src]="subCategory.icon"
                         [alt]="subCategory.categoryName|translate">
                    <span>{{subCategory.categoryName|translate}}</span>
                </a>
            </div>
            <a class="see-all-events"
               *ngIf="category.linkLabel"
               [href]="category.url">{{category.linkLabel|translate}}</a>
        </div>
    </li>
</ul> -->


<!--            Mobile Categories-->
<!-- <div class="button-holder mobileCategoryMenuHolder"
     fxHide
     fxShow.md="true">

    <button mat-button
            [matMenuTriggerFor]="mobileCategoryMenuHolder"
            (click)="categoryMenuExpanded=!categoryMenuExpanded">
        {{'shared.labels.categories'|translate}}
        <mat-icon class="chevron-icon"
                  color="primary"
                  fxHide fxShow.md="true" fxShow.lg="true">
            {{categoryMenuExpanded ? 'expand_less' : 'expand_more'}}
        </mat-icon>
    </button>


    <mat-menu #mobileCategoryMenuHolder="matMenu"
              (close)="categoryMenuExpanded=false">
        <ng-container *ngFor="let category of categories">
            <a mat-menu-item
               class="category-item"
               [href]="category.url"
               [matMenuTriggerFor]="sub_menu">{{ category.categoryName|translate }}</a>
            <mat-menu #sub_menu="matMenu">
                <a mat-menu-item
                   class="sub-category-item"
                   *ngFor="let subCategory of category.children"
                   [href]="subCategory.url">
                    {{ subCategory.categoryName|translate }}
                </a>
            </mat-menu>
        </ng-container>

    </mat-menu>
</div> -->
