import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MaterialModule} from '@biletix/core/material/material.module';
import {AddressInformationFormComponent,} from '@biletix/shared/components/customer/address-information-form/address-information-form.component';
import {EditCustomerAddressComponent,} from '@biletix/shared/components/customer/edit-customer-address/edit-customer-address.component';
import {BtxPhoneInputComponent} from '@biletix/shared/components/dynamic-form/btx-phone-input/btx-phone-input.component';
import {EventDescriptionComponent} from '@biletix/shared/components/event/event-desription/event-description.component';
import {EventImageComponent} from '@biletix/shared/components/event/event-image/event-image.component';
import {ConfirmEqualValidatorDirective} from '@biletix/shared/directives/validators/confirm-equal-validator.directive';
import {ShortenPipe} from '@biletix/shared/pipes/shorten.pipe';
import {TranslateModule} from '@ngx-translate/core';
import {NgxCaptchaModule} from 'ngx-captcha';
import {CountdownModule} from 'ngx-countdown';
import {NgxMaskModule} from 'ngx-mask';
import {SwiperModule} from 'swiper/angular';
import {SafeUrlPipe} from './pipes/safe-url.pipe';

import {EmptyOrderSummaryComponent,} from '@biletix/features/performance/components/performance-page/order-summary/empty-order-summary/empty-order-summary.component';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {CheckoutSummaryComponent} from './components/checkout-summary/checkout-summary.component';
import {CountdownTimerComponent} from './components/countdown-timer/countdown-timer.component';
import {InvoiceInformationFormComponent,} from './components/customer/invoice-information-form/invoice-information-form.component';
import {BtxCheckBoxComponent} from './components/dynamic-form/btx-check-box/btx-check-box.component';
import {BtxDateComponent} from './components/dynamic-form/btx-date/btx-date.component';
import {BtxHesCodeInputComponent} from './components/dynamic-form/btx-hes-code-input/btx-hes-code-input.component';
import {BtxInputComponent} from './components/dynamic-form/btx-input/btx-input.component';
import {BtxPhotoInputComponent} from './components/dynamic-form/btx-photo-input/btx-photo-input.component';
import {BtxRadioButtonComponent} from './components/dynamic-form/btx-radio-button/btx-radio-button.component';
import {BtxSelectComponent} from './components/dynamic-form/btx-select/btx-select.component';
import {DynamicFormComponent} from './components/dynamic-form/dynamic-form.component';
import {NotificationBarComponent} from './components/notification-bar/notification-bar.component';
import {CartTimeoutModalComponent} from './components/popups/cart-timeout-modal/cart-timeout-modal.component';
import {EventRuleModalComponent} from './components/popups/event-rule-modal/event-rule-modal.component';
import {ProductActionsComponent} from './components/product/product-actions/product-actions.component';
import {ProductImageComponent} from './components/product/product-image/product-image.component';
import {ProductPriceComponent} from './components/product/product-price/product-price.component';
import {ReadMoreComponent} from './components/read-more/read-more.component';
import {RowTextItemComponent} from './components/row-text-item/row-text-item.component';
import {SideNavLinkComponent} from './components/side-nav-list/side-nav-link/side-nav-link.component';
import {SideNavListComponent} from './components/side-nav-list/side-nav-list.component';
import {SubTitleComponent} from './components/sub-title/sub-title.component';
import {TitleComponent} from './components/title/title.component';
import {BlockCopyPasteDirective} from './directives/block-copy-paste.directive';
import {DynamicFieldDirective} from './directives/dynamic-field.directive';
import {CustomCurrencyPipe} from './pipes/custom-currency.pipe';
import {FilterPipe} from './pipes/filter.pipe';
import {LocalizedDatePipe} from './pipes/localized-date.pipe';
import {OrderByPipe} from './pipes/order-by.pipe';
import {SecureImagePipe} from './pipes/secure-image.pipe';
import {AddressItemDetailComponent} from './components/customer/address-item-detail/address-item-detail.component';
import {InterestedEventsComponent} from './components/event/interested-events/interested-events.component';
import {ShareButtonComponent} from './components/share-button/share-button.component';
import {SpecialCharacterDirective} from './directives/special-character.directive';
import {AllowNumberDirective} from './directives/allow-number.directive';
import {AllowCharacterDirective} from "@biletix/shared/directives/allow-character.directive";
import {FlagPickerComponent} from "@biletix/shared/components/dynamic-form/flag-picker/flag-picker.component";
import {TicketSummaryListComponent} from './components/ticket-summary-list/ticket-summary-list.component';
import {TicketSummaryItemComponent} from './components/ticket-summary-list/ticket-summary-item/ticket-summary-item.component';
import { InterestInfoComponent } from '@biletix/features/performance/components/performance-page/order-summary/interest-info/interest-info.component';
import { QRCodeModule } from 'angularx-qrcode';
import { SecureUpsellImagePipe } from './pipes/secure-upsell-image.pipe';
import { Stay22Component } from '@biletix/features/performance/components/performance-page/stay22/stay22.component';
import { CancelEventInfoComponent } from './components/event/cancel-event-info/cancel-event-info/cancel-event-info.component';


/**
 *İlerde connfigurasyon ayarı gekirse bu interface üzerinden yapılacak.
 */
export interface SharedModuleConfig {

}


@NgModule({
    declarations: [

        //PIPE
        ShortenPipe,
        OrderByPipe,
        SecureImagePipe,
        SecureUpsellImagePipe,
        FilterPipe,
        CustomCurrencyPipe,
        SafeHtmlPipe,
        SafeUrlPipe,
        LocalizedDatePipe,

        //Directive
        DynamicFieldDirective,
        ConfirmEqualValidatorDirective,
        BlockCopyPasteDirective,
        SpecialCharacterDirective,
        AllowNumberDirective,
        AllowCharacterDirective,

        //Components
        EmptyOrderSummaryComponent,
        BtxInputComponent,
        DynamicFormComponent,
        BtxSelectComponent,
        BtxDateComponent,
        BtxCheckBoxComponent,
        BtxRadioButtonComponent,
        BtxPhoneInputComponent,
        BtxPhotoInputComponent,
        BtxHesCodeInputComponent,
        EditCustomerAddressComponent,
        CheckoutSummaryComponent,
        AddressInformationFormComponent,
        InvoiceInformationFormComponent,
        ReadMoreComponent,
        SideNavLinkComponent,
        SideNavListComponent,
        EventRuleModalComponent,
        ProductImageComponent,
        ProductActionsComponent,
        TitleComponent,
        SubTitleComponent,
        ProductPriceComponent,
        CountdownTimerComponent,
        BreadcrumbComponent,
        EventDescriptionComponent,
        EventImageComponent,
        CartTimeoutModalComponent,
        RowTextItemComponent,
        NotificationBarComponent,
        AddressItemDetailComponent,
        ShareButtonComponent,
        InterestedEventsComponent,
        FlagPickerComponent,
        TicketSummaryListComponent,
        TicketSummaryItemComponent,
        InterestInfoComponent,
        Stay22Component,
        CancelEventInfoComponent,
    ],
    imports: [
        //#VENDOR
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        FlexLayoutModule,
        NgxCaptchaModule,
        NgxMaskModule,
        TranslateModule,
        CountdownModule,
        SwiperModule,
        QRCodeModule,

    ],
    exports: [


        //#VENDOR
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        //ngx-mask
        NgxMaskModule,
        //flex-layout
        FlexLayoutModule,
        //angular-material
        MaterialModule,
        TranslateModule,
        NgxCaptchaModule,
        CountdownModule,
        SwiperModule,
        //angularx - qrcode
        QRCodeModule,

        //PIPE
        ShortenPipe,
        CustomCurrencyPipe,
        OrderByPipe,
        SecureImagePipe,
        SecureUpsellImagePipe,
        FilterPipe,
        SafeHtmlPipe,
        SafeUrlPipe,
        LocalizedDatePipe,

        //Directive
        DynamicFieldDirective,
        ConfirmEqualValidatorDirective,
        BlockCopyPasteDirective,
        SpecialCharacterDirective,
        AllowNumberDirective,
        AllowCharacterDirective,

        //#LOCAL
        DynamicFormComponent,
        EmptyOrderSummaryComponent,
        CheckoutSummaryComponent,
        EditCustomerAddressComponent,
        AddressInformationFormComponent,
        InvoiceInformationFormComponent,
        ReadMoreComponent,
        SideNavLinkComponent,
        SideNavListComponent,
        EventRuleModalComponent,
        ProductActionsComponent,
        TitleComponent,
        SubTitleComponent,
        ProductPriceComponent,
        CountdownTimerComponent,
        BreadcrumbComponent,
        EventDescriptionComponent,
        EventImageComponent,//TODO:Kaldıırlacak Product image eklenecek
        RowTextItemComponent,
        NotificationBarComponent,
        AddressItemDetailComponent,
        ShareButtonComponent,
        InterestedEventsComponent,
        BtxPhoneInputComponent,
        TicketSummaryListComponent,
        TicketSummaryItemComponent,
        InterestInfoComponent,
        Stay22Component,
    ],
    providers: [
        LocalizedDatePipe
    ]
})
export class SharedModule {


    // /**
    //  *
    //  */
    // static forRoot(config:SharedModuleConfig): ModuleWithProviders<SharedModule> {
    //   return {
    //     ngModule: SharedModule,
    //     providers: [
    //       ShortenPipe
    //     ]
    //   }
    // }
    //
    //
    // static forChild(config?:SharedModuleConfig):
    //   ModuleWithProviders<SharedModule> {
    //   return {
    //     ngModule: SharedModule,
    //     providers: [
    //       ShortenPipe
    //     ]
    //   }
    //
    // }

}
