import {WINDOW} from "@biletix/core/common";
import {Inject, Injectable, Optional, PLATFORM_ID} from "@angular/core";
import {environment} from "@biletix/env";
import { isPlatformServer } from "@angular/common";
import { REQUEST, RESPONSE } from "@nguniversal/express-engine/tokens";


@Injectable({providedIn: "root"})
export class ApiService {

  isServer=false;

  constructor(@Inject(WINDOW) private window: Window,
              @Inject(PLATFORM_ID) platformId: Object,
              @Optional() @Inject(REQUEST) private request: any,
              @Optional() @Inject(RESPONSE) private response: any,) {

    this.isServer = isPlatformServer(platformId);
  }

  getHostName(): string {
    return this.window.location.hostname;
  }

  getProtocol(): string {
    return this.window.location.protocol;
  }

  getDomain(): string {
    return this.getProtocol() + "//" + this.getHostName();
  }

  getConfirmSaleUrl():string {

    if(environment.production){
      return this.getDomain() + "/newsales/confirmation";  
    }

    return this.getDomain() + "/webclient/newsales/confirmation";

  }


  /*****************************************************************
   ****************************************************
   * CRM API ENDPOINTS
   *
   */
  getCrmApiHost(): string {

    let crmApiHost =environment.crm_api.url;

    if (environment.production&&!this.isServer){
      crmApiHost =`${this.getDomain()}${environment.crm_api.contextPath}`;
    }

    if(environment.production&&this.isServer){
      const hostName =this.request.headers.host;
      crmApiHost="https://"+hostName+"/wbtxapi";
    }

    if(this.isServer){
      console.log("Server Side Rendering request : "+JSON.stringify(this.request.headers));
    }

    console.log("IsServer : "+this.isServer);
    console.log("CrmApiHost : "+crmApiHost);
    return crmApiHost;
  }


  getIsmApiHost() {
    if (environment.production&&!this.isServer)
      return `${this.getDomain()}${environment.ism_api.contextPath}`;

    return environment.ism_api.url;
  }

  getIsmWebSocketUrl(): string {
    return `${this.getIsmApiHost()}/seatWebsocket`;
  }
}
