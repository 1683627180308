import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {CustomTicketFieldConfig} from "@biletix/core/models/event/custom-ticket-field-config.model";
import {Validator} from "@biletix/core/models/base/field-config.model";
import {ValidationFieldConfig} from "@biletix/core/models/event/validation-field-config.model";
import {ApiError} from "@biletix/core/models/api-error";

export class CustomValidators {

    public static emailPattern: string = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";

    public static DIGIT_PATTERN: RegExp | string = '(?=.*[0-9])';// en az bir rakam

    public static LOWER_CASE_CHAR_PATTERN: RegExp | string = /[a-z]/;// en az bir küçük harf

    public static UPPER_CASE_CHAR_PATTERN: RegExp = /[A-Z]/;// en az bir büyük harf

    public static LEAST_ONE_LETTER_PATTERN: RegExp | string = "(?=.*[a-zA-ZçıöşüÇİÖÜ])";

    public static PUNCTUATION_PATTERN: RegExp = /[!@#$%^&*()_+-=[]{};':"|,.<>?]/;// en az bir özel karakter içermeli //(?=.*[$@$!%*?&])

    public static PASSWORD_PATTERN: string = `^(?=.*[0-9])(?=.*[a-zA-ZçıöşüÇİÖÜ\u0590-\u05FF]).{8,20}$`;//Şifre en az 8 en fazla 20 karakter uzunluğunda sayı ve harf içermelidir.


    /**
     *
     *   @example: this.fb.group({
     *   password: ['', [Validators.required]],
     *   confirmPassword: ['', [Validators.required]]
     *  },
     * {validators: [CustomValidators.matchPassword('password', 'confirmPassword')]})

     * @param controlName
     * @param confirmControlName
     */
    static matchPassword(passwordControlName: string, confirmPasswordControlName: string): ValidatorFn | null {
        return (abstractControl: AbstractControl): ValidationErrors | null => {
            let formGroup: FormGroup = <FormGroup>abstractControl;
            if (formGroup == null) {
                return null;
            }

            let confirmPasswordControl = formGroup.controls[confirmPasswordControlName];
            if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
                return null;
            }

            let passwordControl = formGroup.controls[passwordControlName];
            if (passwordControl.value !== confirmPasswordControl.value) {
                confirmPasswordControl.setErrors({passwordMismatch: true});
            } else {
                confirmPasswordControl.setErrors(null);
            }
            return null;
        }
    }

    static matchEmail(emailControlName: string, confirmEmailControlName: string): ValidatorFn | null {
        return (abstractControl: AbstractControl): ValidationErrors | null => {
            let formGroup: FormGroup = <FormGroup>abstractControl;
            if (formGroup == null) {
                return null;
            }

            let confirmEmailControl = formGroup.controls[confirmEmailControlName];
            if (confirmEmailControl.errors && !confirmEmailControl.errors.passwordMismatch) {
                return null;
            }

            let emailControl = formGroup.controls[emailControlName];
            if (emailControl.value !== confirmEmailControl.value) {
                confirmEmailControl.setErrors({emailMismatch: true});
            } else {
                confirmEmailControl.setErrors(null);
            }
            return null;
        }
    }


    /**
     *
     * @param controlName
     * @param confirmControlName
     */
    static samePassword(passwordControlName: string, confirmPasswordControlName: string): ValidatorFn | null {
        return (abstractControl: AbstractControl): ValidationErrors | null => {
            let formGroup: FormGroup = <FormGroup>abstractControl;
            if (formGroup == null) {
                return null;
            }

            let confirmPasswordControl = formGroup.controls[confirmPasswordControlName];
            //Diğer validationlar için null olmasın diye yapıldı.
            if (confirmPasswordControl.errors && !confirmPasswordControl.errors.samePassword) {
                return null;
            }

            let passwordControl = formGroup.controls[passwordControlName];
            if (passwordControl.value == confirmPasswordControl.value) {
                confirmPasswordControl.setErrors({samePassword: true});
            } else {
                confirmPasswordControl.setErrors(null);
            }
            return null;
        }
    }


    public static passwordValidators(): ValidatorFn | null {
        return Validators.compose([
            Validators.required,
            Validators.pattern(CustomValidators.PASSWORD_PATTERN),
            CustomValidators.patternValidator(CustomValidators.DIGIT_PATTERN, {hasNumber: true}),//Bir sayı olmalı
            //CustomValidators.patternValidator(CustomValidators.LEAST_ONE_LETTER_PATTERN, {hasOneLetter: true}),//Bir harf içermeli
            Validators.minLength(8), Validators.maxLength(20)]);
    }

    public static patternValidator(regex: RegExp | string, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            // if control is empty-order-summary return no error
            if (!control.value) {
                return null;
            }

            if (typeof regex == 'string') {
                regex = new RegExp(regex);
            }

            const valid = (<RegExp>regex).test(control.value);
            return valid ? null : error;
        }
    };


    /**
     *@example
     this.editFormGroup = this.fb.group({
        date: ['', CustomValidators.futureNow],
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required,]]
      }
     )

     * @param control
     */
    static futureNow(control: AbstractControl): ValidationErrors | null {
        let future: Date = new Date(control.value);
        let now = Date.now();
        if (future && future.getTime() < now)
            return {future: true};

        return null;
    };

    /**
     *@example
     this.editFormGroup = this.fb.group({
        future: ['', CustomValidators.futureDate('10.10.2021' or new Date())],
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required,]]
      }
     )
     * @param control
     */
    static futureDate(date: Date | string): ValidatorFn | null {
        return (control: AbstractControl): ValidationErrors | null => {
            if (date == null || date.toString())
                return null;

            let value: Date = new Date(control.value);
            if (value && value < date)
                return {future: true};

            return null;
        }
    };

    static pastDate(control: AbstractControl): ValidationErrors | null {
        let past: Date = new Date(control.value);
        let now = Date.now();
        if (past && past.getTime() > now)
            return {past: true};

        return null;
    };


    static validateCcNumber(control: AbstractControl): ValidationErrors | null {
        let ccNo = control.value;
        if (!(ccNo.startsWith('37')
            || ccNo.startsWith('4')
            || ccNo.startsWith('34')
            || ccNo.startsWith('5'))
        ) {
            // Return error if card is not Amex, Visa or Mastercard
            return {creditCard: true};
        } else if (ccNo.length < 15 || ccNo.length > 16) {
            // Return error if length is not 16 digits
            return {creditCard: true};
        }

        return null;
    }

    /**
     * TODO: Test aşamasında eksik tipler için validasyon kelenecek.
     * @param customField
     */
    static createValidations(customField: CustomTicketFieldConfig | ValidationFieldConfig): Validator[] {

        let validators: Validator[] = [];
        if (customField) {
            let fieldType = customField.type.toLowerCase();
            //Todo: Server tarafında update dilecek returnRequired isRequired olacak.
            if ((<CustomTicketFieldConfig>customField).required || (<ValidationFieldConfig>customField).returnRequired) {
                validators.push({
                    message: "shared.validation.errors.required",
                    name: "required",
                    validator: Validators.required
                });
            }
            if (customField.maxLength > 0) {
                validators.push({
                        message: "shared.validation.errors.maxLength",
                        name: "maxlength",
                        validator: Validators.maxLength(customField.maxLength)
                    }
                );
            }


            if (fieldType == 'email') {
                validators.push({
                    message: "shared.validation.errors.pattern",
                    name: "pattern",
                    validator: Validators.pattern(this.emailPattern)
                });
            }

            //Todo: BU ksım güncellenecek.
            if (fieldType == 'tckno') {
                validators.push({
                    message: "shared.validation.errors.pattern",
                    name: "identityNumber",
                    validator: CustomValidators.tcknValidate
                });
            }

            // if (fieldType == 'plate') {
            //   validators.push({
            //     message: "shared.validation.errors.pattern",
            //     name: "plateNumber",
            //     validator: CustomValidators.plate
            //   });
            // }

            if (fieldType == 'heskod') {
                validators.push({
                    message: "shared.validation.errors.pattern",
                    name: "hesCode",
                    validator: CustomValidators.hesCode
                });
            }


        }

        return validators;
    }

    public static getValidators(validations: Validator[] | any): ValidatorFn[] | null {
        if (validations && validations.length > 0) {
            const validList: ValidatorFn[] = [];
            validations.forEach((valid: Validator) => {
                validList.push(valid.validator);
            });
            return validList;
        }
        return null;
    }


    /**
     * TODO: Tc kimlik validasyonu sonra düzenlencek. Kimlik numarası geçerli olup olmadığı kontrolu yapılacak.
     * @param control
     */
    public static identityNumber(control: AbstractControl): ValidationErrors | null {
        let identityNumber: string = control.value;
        //validate TCKN
        if (identityNumber && identityNumber.length == 11) {
            return CustomValidators.tcknValidate(control);
        // validate VKN
        } else {
            return CustomValidators.vknValidate(control);
        }
    };

    public static tcknValidate(control: AbstractControl): ValidationErrors | null {
        let identityNumber: string = control.value;

        //validate TCKN
        let totalX = 0;
        for (let i = 0; i < 10; i++) {
            totalX += Number(identityNumber.substr(i, 1));
        }
        let isRuleX = totalX % 10 == Number(identityNumber.substr(10, 1));
        let totalY1 = 0;
        let totalY2 = 0;
        for (let i = 0; i < 10; i += 2) {
            totalY1 += Number(identityNumber.substr(i, 1));
        }
        for (let i = 1; i < 10; i += 2) {
            totalY2 += Number(identityNumber.substr(i, 1));
        }
        var isRuleY = ((totalY1 * 7) - totalY2) % 10 == Number(identityNumber.substr(9, 0));
        if (!(isRuleX && isRuleY)) {
            return { identityNumber: false };
        } else {
            return null;
        }
    };

    public static vknValidate(control: AbstractControl): ValidationErrors | null {
        let identityNumber: string = control.value;

        //validate VKN
        let v = []
        let lastDigit = Number(identityNumber.charAt(9))
        for (let i = 0; i < 9; i++) {
            let tmp = (Number(identityNumber.charAt(i)) + (9 - i)) % 10
            v[i] = (tmp * 2 ** (9 - i)) % 9
            if (tmp !== 0 && v[i] === 0) v[i] = 9
        }
        let sum = v.reduce((a, b) => a + b, 0) % 10
        if (((10 - (sum % 10)) % 10) != lastDigit) {
            return { identityNumber: false };
        }
        return null;
    };

    public static cannotContainSpace(control: AbstractControl) : ValidationErrors | null {
        if((control.value as string).indexOf(' ') >= 0){
            return {cannotContainSpace: true}
        }
        return null;
    }

    public static hesCode(control: AbstractControl): ValidationErrors | null {
        let hesCode: string = control.value;
        if (hesCode && hesCode.length < 9)
            return {hesCode: false};

        return null;
    };

    public static resetForm(formGroup: FormGroup, ...formControlNames: string []): FormGroup {
        formControlNames?.forEach(name => {
                const control = formGroup.controls[name];
                control?.setErrors(null);
                control?.reset();
            }
        );
        return formGroup;
    }


    static setErrors(formGroup: FormGroup | any, errors: ApiError[]) {
        if (!formGroup) {
            return;
        }

        errors?.filter(error => formGroup.controls[error.code])
            .forEach(error => {

                    let control = formGroup.controls[error.code];
                    control.reset();

                    let validationErrors: any | ValidationErrors = {};
                    validationErrors[error.code] = error.message;
                    control.setErrors(validationErrors);
                }
            );
    }
}
