<footer [dir]="'dir'| translate" [ngClass]="{'sticky-area': footerStyleValue == true}" class="lang-{{currentLocale.lang}}">
  <div class="container" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column">
    <div class="logo-col" fxFlex="100" fxFlex.xs="25" fxFlex.sm="100" fxFlex.md="100">
      <a [href]="routerService.homePageUrl"><img [src]="'assets/images/iswlogo.svg'" alt="TicketmasterIsrael" class="logo"></a>
     <!-- <a [routerLink]="[routerService.homePageUrl]"><img [src]="'assets/images/iswlogo.svg'" alt="Ticketmaster Israel" class="logo"></a> -->
      <span class="address">{{'footer.address'|translate}} {{'footer.addressText'|translate}}</span>
      <!-- <h2>{{'footer.followUs'|translate}}</h2>
      <ul class="social">
        <li class="facebook"><a href="https://www.facebook.com/Biletix" target="_blank"></a></li>
        <li class="twitter"><a href="https://twitter.com/biletix" target="_blank"></a></li>
        <li class="blog"><a href="http://blog.biletix.com/" target="_blank"></a></li>
        <li class="youtube"><a href="https://www.youtube.com/channel/UCI6ownTOlxgaN0Um74-sC6Q" target="_blank"></a></li>
        <li class="instagram"><a href="http://instagram.com/biletix" target="_blank"></a></li>
        <li class="linkedin"><a href="https://www.linkedin.com/company/biletix" target="_blank"></a></li>
        <li class="spotify"><a href="https://open.spotify.com/user/qcjtfkcpiw94n579mfq1h2pq2?si=b02989c27f564710" target="_blank"></a></li>
      </ul>
      <h2>{{'footer.ourApps'|translate}}</h2>
      <ul class="app-icon">
        <li *ngIf="currentLocale?.lang=='tr'"><a href="https://itunes.apple.com/tr/app/biletix/id1181457295?ls=1&mt=8"><img [src]="'assets/images/icon/app-store.svg'" alt="Biletix Ios App" ></a></li>
        <li *ngIf="currentLocale?.lang=='en'"><a href="https://itunes.apple.com/tr/app/biletix/id1181457295?ls=1&mt=8"><img [src]="'assets/images/icon/app-store-en.png'" alt="Biletix Ios App" ></a></li>
        <li *ngIf="currentLocale?.lang=='tr'"><a href="https://play.google.com/store/apps/details?id=com.biletix.ticketmaster"><img [src]="'assets/images/icon/play-store.svg'" alt="Biletix Android App" ></a></li>
        <li *ngIf="currentLocale?.lang=='en'"><a href="https://play.google.com/store/apps/details?id=com.biletix.ticketmaster"><img [src]="'assets/images/icon/play-store-en.png'" alt="Biletix Android App" ></a></li>
      </ul> -->
    </div>
    <div class="" fxFlex="100" fxFlex.xs="25" fxFlex.sm="100" fxFlex.md="100">
      <div class="footer-column-holder">
        <h2 checkToggle>{{'footer.aboutUs'|translate}}</h2>
        <ul>
          <!-- <li><a [href]="routerService.getSeamPageUrl('about')">{{'footer.whoWeAre'|translate}}</a></li> -->
          <li><a [href]="routerService.getIswPageUrl('about')">{{'footer.whoWeAre'|translate}}</a></li>
          <li><a [href]="routerService.getIswPageUrl('ourpolicies')">{{'footer.ourPolicy'|translate}}</a></li>
          <li><a [href]="routerService.getIswPageUrl('international')">{{'footer.international'|translate}}</a></li>
          <!-- <li><a [href]="routerService.getSeamPageUrl('visualmaterials')">{{'footer.visualMaterials'|translate}}</a></li> -->
          <!-- <li><a [href]="routerService.getSeamPageUrl('contactus')">{{'footer.contactUs'|translate}}</a></li> -->
          <!-- <li><a href="http://www.livenation.com/">{{'footer.liveNationEntertainment'|translate}}</a></li> -->
          <!-- <li><a [href]="routerService.getSeamPageUrl('dunyakansergunu')">{{'footer.socialResponsibility'|translate}}</a></li> -->
        </ul>
      </div>
    </div>
    <div class="" fxFlex="100" fxFlex.xs="25" fxFlex.sm="100" fxFlex.md="100">
      <div class="footer-column-holder">
        <h2 checkToggle>{{'footer.workWithUs'|translate}}</h2>
        <ul>
          <li><a [href]="routerService.getIswPageUrl('career')">{{'footer.career'|translate}}</a></li>
          <li><a href="https://www.livenation.com/">{{'footer.liveNationEntertainment'|translate}}</a></li>
          <li><a href="https://business.ticketmaster.com/our-story/">{{'footer.ticketMasterInternational'|translate}}</a></li>
        </ul>
      </div>
    </div>
    <div class="" fxFlex="100" fxFlex.xs="25" fxFlex.sm="100" fxFlex.md="100">
      <div class="footer-column-holder">
        <h2 checkToggle>{{'footer.letsHelp'|translate}}</h2>
        <ul>
          <li><a [href]="routerService.getIswPageUrl('help')">{{'footer.help'|translate}}</a></li>
          <li *ngIf="currentLocale?.lang=='iw'"><a href="https://help.ticketmaster.co.il/hc/he/requests/new?ticket_form_id=360000092057">{{'footer.contactUs'|translate}}</a></li>
          <li *ngIf="currentLocale?.lang=='en'"><a href="https://help.ticketmaster.co.il/hc/en-us/requests/new?ticket_form_id=360000092057">{{'footer.contactUs'|translate}}</a></li>
          <li><a [href]="routerService.getIswPageUrl('deliverymethods')">{{'footer.deliveryMethods'|translate}}</a></li>
          <li *ngIf="currentLocale?.lang=='en'"><a href="https://help.ticketmaster.co.il/hc/en-us/articles/20881962474897">{{'main.labels.accessibility'|translate}}</a></li>
          <li *ngIf="currentLocale?.lang=='iw'"><a href="https://help.ticketmaster.co.il/hc/he/articles/20881962474897">{{'main.labels.accessibility'|translate}}</a></li>
        </ul>


      </div>
    </div>

  </div>

  <div class="container">
    <div fxLayout="column">
      <div class="footer-seperator" fxFlex="100%"></div>
      <h2 checkToggle class="corporate-pages-title" >{{'footer.policiesNotices'|translate}}</h2>
      <div class="footer-collapsable" fxFlex="100" fxFlex.xs="25" fxFlex.sm="100" fxFlex.md="100">
        <ul class="corporate-pages" >
          <li>
            <a [href]="routerService.getIswPageUrl('sitemap')">{{'footer.siteMap'|translate}}</a>
          </li>
          <li>
            <a [href]="routerService.getIswPageUrl('ourpolicies')">{{'footer.privacyPolicy'|translate}}</a>
          </li>
          <li>
            <button id="ot-sdk-btn" class="ot-sdk-show-settings">{{'footer.cookieConsent'|translate}}</button>
          </li>
          <li>
            <a [href]="routerService.getIswPageUrl('ourpolicies')">{{'footer.termsAndConditions'|translate}}</a>
          </li>
        </ul>
        <span class="trademark" title={{version}}>{{'footer.trademark'|translate}}</span>
        <span class="trademark-term">{{'footer.trademarkTerm'|translate}}</span>
      </div>
    </div>
  </div>

</footer>
