import {
  Component,
  DoCheck,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { groupBy, map, mergeMap, toArray } from 'rxjs/operators';
import { TicketSaleService } from '@biletix/core/services/ticket-sale.service';
import { from, Observable, of } from 'rxjs';
import { AbstractionComponent } from '@biletix/core/common/abstraction-component';
import { RouterService } from '@biletix/core/services/router.service';
import { ShoppingCart } from '@biletix/core/models/shopping-cart/shopping-cart.model';
import { CartTimeoutService } from '@biletix/core/services/cart-timeout.service';
import { ShoppingCartState } from '@biletix/core/models/state/shopping-cart-state.model';
import { Router } from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { ShoppingItem } from '@biletix/core/models/shopping-cart/shopping-item.model';
import { Locale } from '@biletix/core/common';
import { I18nService } from '@biletix/core/services/i18n.service';



@Component({
  selector: 'btx-cart-dropdown',
  templateUrl: './cart-dropdown.component.html',
  styleUrls: ['./cart-dropdown.component.scss'],
})


export class CartDropdownComponent
  extends AbstractionComponent
  implements OnInit, DoCheck
{
  @ViewChild('cartPopdropHolder') cartPopdropHolder: ElementRef | undefined;
  ticketCount$: Observable<number> = of(0);
  shoppingCartState: ShoppingCartState | any;
  cartStateValue: boolean = false;
  shoppingCart: ShoppingCart | undefined;
  eventShoppingItemsMap: Map<string, ShoppingItem[]>;


  locales: Locale[];
  currentLocale: Locale;

  constructor(
    private ticketSaleService: TicketSaleService,
    private cartTimeoutService: CartTimeoutService,
    private router: Router,
    public i18n: I18nService,
    private routerService: RouterService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    super();
    this.locales = this.i18n.getAvailableLocales();
    this.currentLocale = this.i18n.currentLocale;

    this.eventShoppingItemsMap = new Map<string, ShoppingItem[]>();
    router.events.subscribe((val) => {
      this.changeCartState();
    });
  }
  ngDoCheck(): void {
    this.ticketCount$.subscribe((ticketCount) => {
      if (ticketCount != 0) {
        if (this.ticketSaleService.openBasket == true) {
          this.cartPopdropHolder!.nativeElement.classList.add(
            'cart-popdrop-holder-display'
          );
          setTimeout(() => {
            this.cartPopdropHolder!.nativeElement.classList.remove(
              'cart-popdrop-holder-display'
            );
          },3000);
          this.ticketSaleService.openBasket = false;
        }
      }
    });
  }

  ngOnInit(): void {
    if (!isPlatformServer(this.platformId)) {
      this.cartTimeoutService.shoppingCartStateChange
        .pipe(this.takeUntilDestroy())
        .subscribe((shoppingCartState) => {
          this.shoppingCartState = shoppingCartState;
        });

      //get shopping cart
      this.ticketSaleService
        .getShoppingCart()
        .subscribe((cart) => this.ticketSaleService.setShoppingCart(cart));

      //get ticket count
      this.ticketCount$ = this.ticketSaleService.watchShoppingCart.pipe(
        map((cart: ShoppingCart) => cart?.totalTicket ?? 0)
      );

      this.ticketSaleService.watchShoppingCart.subscribe((shoppingCart) => {
        this.setShoppingCart(shoppingCart);
        // console.log(this.shoppingCart , 'wwww')
      });
    }
  }

  setShoppingCart(cart: ShoppingCart) {
    this.shoppingCart = cart;
    this.eventShoppingItemsMap.clear();
    // this.eventShoppingUpsellMap.clear();
    // this.shareCustomerInfos.clear();
    // this.selectedDeliveryChoice = null;
    // this.adminFeeCharge = null;
    if (cart) {
      // this.selectedDeliveryChoice = cart.deliveryChoice;
      // this.adminFeeCharge = cart.adminFeeCharge;
      this.setEventShoppingItemsMap(cart.shoppingItems);
      //this.setEventShoppingUpsellMap(cart.shoppingUpsellItems);
    }
  }

  setEventShoppingItemsMap(items: ShoppingItem[]) {
    from(items)
      .pipe(
        this.takeUntilDestroy(),
        groupBy((item) => item.eventCode + item.performanceCode),
        mergeMap((group) => group.pipe(toArray())),
        map((arr) => {
          let shoppingItem: ShoppingItem = arr[0];
          const key = shoppingItem.eventCode + shoppingItem.performanceCode;
          return { key: key, values: arr };
        })
      )
      .subscribe((map) => this.eventShoppingItemsMap.set(map.key, map.values));
  }

  changeCartState() {
    if (this.router.url.indexOf('/cart') > -1) {
      this.cartStateValue = true;
      // console.log("true");
    } else {
      this.cartStateValue = false;
      // console.log("false");
    }
  }

  isExistShoppingCart(): boolean {
    return this.ticketSaleService.isExistShoppingCart();
  }

  get cartPageUrl() {
    return this.routerService.cartPageUrl;
  }
}
