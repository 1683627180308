export const RouteParameter = {

  DATA: {
    LOGGED_IN_CUSTOMER: "loggedInCustomer",
    SHOPPING_CART: "shoppingCart",
    CONFIRM_SHOPPING_CART: "confirmShoppingCart",
    ANONYMOUS_CUSTOMER_INFO: "anonymousCustomerInfo",
    ACCOUNT_SETTING_TAB: "accountSettingTab",
    ACTIVE_LINK: "activeLink",
    CHECK_REF_NUMBER  : "checkRefNumber"
  },

  PARAMS: {
    EVENT_CODE: 'eventCode',
    EVENT_GROUP_CODE: 'eventGroupCode',
    PERF_CODE: 'perfCode',
    VENUE_CODE: 'venueId',
    REGION: 'region',
    LANGUAGE: 'lang',
    CHANGE_REQUEST_ID: 'changeRequestId',
    RETURN_URL: "returnUrl",
    ANONYMOUS_BUTTON_VISIBLE: "showLoginAsAnonymousBtn",
    SCODE: 'scode',
    REFCODE: 'refcode'
  }

};
