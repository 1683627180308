<div>
  <a class="event-image "
  [routerLink]="routerLink">

 <img class="cover-img "
      [src]="imageSrc" alt="">

 <span class="ticket-count"
      matBadgeColor="warn"
      matBadgePosition="below after"
      [matBadge]="'x'+count"></span>
  </a>
</div>

