import {BehaviorSubject, Observable} from "rxjs";

export enum StorageKey {

    CART_TIMEOUT_DATE = "CART_TIMEOUT_DATE",
    CART_REMAINING_TIME_IN_SECONDS = "CART_REMAINING_TIME_IN_SECONDS",

    CURRENT_CUSTOMER = "CURRENT_CUSTOMER",
    LOCALE = "LOCALE",
    REGION = "REGION",
    LOGGED_IN_CUSTOMER = "LOGGED_IN_CUSTOMER",
    SHOPPING_CART = "SHOPPING_CART",
    SHOPPING_CART_REFRESH_TIME = "RT",
    RETURN_URL = "RETURN_URL",
    LOGGED_IN_ANONYMOUS_CUSTOMER = "LOGGED_IN_ANONYMOUS_CUSTOMER",
    PAYMENT_ERROR="PAYMENT_ERROR"

};

/**
 * @author otunctan
 */
export abstract class StorageService {


    private _subjectsMap: Map<StorageKey, BehaviorSubject<any>> | any;

    private _storage: Storage;

    /**
     * Constructor with service injection
     * @param storage
     */
    protected constructor(storage: Storage) {
        this._storage = storage;
        this._subjectsMap = new Map<StorageKey, BehaviorSubject<any>>();
    }


    /**
     * watch data of given key
     * @param key
     * @param defaultValue
     */
    watch<T>(key: StorageKey): Observable<T | any> {
        if (!this._subjectsMap.has(key)) {
            this._subjectsMap.set(key, new BehaviorSubject<any>(null));
        }

        let item: any = this._storage.getItem(key);
        if (item === "undefined") {
            item = undefined;
        } else {
            item = JSON.parse(item);
        }

        let subject = this._subjectsMap.get(key);
        subject.next(item);
        return subject.asObservable();
    }

    /**
     * get data of given key
     * @param key
     */
    get<T>(key: StorageKey): T | any {
        let item: any = this._storage.getItem(key);
        if (item === "undefined") {
            item = undefined;
        } else {
            item = JSON.parse(item);
        }
        return item;
    }

    /**
     * set value on given key
     * @param key
     * @param value
     */
    set<T>(key: StorageKey, value: T | any) {
        this._storage.setItem(key, JSON.stringify(value));
        if (!this._subjectsMap.has(key)) {
            this._subjectsMap.set(key, new BehaviorSubject<any>(value));
        } else {
            this._subjectsMap.get(key).next(value);
        }
    }

    /**
     * remove given key
     * @param key
     */
    remove(key: StorageKey) {
        if (this._subjectsMap.has(key)) {
            this._subjectsMap.get(key).complete();
            this._subjectsMap.delete(key);
        }
        this._storage.removeItem(key);
    }

    /**
     * clear all available keys
     */
    clear() {
        this._subjectsMap.clear();
        this._storage.clear();
    }


}
